import classNames from "classnames";
import React, { ReactNode } from "react";

export default function Chip({
    label,
    type = "primary",
    size = "medium",
    invertColors = false,
    tooltipContent,
    showToolTip = true
}: {
    label: string | ReactNode;
    type?: "primary" | "warning" | "danger" | "success" | "neutral";
    size?: "small" | "medium";
    invertColors?: boolean;
    tooltipContent?: string;
    showToolTip?: boolean;
}) {
    const isLabelAReactNode = React.isValidElement<ReactNode>(label);
    return (
        <span
            className={classNames(`relative text-center rounded uppercase font-semibold whitespace-nowrap`, {
                "p-2 w-7 h-7": size === "medium" && isLabelAReactNode,
                "p-1 w-5 h-5 ": size === "small" && isLabelAReactNode,
                "px-3.5 py-1 text-base": size === "medium" && !isLabelAReactNode,
                "px-2 py-px text-xs": size === "small" && !isLabelAReactNode,
                [`bg-${type}-light text-${type} fill-${type}`]: !!type && !invertColors,
                [`bg-${type} text-white fill-white`]: !!type && invertColors,
                "flex justify-center items-center leading-4": isLabelAReactNode
            })}
            {...(showToolTip && tooltipContent && { title: tooltipContent })}
        >
            {label || ""}
        </span>
    );
}
