export default function Lawyer({ className }: { className?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg " width="100%" height="100%" viewBox="0 0 640 896" className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M479.749 276C495.18 249.033 504 217.796 504 184.5C504 82.6035 421.396 0 319.5 0C217.604 0 135 82.6035 135 184.5C135 217.796 143.82 249.033 159.251 276H135.249C120.416 276 106.074 281.318 94.8269 290.989L21.5776 353.972C7.87964 365.75 0 382.917 0 400.983V834C0 868.242 27.7583 896 62 896H578C612.242 896 640 868.242 640 834V400.478C640 382.412 632.12 365.245 618.422 353.467L545.761 290.989C534.513 281.318 520.172 276 505.338 276H479.749ZM415.995 341.785C387.92 359.046 354.873 369 319.5 369C284.849 369 252.43 359.448 224.731 342.833L176.19 713.141C174.463 726.314 184.718 738 198.003 738H320.432H442.859C456.145 738 466.4 726.314 464.673 713.141L415.995 341.785Z"
            />
        </svg>
    );
}
