import classNames from "classnames";
import { ReactNode, useEffect } from "react";

import { CloseIcon } from "../Icons";

export default function Popup({
    title,
    isOpen,
    children,
    popupClassNames,
    onClose,
    leftContent,
    rightContent
}: {
    title: string;
    isOpen: boolean;
    children?: ReactNode;
    popupClassNames?: classNames.ArgumentArray;
    onClose?: () => void;
    leftContent?: ReactNode;
    rightContent?: ReactNode;
}) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const hasContent = leftContent || rightContent;

    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="fixed inset-0 bg-black opacity-60"></div>
                    <div className={classNames("relative z-10 p-5 bg-white rounded-md shadow-lg w-96", popupClassNames)}>
                        <div className="flex items-start justify-between">
                            <h1 className="text-base text-primary font-medium mb-0">{title}</h1>
                            {onClose && (
                                <button onClick={onClose}>
                                    <CloseIcon className="w-5 h-5" />
                                </button>
                            )}
                        </div>
                        {children && (
                            <div
                                className={classNames("overflow-y-auto pt-5 max-h-96 text-label", {
                                    "mb-8": leftContent || rightContent
                                })}
                            >
                                {children}
                            </div>
                        )}
                        {hasContent && (
                            <div
                                className={classNames("flex items-end h-full", {
                                    "justify-between": leftContent && rightContent,
                                    "justify-end": !leftContent && rightContent,
                                    "justify-start": leftContent && !rightContent
                                })}
                            >
                                {leftContent}
                                {rightContent}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
