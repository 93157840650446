import classNames from "classnames";
import { ReactNode } from "react";
import { ChevronSmall } from "../Icons";

type BreadCrumbItem = {
    label: string;
    path?: string;
    icon?: ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export default function Breadcrumb({ breadCrumbList }: { breadCrumbList: BreadCrumbItem[] }) {
    const lastItemIndex = breadCrumbList.length - 1;
    return (
        <nav className="flex">
            <ol className="flex items-center">
                {breadCrumbList.map(({ path, icon, label, onClick }, index) => (
                    <li className="flex items-center" key={label}>
                        {index < lastItemIndex && (
                            <>
                                <a
                                    href={path}
                                    className="flex items-center text-sm font-medium cursor-pointer text-deep-blue hover:underline max-w-xs"
                                    onClick={onClick}
                                >
                                    {icon}
                                    <span
                                        title={label}
                                        className={classNames({
                                            truncate: index > 0
                                        })}
                                    >
                                        {label}
                                    </span>
                                </a>
                                <ChevronSmall className="w-2.5 h-2.5 rotate-90 fill-deep-blue mx-2" />
                            </>
                        )}
                        {index === lastItemIndex && (
                            <div className="flex items-center">
                                <span className="text-sm font-medium text-deep-blue">{label}</span>
                            </div>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
}
