import { next } from "@vercel/edge";
import { get } from "@vercel/edge-config";
import ipRangeCheck from "ip-range-check";

function isAtLeastOneIpAllowed(ips: string[], ipWhiteList: string[]): boolean {
    return ips.some(ip => !!ipRangeCheck(ip, ipWhiteList));
}

export function restrictAccessByIp(ipWhiteList: string[] = []): (req: Request) => Response {
    return (request: Request) => {
        const realIpHeader = request.headers.get("x-real-ip") || "";
        const cfConnectingIpHeader = request.headers.get("cf-connecting-ip") || "";

        if (!ipWhiteList.length || isAtLeastOneIpAllowed([realIpHeader, cfConnectingIpHeader], ipWhiteList)) {
            return next();
        }

        console.warn(`Request blocked: cf-connecting-ip = ${cfConnectingIpHeader}, x-real-ip = ${realIpHeader}`);

        return new Response(null, { status: 404 });
    };
}

const getVercelWhiteListedIps = async (): Promise<string[] | undefined> => {
    try {
        const vercelWhiteListedIps: readonly string[] | undefined = await get("white_listed_ips");

        return vercelWhiteListedIps ? [...vercelWhiteListedIps] : undefined;
    } catch (error) {
        return [];
    }
};

export const restrictVercelAccessByIp = async (request: Request): Promise<Response> => {
    const middleware = restrictAccessByIp(await getVercelWhiteListedIps());

    return middleware(request);
};
