import { useEffect, useState } from "react";

export default function useScroll() {
    const [scrollY, setScrollY] = useState<number>(0);

    const scrollPosition = () => {
        setScrollY(window.pageYOffset);
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollPosition);
        return () => {
            window.removeEventListener("scroll", scrollPosition);
        };
    }, []);

    return scrollY;
}
