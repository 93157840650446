import classNames from "classnames";
import { TickIcon } from "../Icons";

interface Step {
    title: string;
    subtitle?: string;
}

export default function Stepper({
    stepItems,
    currentStep,
    activeSteps,
    onClick,
    className
}: {
    stepItems: Step[];
    currentStep: number;
    activeSteps?: number[];
    onClick?: (index: number) => void;
    className?: string;
}) {
    const getCircleClassName = (index: number) => {
        if (onClick && activeSteps) {
            const lastStep = stepItems.length - (stepItems.length - activeSteps.length);
            const isActive = activeSteps.includes(index + 1);
            const isCurrent = index + 1 === currentStep;
            if (isActive && !isCurrent) {
                return "bg-primary border-primary";
            } else if (isCurrent || index === activeSteps.length - 1 || lastStep === index) {
                return "bg-white border-primary";
            } else {
                return "bg-white border-medium-grey";
            }
        } else {
            return {
                "bg-primary border-primary": index + 1 < currentStep,
                "bg-white border-primary": index + 1 === currentStep,
                "bg-white border-medium-grey": index + 1 > currentStep
            };
        }
    };

    const getTickIconClassName = (index: number) => {
        if (onClick && activeSteps) {
            const isActive = activeSteps.includes(index + 1);
            const isCurrent = index + 1 === currentStep;
            if (isActive && !isCurrent) {
                return "fill-white";
            } else if (isActive && isCurrent) {
                return "fill-primary";
            } else {
                return "fill-white";
            }
        } else {
            return "fill-white";
        }
    };

    const getLeftSeparatorClassName = (index: number) => {
        if (onClick && activeSteps) {
            const isActive = activeSteps.includes(index + 1);
            const lastStep = activeSteps.length;

            if (index === 0) {
                return "bg-transparent";
            } else if (index > 0) {
                return isActive || lastStep === index ? "bg-primary" : "bg-medium-grey";
            } else {
                return "bg-medium-grey";
            }
        } else {
            return {
                "bg-transparent": index === 0,
                "bg-primary": index > 0 && index < currentStep,
                "bg-medium-grey": index > 0 && index >= currentStep
            };
        }
    };

    const getRightSeparatorClassName = (index: number) => {
        if (onClick && activeSteps) {
            const isActive = activeSteps.includes(index + 1);

            if (index === stepItems.length - 1) {
                return "bg-transparent";
            } else if (index < stepItems.length - 1) {
                return isActive ? "bg-primary" : "bg-medium-grey";
            } else {
                return "bg-medium-grey";
            }
        } else {
            return {
                "bg-transparent": index + 1 === stepItems.length,
                "bg-primary": index + 1 < currentStep,
                "bg-medium-grey": index + 1 >= currentStep && index + 1 < stepItems.length
            };
        }
    };

    const getTextClassName = (index: number) => {
        if (onClick && activeSteps) {
            const isActive = activeSteps.includes(index + 1);
            const lastStep = activeSteps.length;

            return {
                "hover:underline hover:cursor-pointer hover:font-bold": index < stepItems.length - 1,
                "font-bold": currentStep === index + 1,
                "text-primary": isActive || lastStep === index,
                "text-medium-grey": !isActive && lastStep !== index
            };
        } else {
            return {
                "text-primary": index < currentStep,
                "text-medium-grey": index >= currentStep
            };
        }
    };

    const handleClick = (index: number) => {
        if (activeSteps && onClick) {
            if (index <= activeSteps.length) {
                onClick(index + 1);
            }
        }
    };
    
    return (
        <ul className={classNames("flex", className)}>
            {stepItems.map(({ title, subtitle }, index) => {
                return (
                    <li className="flex flex-col w-full max-w-52" key={index}>
                        <span className="flex items-center">
                            <span className={classNames("grow h-0.5", getLeftSeparatorClassName(index))} />
                            <span className={classNames("w-5 h-5 p-0.5 border-2 rounded-full", getCircleClassName(index))}>
                                <TickIcon className={classNames(getTickIconClassName(index))} />
                            </span>
                            <span className={classNames("grow h-0.5", getRightSeparatorClassName(index))} />
                        </span>
                        <div onClick={() => handleClick(index)} className={classNames("flex flex-col text-center", getTextClassName(index))}>
                            <span
                                className={classNames("text-sm mt-1.5,", {
                                    "font-semibold": index + 1 <= currentStep
                                })}
                            >
                                {title}
                            </span>
                            {subtitle && <span className="text-xs font-light">{subtitle}</span>}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}
