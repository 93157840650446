import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { DownloadIcon, Loader, useI18n, useToast } from "@vaultinum/app-sdk";
import { extractHostname, getISOString, Report } from "@vaultinum/vaultinum-legal-proof-api";
import classNames from "classnames";
import { User } from "firebase/auth";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../auth";
import { Translation } from "../../../i18n";
import { downloadReport, getReports } from "../../../services";

function DownloadButton({ user, report }: { user: User | null; report: Report.Metadata }) {
    const { translation } = useI18n<Translation>();
    const { error } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        const fileName = `${report.requester.replace(/ /g, "_")}-${report.reportTitle.replace(/ /g, "_")}-${getISOString(
            "YYYY-MM-DD_HH-mm-ss",
            report.creationDate
        )}`;
        await downloadReport(user, report.id, fileName, error, translation);
        setIsLoading(false);
    };

    return (
        <button
            disabled={isLoading}
            className="p-1.5 bg-warning rounded-full hover:bg-orange"
            title={translation.downloadReport}
            onClick={handleDownload}
        >
            {isLoading ? <Loader className="w-3 h-3" position="relative" /> : <DownloadIcon className="fill-white w-3" />}
        </button>
    );
}

const columnHelper = createColumnHelper<Report.Metadata>();

export default function ReportList() {
    const { user } = useAuth();
    const [reports, setReports] = useState<Report.Metadata[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { translation } = useI18n<Translation>();

    const columns = useMemo(() => {
        return [
            columnHelper.accessor("creationDate", {
                header: translation.reportList.header.creationDate,
                cell: (cell) => new Date(cell.getValue()).toISOString().split("T")[0],
            }),
            columnHelper.accessor("reportTitle", {
                header: translation.reportList.header.title,
            }),
            columnHelper.accessor("requester", {
                header: translation.reportList.header.requester,
            }),
            columnHelper.accessor("visitedWebsites", {
                header: translation.reportList.header.visitedWebsites,
                cell: (cell) =>
                    cell
                        .getValue()
                        .map((site) => extractHostname(site))
                        .join(", "),
            }),
            columnHelper.accessor("reportType", {
                header: translation.reportList.header.reportType,
                cell: (cell) => translation.reportList.reportType[cell.getValue()],
            }),
            columnHelper.display({
                id: "actions",
                cell: (cell) => <DownloadButton user={user} report={cell.row.original} />,
            }),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setIsLoading(true);
        if (!user) {
            return;
        }
        getReports(user.uid, setReports);
        setIsLoading(false);
    }, [user]);

    const table = useReactTable({
        columns,
        data: reports.sort((a, b) => (a.creationDate > b.creationDate ? -1 : 1)),
        getCoreRowModel: getCoreRowModel(),
    });

    if (!reports.length && !isLoading) {
        return <p>{translation.reportList.noReport}</p>;
    }

    return (
        <table className="min-w-full table-auto divide-y divide-gray-200 text-xs">
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th
                                className={classNames("px-2 py-3 text-left text-primary uppercase tracking-wider", {
                                    "w-3/12": header.id === "reportTitle",
                                })}
                                key={header.id}
                            >
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td
                                className={classNames({
                                    "px-2 py-3 whitespace-nowrap": !cell.id.includes("actions"),
                                    "sticky right-0 px-2 py-1 whitespace-nowrap bg-white": cell.id.includes("actions"),
                                })}
                                key={cell.id}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
