import classNames from "classnames";

export default function ProgressBar({ indicator, progress, label }: { indicator: string; progress: string; label?: string }) {
    return (
        <>
            <div className="flex justify-between mb-1 text-primary text-xs">
                {label && <span className="font-medium">{label}</span>}
                <span className="font-bold">{indicator}</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-4 flex items-center p-1">
                <div className={classNames("bg-primary h-2.5 rounded-full ")} style={{ width: `${progress}%` }} />
            </div>
        </>
    );
}
