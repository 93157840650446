import classNames from "classnames";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";

import { Input } from "../../../../design-system/components";
import { InputFields } from "../../../model";

export default function FormFieldsInput({
    fields,
    control,
    errors,
    readOnly,
    className
}: {
    fields: InputFields[];
    control: Control<FieldValues>;
    errors: FieldErrors;
    readOnly?: boolean;
    className?: string;
}) {
    return (
        <ul className={classNames("gap-5", className)}>
            {fields.map(({ name, label, className: clsName, helperText, type, rows, labelPosition, groupList, limit, disabled, placeholder, options, autofocus }) => {
                return (
                    <li key={name}>
                        <Controller
                            name={name}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input
                                    label={label}
                                    className={clsName}
                                    labelPosition={labelPosition}
                                    helperText={errors[name]?.message ? String(errors[name]?.message) : helperText}
                                    limit={limit}
                                    fieldName={name}
                                    groupList={groupList}
                                    type={type}
                                    placeholder={placeholder}
                                    error={!!errors[name]}
                                    rows={rows}
                                    options={options}
                                    onChange={onChange}
                                    value={value}
                                    disabled={disabled || readOnly}
                                    autofocus={autofocus}
                                />
                            )}
                        />
                    </li>
                );
            })}
        </ul>
    );
}
