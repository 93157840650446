import { PluralString, RatioString } from "./Translation";

export function translate(pluralString: PluralString, count: number): string {
    if (pluralString[count]) {
        return pluralString[count];
    }

    if (pluralString.plural) {
        return pluralString.plural(count);
    }

    return "";
}

export function translateRatio(ratioString: RatioString, count: number, total: number): string {
    if (ratioString[count]) {
        if (ratioString[count][total]) {
            return ratioString[count][total];
        }
        if (ratioString[count].other) {
            const otherFn = ratioString[count].other;
            if (typeof otherFn === "function") {
                return otherFn(total);
            }
        }
    }

    if (ratioString["*"]) {
        if (ratioString["*"][total]) {
            return ratioString["*"][total](count);
        }

        if (ratioString["*"].other) {
            return ratioString["*"].other(count, total);
        }
    }
    return "";
}
