import { createContext, ReactNode, useEffect, useState } from "react";

import { useApi } from "../hooks";
import { DepositApi, ExportApi, MemberApi } from "../model";
import { getInformation } from "./deposit.service";
import { getExportApi } from "./export.service";
import { getMemberApi } from "./member.service";

export interface Apis {
    deposit: DepositApi;
    export: ExportApi;
    member: MemberApi;
}

export const ApisContext = createContext({} as Apis);

export const ApisContextProvider = ({ children }: { children: ReactNode }) => {
    const [apis, setApis] = useState<Apis | undefined>();
    const { fetchApi } = useApi();

    useEffect(() => {
        void (async () => {
            const depositResult = await fetchApi<DepositApi>(getInformation);
            const exportResult = await fetchApi<ExportApi>(getExportApi);
            const memberResult = await fetchApi<MemberApi>(getMemberApi);

            if (depositResult && exportResult && memberResult) {
                setApis({
                    deposit: depositResult,
                    export: exportResult,
                    member: memberResult
                });
            }
        })();
    }, []);

    return <>{apis && <ApisContext.Provider value={apis}>{children}</ApisContext.Provider>}</>;
};
