import { useState } from "react";

import { Translation, useI18n } from "..";
import { useToast } from "../../design-system";

export default function useApi() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { translation } = useI18n<Translation>();
    const toast = useToast();

    const fetchApi = async<T>(fetchFn: () => Promise<T>, onSuccess?: (response: T) => void) => {
        try {
            setIsLoading(true);

            const result = await fetchFn();

            if (onSuccess) {
                onSuccess(result);
            }

            return result;
        } catch (e) {
            toast.error(translation.httpErrorHandling(e));

            return undefined;
        } finally {
            setIsLoading(false);
        }
    };
    return { fetchApi, isLoading };
}
