import classNames from "classnames";
import { Spinner } from "../Icons";

export default function Loader({ className, position = "absolute" }: { className?: string; position?: "absolute" | "relative" }) {
    return (
        <Spinner
            className={classNames("animate-spin fill-primary w-5 h-5 ", className, {
                relative: position === "relative",
                "absolute inset-1/2": position === "absolute"
            })}
        />
    );
}
