import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? ""));
initializeFirestore(app, { experimentalAutoDetectLongPolling: true });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
