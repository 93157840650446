export default function LegalPersonIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 23 30" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M5.27 6.094c0-.518.43-.938.96-.938h2.874a.95.95 0 0 1 .959.938v2.812a.95.95 0 0 1-.959.938H6.23a.95.95 0 0 1-.958-.938V6.094Zm11.5-.938a.95.95 0 0 1 .96.938v2.812a.95.95 0 0 1-.96.938h-2.874a.95.95 0 0 1-.959-.938V6.094a.95.95 0 0 1 .959-.938h2.875Zm-11.5 8.438a.95.95 0 0 1 .96-.938h2.874a.95.95 0 0 1 .959.938v2.812a.95.95 0 0 1-.959.938H6.23a.95.95 0 0 1-.958-.938v-2.812Zm11.5-.938c.528 0 .96.422.96.938v2.812a.951.951 0 0 1-.96.938h-2.874a.95.95 0 0 1-.959-.938v-2.812a.95.95 0 0 1 .959-.938h2.875ZM0 3.75C0 1.679 1.716 0 3.833 0h15.334C21.28 0 23 1.679 23 3.75v22.5c0 2.068-1.719 3.75-3.833 3.75H3.833C1.716 30 0 28.318 0 26.25V3.75Zm2.875 0v22.5a.95.95 0 0 0 .958.938h4.792v-3.75c0-1.553 1.288-2.813 2.875-2.813s2.875 1.26 2.875 2.813v3.75h4.792a.95.95 0 0 0 .958-.938V3.75a.95.95 0 0 0-.958-.938H3.833c-.53 0-.958.42-.958.938Z"
                fill="#004C93"
            />
        </svg>
    );
}
