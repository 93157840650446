import { User } from "firebase/auth";

export async function sendLoginEventToMixpanel(user: User) {
    try {
        await fetch(`${process.env.REACT_APP_LPR_ENGINE_URL}/tracking/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${await user.getIdToken()}`,
            },
            body: JSON.stringify({
                email: user.email,
                userUid: user.uid,
            }),
        });
    } catch (err) {
        // Do nothing
    }
}
