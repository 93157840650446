import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button, InfoTooltip } from "..";

type GridCellProps = {
    children?: ReactNode;
    customClass?: string;
    title?: string;
    subtitle?: string;
    icon?: ReactNode;
    tooltipMessage?: string | ReactNode;

    primaryButtonProps?: ButtonProps;
    secondaryButtonProps?: ButtonProps;
};

type ButtonProps = {
    label?: string;
    to?: string;
    disabled?: boolean;
    shouldOpenInNewTab?: boolean;
};

const ButtonLink = ({ label, to, disabled = false, shouldOpenInNewTab = false }: ButtonProps) => {
    if (!label) {
        return null;
    }

    const target = shouldOpenInNewTab ? "_blank" : "_self";
    const href = to || "#";

    return (
        <div className="flex justify-center">
            <Link to={href} target={target} rel="noopener noreferrer">
                <Button type="button" label={label} disabled={disabled} />
            </Link>
        </div>
    );
};

export default function GridCell({ children, customClass, title, subtitle, icon, tooltipMessage, primaryButtonProps, secondaryButtonProps }: GridCellProps) {
    return (
        <div className={classNames("rounded-md bg-white p-7 relative", customClass)}>
            {tooltipMessage && (
                <InfoTooltip className="top-1 right-1" direction="bottom" width="w-60">
                    {tooltipMessage}
                </InfoTooltip>
            )}
            <div className="flex flex-col justify-between h-full space-y-4">
                <span>
                    <span>
                        {title && (
                            <h3 className="flex items-center mb-2 text-base font-bold text-primary">
                                {icon && <div className="inline-block p-2 mr-2 rounded-full bg-primary-light">{icon}</div>} {title}
                            </h3>
                        )}
                        {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
                    </span>
                    {children}
                </span>
                {primaryButtonProps && (
                    <ButtonLink
                        to={primaryButtonProps.to}
                        shouldOpenInNewTab={primaryButtonProps.shouldOpenInNewTab}
                        label={primaryButtonProps.label}
                        disabled={primaryButtonProps.disabled}
                    />
                )}
                {secondaryButtonProps && (
                    <ButtonLink
                        to={secondaryButtonProps.to}
                        shouldOpenInNewTab={secondaryButtonProps.shouldOpenInNewTab}
                        label={secondaryButtonProps.label}
                        disabled={secondaryButtonProps.disabled}
                    />
                )}
            </div>
        </div>
    );
}
