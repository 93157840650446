import { SuccessIcon } from "../../../design-system";

export default function SealResult({ title, subtitle }: { title: string; subtitle: string }) {
    return (
        <div className="flex flex-col items-center justify-center gap-y-5">
            <SuccessIcon className="w-20 h-20 fill-green-600" />
            <p className="text-2xl font-medium text-primary">{title}</p>
            <p className="text-sm">{subtitle}</p>
        </div>
    );
}
