import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../design-system";
import { InterdepositContext } from "../contexts";
import { Translation, useI18n } from "../i18n";
import { DepositStore, ManagedBy, Member, Representative, User, UserInfo } from "../model";
import {
    addMemberToDepositStore,
    addUserManagerToDepositStore,
    getContributorsByMember,
    getManagedByDepositStore,
    getRepresentativesByMember,
    removeDepositStoreContributor,
    removeDepositStoreMember
} from "../services";
import useApi from "./useApi";

export interface Managers {
    onRepresentativeManagerAdd: (representative: Representative) => void;
    onRepresentativeManagerRemove: (representativeId: string) => void;
    onUserManagerRemove: (userId: string) => void;
    onUserManagerAdd: (user: UserInfo) => void;
    contributors?: UserInfo[];
    userManagers: UserInfo[];
    representativeManagers: Representative[];
    representatives?: Representative[];
}

type ContributorsList = {
    submit: (redirect?: string) => Promise<void>;
    reset: () => void;
    readonly: boolean;
    infoMessage: ReactNode;
    warningMessage: ReactNode;
    updating: boolean;
    noModification: boolean;
    managers: Managers;
};

const adminstratorToUserInfo = (administrator: Member.Administrator): UserInfo => {
    return {
        id: administrator.id,
        firstName: administrator.firstName,
        lastName: administrator.lastName,
        role: User.UserWalletRole.ADMINISTRATOR,
        email: administrator.email,
        _links: {
            removeUserManager: {
                available: false
            }
        }
    } as UserInfo;
};

export function useContributorsList(depositStore: DepositStore, member: Member): ContributorsList {
    const toast = useToast();
    const { refreshDepositStore } = useContext(InterdepositContext);
    const { fetchApi } = useApi();
    const { translation } = useI18n<Translation>();

    const [updating, setUpdating] = useState<boolean>(false);
    const [contributors, setContributors] = useState<UserInfo[]>();
    const [representatives, setRepresentatives] = useState<Representative[]>();
    const [defaultManagers, setDefaultManagers] = useState<ManagedBy>();

    const [userManagers, setUserManagers] = useState<UserInfo[]>(depositStore.managedBy.users);
    const [userManagersToAdd, setUserManagersToAdd] = useState<UserInfo[]>([]);
    const [userManagersToRemove, setUserManagersToRemove] = useState<UserInfo[]>([]);

    const [representativeManagers, setRepresentativeManagers] = useState<Representative[]>(depositStore.managedBy.members);
    const [representativeManagersToAdd, setRepresentativeManagersToAdd] = useState<Representative[]>([]);
    const [representativeManagersToRemove, setRepresentativeManagersToRemove] = useState<Representative[]>([]);

    const [infoMessage, setInfoMessage] = useState<ReactNode>();
    const [warningMessage, setWarningMessage] = useState<ReactNode>();

    const noModification = useMemo(
        () =>
            userManagersToAdd.length === 0 &&
            userManagersToRemove.length === 0 &&
            representativeManagersToAdd.length === 0 &&
            representativeManagersToRemove.length === 0,
        [userManagersToAdd, userManagersToRemove, representativeManagersToAdd, representativeManagersToRemove]
    );

    const navigate = useNavigate();

    const addUserManagerFn = addUserManagerToDepositStore(depositStore);
    const addRepresentativeManagerFn = addMemberToDepositStore(depositStore);

    const submit = async (redirect?: string): Promise<void> => {
        const addUserPromises = userManagersToAdd.map(manager => {
            if (addUserManagerFn) {
                return fetchApi(() => addUserManagerFn(manager));
            }

            return Promise.resolve();
        });

        const addRepresentativePromises = representativeManagersToAdd.map(manager => {
            if (addRepresentativeManagerFn) {
                return fetchApi(() => addRepresentativeManagerFn(manager));
            }

            return Promise.resolve();
        });

        const removeUserPromises = userManagersToRemove.map(manager => {
            const removeFn = removeDepositStoreContributor(manager);

            if (removeFn) {
                return fetchApi(removeFn);
            }

            return Promise.resolve();
        });

        const removeRepresentativePromises = representativeManagersToRemove.map(manager => {
            const removeFn = removeDepositStoreMember(manager);

            if (removeFn) {
                return fetchApi(removeFn);
            }

            return Promise.resolve();
        });

        setUpdating(true);

        await Promise.all([...removeUserPromises, ...removeRepresentativePromises, ...addRepresentativePromises, ...addUserPromises]).then(responses => {
            if (responses.every(response => response !== undefined)) {
                toast.success(translation.contributorsUpdated);
            } else {
                toast.error(translation.error);
            }
        });

        setUserManagersToAdd([]);
        setUserManagersToRemove([]);
        setRepresentativeManagersToAdd([]);
        setRepresentativeManagersToRemove([]);

        refreshDepositStore();

        setUpdating(false);
        if (redirect) {
            navigate(redirect);
        }
    };

    useEffect(() => {
        if (noModification && depositStore.managedBy.members.length) {
            setInfoMessage(translation.representativeSelectedInfo);
        } else {
            setInfoMessage(undefined);
        }
    }, [userManagersToAdd, userManagersToRemove, representativeManagersToAdd, representativeManagersToRemove, noModification]);

    useEffect(() => {
        if (representativeManagersToAdd.length) {
            setWarningMessage(translation.representativeAddedInfo(representativeManagersToAdd[0].name));
        } else if (representativeManagersToRemove.length) {
            setWarningMessage(translation.representativeRemovedInfo(depositStore.managedBy.members[0].name));
        } else {
            setWarningMessage(undefined);
        }
    }, [representativeManagersToAdd, representativeManagersToRemove]);

    const readonly = !addUserManagerFn && !addRepresentativeManagerFn;

    const initialDepositStoreUserManagers = defaultManagers?.users ?? member.administrators.map(adminstratorToUserInfo);

    const loadContributorsAndRepresentatives = async (): Promise<void> => {
        const fetchContributors = getContributorsByMember(member);
        const fetchRepresentatives = getRepresentativesByMember(member);
        const fetchDefaultManagers = getManagedByDepositStore(depositStore);

        if (fetchContributors) {
            await fetchApi(fetchContributors, setContributors);
        }

        if (fetchRepresentatives) {
            await fetchApi(fetchRepresentatives, setRepresentatives);
        }

        if (fetchDefaultManagers) {
            await fetchApi(fetchDefaultManagers, setDefaultManagers);
        }
    };

    useEffect(() => {
        void loadContributorsAndRepresentatives();
    }, []);

    const onUserManagerAdd = (user: UserInfo): void => {
        setUserManagers(prev => {
            if (prev.length === 0) {
                return [...initialDepositStoreUserManagers, user];
            } else {
                return [...prev, user];
            }
        });
        setUserManagersToRemove(prev => prev.filter(manager => manager.id !== user.id));
        if (depositStore.managedBy.users.every(manager => manager.id !== user.id)) {
            setUserManagersToAdd(prev => [...prev, user]);
        }

        setRepresentativeManagers([]);
        setRepresentativeManagersToAdd([]);

        setRepresentativeManagersToRemove(depositStore.managedBy.members.filter(removeDepositStoreMember));
    };

    const onUserManagerRemove = (userId: string): void => {
        setUserManagers(prev => prev.filter(manager => manager.id !== userId));
        setUserManagersToAdd(prev => prev.filter(manager => manager.id !== userId));

        const user = depositStore.managedBy.users.find(manager => manager.id === userId);

        if (user) {
            setUserManagersToRemove(prev => [...prev, user]);
        }
    };

    const onRepresentativeManagerAdd = (representative: Representative): void => {
        setUserManagers([]);
        setUserManagersToAdd([]);
        setUserManagersToRemove(depositStore.managedBy.users.filter(removeDepositStoreContributor));

        setRepresentativeManagers([representative]);
        setRepresentativeManagersToAdd([representative].filter(manager => depositStore.managedBy.members.every(rep => rep.id !== manager.id)));
        setRepresentativeManagersToRemove(depositStore.managedBy.members.filter(manager => manager.id !== representative.id));
    };

    const onRepresentativeManagerRemove = (representativeId: string): void => {
        setUserManagers(initialDepositStoreUserManagers);
        setUserManagersToAdd([]);
        setUserManagersToRemove(depositStore.managedBy.users.filter(removeDepositStoreContributor));

        setRepresentativeManagers([]);
        setRepresentativeManagersToAdd([]);
        setRepresentativeManagersToRemove(prev => [...prev, ...depositStore.managedBy.members.filter(manager => manager.id === representativeId)]);
    };

    const reset = (): void => {
        setUserManagers(depositStore.managedBy.users);
        setUserManagersToAdd([]);
        setUserManagersToRemove([]);

        setRepresentativeManagers(depositStore.managedBy.members);
        setRepresentativeManagersToAdd([]);
        setRepresentativeManagersToRemove([]);
    };

    const managers: Managers = useMemo(
        () => ({
            contributors: contributors?.map(contributor => defaultManagers?.users.find(user => user.id === contributor.id) ?? contributor),
            representatives,
            userManagers,
            representativeManagers,
            onRepresentativeManagerAdd,
            onRepresentativeManagerRemove,
            onUserManagerRemove,
            onUserManagerAdd
        }),
        [
            defaultManagers,
            contributors,
            representatives,
            userManagers,
            representativeManagers,
            onRepresentativeManagerAdd,
            onRepresentativeManagerRemove,
            onUserManagerRemove,
            onUserManagerAdd
        ]
    );

    return {
        managers,
        submit,
        noModification,
        reset,
        readonly,
        infoMessage,
        warningMessage,
        updating
    };
}
