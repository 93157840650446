import { Report, REPORTS_COLLECTION, USERS_COLLECTION } from "@vaultinum/vaultinum-legal-proof-api";
import { saveAs } from "file-saver";
import { User } from "firebase/auth";
import { collection, DocumentData, DocumentSnapshot, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { Translation } from "../i18n";

function convertDocToReport(document: DocumentSnapshot<DocumentData>): Report.Metadata {
    return {
        ...(document.data() as Omit<Report.Metadata, "creationDate">),
        creationDate: document.data()?.creationDate.toDate(),
        id: document.id,
    };
}

export const getReports = (userUid: string, onUpdate: (requesters: Report.Metadata[]) => void) =>
    onSnapshot(
        query(collection(getFirestore(), USERS_COLLECTION, userUid, REPORTS_COLLECTION), where("status", "!=", "error")),
        (querySnapshot) => {
            onUpdate(querySnapshot.docs.map(convertDocToReport));
        },
        () => onUpdate([])
    );

export const downloadReport = async (
    user: User | null,
    reportId: string,
    reportTitle: string,
    onError: (message: React.ReactNode) => void,
    translation: Translation
) => {
    if (!user) {
        onError(translation.mustBeConnectedToDownload);
        return;
    }

    const response = await fetch(`${process.env.REACT_APP_LPR_ENGINE_URL}/report/${user.uid}/${reportId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/zip",
            Authorization: `Bearer ${await user.getIdToken()}`,
        },
    });

    if (!response.ok) {
        onError(translation.reportNotFound);
        return;
    }

    const blob = await response.blob();
    saveAs(blob, `${reportTitle}.zip`);
};
