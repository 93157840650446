import classNames from "classnames";
import { ReactNode } from "react";

export default function CreditCard({
    title,
    subtitle,
    lastRightContent,
    index,
    firstleftContent,
    firstRightContent
}: {
    title: string;
    subtitle: string;
    lastRightContent: ReactNode;
    index: number;
    firstleftContent?: ReactNode;
    firstRightContent?: ReactNode;
}) {
    return (
        <div className="flex justify-between border rounded-xl shadow-md py-2.5 px-3.5">
            <div className="flex items-center">
                <div className="flex items-center">{firstleftContent}</div>
                <div className="flex flex-col">
                    <span className="text-label text-sm font-semibold">{title}</span>
                    <p
                        className={classNames("text-xs font-light", {
                            "text-primary": index === 0,
                            "text-[#F4150D]": index !== 0
                        })}
                    >
                        {subtitle}
                    </p>
                </div>
            </div>
            <div className="flex gap-2.5">
                {firstRightContent && <div className="flex items-center">{firstRightContent}</div>}
                {lastRightContent && <div className="flex flex-col">{lastRightContent}</div>}
            </div>
        </div>
    );
}
