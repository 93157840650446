import { FileSizeUnit } from "../model";

export interface OverWrittenOptions {
    decimal: number;
    forceUnit?: FileSizeUnit;
}

export type BytesFormatterOptions = OverWrittenOptions & {
    units: Record<FileSizeUnit, string>;
};

export function bytesConverter(locale: string, options: BytesFormatterOptions) {
    const units = Object.values(options.units);
    return function (size: number, overWrittenOptions?: OverWrittenOptions) {
        const mergedOptions = { ...options, ...overWrittenOptions };
        let convertedSize = size;
        const { forceUnit, decimal } = mergedOptions;
        let unitIndex = forceUnit ? units.indexOf(mergedOptions.units[forceUnit]) : 0;

        if (forceUnit && unitIndex !== -1) {
            convertedSize = size / Math.pow(1024, unitIndex);
        } else {
            while (convertedSize >= 1024 && unitIndex < units.length - 1) {
                convertedSize /= 1024;
                unitIndex++;
            }
        }

        return `${parseFloat(convertedSize.toFixed(decimal)).toLocaleString(locale, { useGrouping: true })} ${units[unitIndex]}`;
    };
}

export function getTotalSize(files: File[]) {
    return Array.from(files.values()).reduce((acc, file) => acc + file.size, 0);
}

export function calculatePercentage(totalUploadSizeInBytes: number, uploadedSizeInBytes: number): number {
    let percentage = 0;

    if (totalUploadSizeInBytes > 0 && uploadedSizeInBytes >= 0) {
        percentage = Math.floor((uploadedSizeInBytes * 100) / totalUploadSizeInBytes);
    }

    return percentage;
}
