import classNames from "classnames";
import { Member } from "../../model";
import { CorporationIcon, LegalPersonIcon, PersonIcon } from "../../../design-system";

export default function MemberNatureIcon({ nature, className }: { nature: Member.Nature; className?: string }): JSX.Element {
    let Component;
    switch (nature) {
        case Member.Nature.INDIVIDUAL_COMPANY:
            Component = LegalPersonIcon;
            break;
        case Member.Nature.LEGAL_PERSON:
            Component = CorporationIcon;
            break;
        default:
            Component = PersonIcon;
    }

    return <Component className={classNames("fill-primary", className)} />;
}
