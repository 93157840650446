export default function BoxIcon({ className }: { className?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg " width="100%" height="100%" viewBox="0 0 54 49" className={className}>
            <path d="M36 9C36 13.9706 31.9706 18 27 18C22.0294 18 18 13.9706 18 9C18 4.02944 22.0294 0 27 0C31.9706 0 36 4.02944 36 9Z" />
            <path d="M54 40C54 44.9706 49.9706 49 45 49C40.0294 49 36 44.9706 36 40C36 35.0294 40.0294 31 45 31C49.9706 31 54 35.0294 54 40Z" />
            <path d="M18 40C18 44.9706 13.9706 49 9 49C4.02944 49 0 44.9706 0 40C0 35.0294 4.02944 31 9 31C13.9706 31 18 35.0294 18 40Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27 8L7.94745 41H46.0526L27 8ZM27 20L18.3398 35H35.6603L27 20Z" />
        </svg>
    );
}
