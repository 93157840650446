import { ExpandIcon } from "../Icons";

export default function ExpendButton({ onClick, label }: { onClick: (e: React.MouseEvent<HTMLElement>) => void; label: string }) {
    return (
        <button className="flex items-center gap-1 p-2 hover:bg-primary-xlight text-primary" onClick={onClick}>
            <ExpandIcon className="w-2 h-2 fill-primary" />
            {label}
        </button>
    );
}
