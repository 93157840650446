import classNames from "classnames";
import React, { ReactNode } from "react";

export default function CardBoxContent({
    content,
    title,
    header,
    subtitle,
    helperText,
    bottomLeftContent,
    bottomRightContent,
    checked,
    isDisabled,
    lightMode
}: {
    content: ReactNode | string[];
    title?: string;
    header?: string;
    subtitle?: string;
    helperText?: string;
    bottomLeftContent?: ReactNode;
    bottomRightContent?: ReactNode;
    isBordered?: boolean;
    checked?: boolean;
    isDisabled?: boolean;
    lightMode?: boolean;
}) {
    return (
        <div className="relative flex flex-col h-full">
            <header className="mb-4">
                {header && (
                    <h2
                    className={classNames("text-center font-bold text-xl mb-4", {
                        "text-primary": checked ||  !checked && !isDisabled,
                        "text-color-disabled": isDisabled || lightMode
                    })}
                    >
                        {header}
                    </h2>
                )}
                <h2
                    className={classNames("font-bold text-base", {
                        "text-primary": checked || !checked && !isDisabled,
                        "text-light-black": isDisabled,
                        "font-light text-light-black": lightMode
                    })}
                    >
                    {title}
                    {subtitle && (
                        <p
                        className={classNames("text-sm font-light -mt-1", {
                            "text-primary": checked || !checked && !isDisabled,
                            "text-light-black": isDisabled || lightMode,
                        })}
                        >
                        {subtitle}
                    </p>
                )}
                </h2>
            </header>   
            <div
                className={classNames("text-sm ", {
                    "text-label": checked,
                    "text-light-black": isDisabled || lightMode
                })}
            >
                {helperText && <p className="text-sm">{helperText}</p>}
                {content && Array.isArray(content) ? (
                    content.map((item, index) => (
                        <React.Fragment key={index}>
                            <p>{item}</p>
                            {index !== content.length - 1 && <br />}
                        </React.Fragment>
                    ))
                ) : (
                    <>{content}</>
                )}
            </div>
            <footer
                className={classNames("flex items-end mt-auto pt-4", {
                        "justify-between": bottomLeftContent && bottomRightContent,
                        "justify-end": !bottomLeftContent && bottomRightContent,
                        "justify-start": bottomLeftContent && !bottomRightContent
                    })}
                >
                    {bottomLeftContent}
                    {bottomRightContent}
            </footer>
        </div>
    );
}
