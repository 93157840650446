import { Card } from "..";
import classNames from "classnames";

export default function SkeletonCards({ small, length }: { small?: boolean; length: number }) {
    const Cards = [];

    for (let i = 0; i < length; i++) {
        Cards.push(
            small ? (
                <li>
                    <Card title="" isLoading small />
                </li>
            ) : (
                <li>
                    <Card isLoading title="" />
                </li>
            )
        );
    }
    return <ul className={classNames({ "space-y-1": small, "space-y-2": !small })}>{Cards}</ul>;
}
