export default function CompanyLogo({ className, small }: { className?: string; small?: boolean }) {
    if (small) {
        return (
            <svg width="100%" height="100%" viewBox="0 0 109 160" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
                <path
                    d="M54.2 0C24.3 0 0 23 0 51.4V146.5C0 153.1 5.29999 158.5 11.8 158.5C18.3 158.5 23.6 153.1 23.6 146.5V51.4C23.6 36 37.3 23.5 54.2 23.5C71.1 23.5 84.8 36 84.8 51.4V146.5C84.8 153.1 90.1 158.5 96.6 158.5C103.1 158.5 108.4 153.1 108.4 146.5V51.4C108.4 23 84.1 0 54.2 0Z"
                    fill="#004C8E"
                />
                <path
                    d="M65.8 80.3H63.6L63.4 75.7C63.1 72 59 69.1 54.1 69.1C49.2 69.1 45.1 72 44.8 75.7L44.6 80.3H42.4C41.5 80.3 40.8 81 40.8 81.9V102.1C40.8 103 41.5 103.7 42.4 103.7H65.9C66.8 103.7 67.5 103 67.5 102.1V81.9C67.4 81 66.7 80.3 65.8 80.3ZM55.2 92.7V95.5C55.2 96.2 54.7 96.7 54 96.7C53.3 96.7 52.8 96.2 52.8 95.5V92.7C52.1 92.3 51.5 91.7 51.5 90.7C51.5 89.3 52.6 88.2 54 88.2C55.4 88.2 56.5 89.3 56.5 90.7C56.6 91.7 55.9 92.3 55.2 92.7ZM47.4 80.3L47.5 76.9C47.7 74.4 50.5 72.4 53.9 72.4C57.3 72.4 60.1 74.4 60.3 76.9L60.4 80.3H47.4Z"
                    fill="#F4150D"
                />
            </svg>
        );
    }
    return (
        <svg width="100%" height="100%" x="0" y="0" viewBox="0 0 808 160" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M412.6 26.6L415.4 35.9H423.3L412.3 0.600006H402.1L391.2 35.9H398.8L401.4 26.6H412.6ZM404.7 13.1C405.1 11.9 405.4 10.4 405.7 8.89999C405.9 7.89999 406.1 7.00001 406.4 6.10001L406.5 5.80002H407.2L407.4 6.10001L407.7 7.20001C408.2 9.10001 408.7 11.3 409.3 13.1L411.7 21.4H402.4L404.7 13.1Z"
                fill="#004C8E"
            />
            <path
                d="M426.8 18.7C426.7 23.9 428.5 28.5 431.8 31.5C435.1 34.7 439.9 36.3 446.3 36.3C450.6 36.3 455.1 35.3 458 34.4V16.1H445.3V21.6H450.9V29.9L450.6 30C449.9 30.4 448.3 30.6 446.3 30.6C439 30.6 434.4 25.8 434.4 18.3C434.4 10.8 439.2 6.10001 446.9 6.10001C450.9 6.10001 453.4 6.8 455.1 7.5L456.6 1.80002C454.8 1.00002 451.4 0.200012 447 0.200012C434.8 0.200012 426.8 7.50001 426.8 18.7Z"
                fill="#004C8E"
            />
            <path d="M486.8 20.6V14.9H473.3V6.39999H487.6V0.600006H465.9V35.9H488.3V30.1H473.3V20.6H486.8Z" fill="#004C8E" />
            <path
                d="M502.8 9.39999C505 14.5 507.7 19.6 509.1 22.1L516.8 35.8H524.7V0.600006H518V10.7C518 16.8 518.2 21.4 518.7 25.7L518.8 26.2H517.8L517.7 25.9C516.1 21.8 513.9 17.3 511.8 13.6L504.3 0.600006H495.4V35.9H502.1V25.5C502.1 19.7 502 14.5 501.8 9.70001V9.39999L502.7 9.10001L502.8 9.39999Z"
                fill="#004C8E"
            />
            <path
                d="M551.1 0C539.4 0 531.6 7.50002 531.6 18.8C531.6 29.7 538.8 36.4 550.3 36.4C554.3 36.4 557.5 35.7 559.2 35L558.1 29.5C556.3 30.1 553.7 30.5 551.6 30.5C544.1 30.5 539.4 25.8 539.4 18.2C539.4 10.5 544 5.80002 551.5 5.80002C553.8 5.80002 556.1 6.2 558.1 7L559.6 1.39999C558.1 0.799994 555.1 0 551.1 0Z"
                fill="#004C8E"
            />
            <path d="M586.9 20.6V14.9H573.5V6.39999H587.7V0.600006H566.1V35.9H588.5V30.1H573.5V20.6H586.9Z" fill="#004C8E" />
            <path
                d="M615.7 21.7C620.3 21.7 624.2 20.3 626.5 17.9C628.3 16.1 629.2 13.6 629.2 10.5C629.2 7.5 628.1 5.00002 626.2 3.30002C624 1.40002 620.7 0.399994 616.3 0.399994C613.3 0.399994 610.4 0.6 607.7 1V35.9H611.5V21.3L612.1 21.4C613.1 21.7 614.5 21.7 615.7 21.7ZM611.4 18.4V3.60001L611.7 3.5C612.6 3.3 614.3 3.10001 616.3 3.10001C622.1 3.10001 625.4 5.90001 625.4 10.6C625.4 15.7 621.8 18.8 615.8 18.8C614.1 18.8 612.8 18.7 611.8 18.4H611.4Z"
                fill="#004C8E"
            />
            <path
                d="M651.3 0C641.6 0 634.8 7.6 634.8 18.5C634.8 29 641.4 36.4 650.8 36.4C660.8 36.4 667.3 29.1 667.3 17.8C667.3 7.20002 660.8 0 651.3 0ZM651 33.6C643.1 33.6 638.9 26 638.9 18.5C638.9 10.8 642.7 3 651.1 3C659.4 3 663.2 10.9 663.2 18.2C663.2 27.2 658.2 33.6 651 33.6Z"
                fill="#004C8E"
            />
            <path
                d="M687.4 36.5C696 36.5 700.8 31.1 700.8 21.3V0.600006H696.9V21.6C696.9 29.3 693.6 33.6 687.6 33.6C681.9 33.6 678.6 29.2 678.6 21.6V0.600006H674.7V21.5C674.8 33.9 681.7 36.5 687.4 36.5Z"
                fill="#004C8E"
            />
            <path
                d="M726.9 27.6C727.6 30.8 728.5 34.5 729.2 35.9H733.1C732.4 34.1 731.6 30.8 730.6 26.5C729.7 22.7 728.1 20.4 725.5 19.5L725.2 19.4V18.6L725.5 18.5C729.6 17.1 732.1 13.8 732.1 9.70001C732.1 7.00001 731.1 4.70001 729.4 3.20001C727.2 1.20001 724 0.399994 719.1 0.399994C716 0.399994 712.8 0.600006 710.5 1.10001V36H714.3V20.3H719.2C723.7 20.4 725.8 22.4 726.9 27.6ZM714.2 3.60001L714.5 3.5C715.3 3.3 716.9 3.10001 719.3 3.10001C725 3.20001 728.2 5.70002 728.2 10.3C728.2 14.8 724.8 17.6 719.5 17.6H714.2V3.60001Z"
                fill="#004C8E"
            />
            <path d="M752.1 0.600006V35.9H771.5V32.9H755.9V0.600006H752.1Z" fill="#004C8E" />
            <path
                d="M797 24.5L800.9 35.9H805L792.9 0.600006H787.9L775.8 35.9H779.7L783.5 24.5H797ZM787.8 10.7C788.4 8.80001 789 6.70001 789.6 4.20001L789.7 3.89999H790.4L790.6 4.20001L790.7 4.60001C791.2 6.60001 791.7 8.60002 792.5 10.8L796.3 21.8H784L787.8 10.7Z"
                fill="#004C8E"
            />
            <path
                d="M393.3 62C395.8 61.6 399.4 61.3 404.5 61.3C409.6 61.3 413.2 62.3 415.7 64.2C418 66 419.6 69 419.6 72.6C419.6 76.2 418.4 79.2 416.2 81.2C413.4 83.8 409.2 85 404.3 85C403.2 85 402.2 84.9 401.5 84.8V97.7H393.3V62ZM401.5 78.4C402.2 78.6 403.1 78.6 404.3 78.6C408.7 78.6 411.4 76.4 411.4 72.7C411.4 69.4 409.1 67.4 405 67.4C403.3 67.4 402.2 67.6 401.6 67.7V78.4H401.5Z"
                fill="#F4150D"
            />
            <path
                d="M425.3 62C428 61.6 431.9 61.3 436.3 61.3C441.7 61.3 445.5 62.1 448.1 64.1C450.3 65.8 451.5 68.3 451.5 71.7C451.5 76.3 448.2 79.5 445.1 80.6V80.8C447.6 81.8 449.1 84.2 450 87.6C451.1 91.7 452.3 96.4 453 97.8H444.6C444 96.8 443.1 93.8 442.1 89.4C441.1 84.9 439.6 83.7 436.2 83.6H433.7V97.8H425.5V62H425.3ZM433.5 77.6H436.8C440.9 77.6 443.4 75.6 443.4 72.4C443.4 69.1 441.1 67.4 437.3 67.4C435.3 67.4 434.2 67.6 433.6 67.7V77.6H433.5Z"
                fill="#F4150D"
            />
            <path
                d="M491.2 79.3C491.2 91.2 483.9 98.4 473.3 98.4C462.5 98.4 456.1 90.3 456.1 80C456.1 69.2 463.1 61 473.9 61C485 60.9 491.2 69.3 491.2 79.3ZM464.8 79.7C464.8 86.8 468.2 91.8 473.7 91.8C479.3 91.8 482.5 86.5 482.5 79.5C482.5 73 479.4 67.4 473.7 67.4C468 67.4 464.8 72.7 464.8 79.7Z"
                fill="#F4150D"
            />
            <path d="M502.7 68.4H492.9V61.5H521V68.4H511V97.7H502.7V68.4Z" fill="#F4150D" />
            <path d="M547.6 82.4H534.2V91H549.2V97.7H525.9V61.5H548.4V68.2H534.2V75.7H547.6V82.4Z" fill="#F4150D" />
            <path
                d="M582.1 96.7C580.6 97.5 577.2 98.3 572.7 98.3C560.1 98.3 553.6 90.5 553.6 80.2C553.6 67.9 562.5 61 573.5 61C577.8 61 581 61.9 582.5 62.6L580.8 69.1C579.1 68.4 576.8 67.8 573.9 67.8C567.3 67.8 562.3 71.7 562.3 79.8C562.3 87 566.6 91.6 574 91.6C576.5 91.6 579.3 91.1 580.9 90.4L582.1 96.7Z"
                fill="#F4150D"
            />
            <path d="M595.9 68.4H586.1V61.5H614.2V68.4H604.2V97.7H595.9V68.4Z" fill="#F4150D" />
            <path d="M627.4 61.5V97.7H619.1V61.5H627.4Z" fill="#F4150D" />
            <path
                d="M668.5 79.3C668.5 91.2 661.2 98.4 650.6 98.4C639.8 98.4 633.4 90.3 633.4 80C633.4 69.2 640.4 61 651.2 61C662.3 60.9 668.5 69.3 668.5 79.3ZM642.1 79.7C642.1 86.8 645.5 91.8 651 91.8C656.6 91.8 659.8 86.5 659.8 79.5C659.8 73 656.7 67.4 651 67.4C645.3 67.4 642.1 72.7 642.1 79.7Z"
                fill="#F4150D"
            />
            <path
                d="M674.4 97.7V61.5H684L691.6 74.8C693.8 78.6 695.9 83.1 697.6 87.2H697.8C697.3 82.4 697.1 77.5 697.1 72.1V61.5H704.7V97.7H696L688.2 83.7C686 79.8 683.7 75.2 681.9 70.9L681.7 71C681.9 75.8 682 80.9 682 86.8V97.7H674.4Z"
                fill="#F4150D"
            />
            <path
                d="M748.8 92.5C752.1 89.2 753.9 84.5 753.9 78.8C753.9 73.3 752.3 69.2 748.9 66.1C745.7 63.1 741 61.7 734.4 61.7C731.2 61.7 728 61.9 724.8 62.4V97.2C727.2 97.5 729.9 97.6 732.9 97.6C739.9 97.6 745.4 95.8 748.8 92.5ZM728.6 94.4V65.1L729 65C730 64.8 731.9 64.5 734.6 64.5C739.6 64.5 743.5 65.8 746.2 68.5C748.7 71 749.9 74.5 749.9 78.9C749.9 88.9 744.1 94.7 733.9 94.7C731.6 94.7 730.1 94.6 729.1 94.4H728.6Z"
                fill="#004C8E"
            />
            <path d="M779.5 80.3V77.3H765.3V65H780.3V62H761.5V97.3H781.1V94.3H765.3V80.3H779.5Z" fill="#004C8E" />
            <path
                d="M808 87.8C808 83 805.4 80 799 77.6C794.1 75.7 791.4 74 791.4 70.2C791.4 67.3 793.7 64.3 798.7 64.3C801.6 64.3 803.9 65.1 805.4 65.8L806.4 62.9C805.1 62.3 802.7 61.4 798.9 61.4C792.2 61.4 787.5 65.3 787.5 70.8C787.5 75.3 790.5 78.4 796.8 80.6C802 82.6 804 84.7 804 88.2C804 92.2 800.8 94.8 795.9 94.8C793.1 94.8 790.2 94 787.7 92.7L786.8 95.7C788.8 96.9 792.4 97.8 795.6 97.8C803.2 97.8 808 93.9 808 87.8Z"
                fill="#004C8E"
            />
            <path
                d="M404.3 145.8C409.3 145.8 413.3 144.5 415.9 142.1C418 140.1 419.1 137.2 419.1 133.8C419.1 130.6 417.7 127.6 415.4 125.8C413 123.9 409.5 123 404.5 123C400.3 123 396.7 123.2 393.8 123.7V158.6H401.1V145.6L401.6 145.7C402.3 145.8 403.2 145.8 404.3 145.8ZM401 140.2V128.8L401.3 128.7C401.7 128.6 402.8 128.4 404.8 128.4C409.1 128.4 411.7 130.6 411.7 134.2C411.7 138.1 408.8 140.6 404.1 140.6C402.8 140.6 401.9 140.5 401.2 140.4L401 140.2Z"
                fill="#004C8E"
            />
            <path
                d="M442.3 150.6C443.3 154.8 444.1 157.6 444.7 158.7H452.2C451.7 157.4 451.1 155.1 450.4 152.5C450.1 151.4 449.8 150.2 449.5 149.1C448.5 145.5 447.1 143.5 444.9 142.6L444.6 142.5V141.6L444.9 141.5C448 140.4 451 137.4 451 133C451 129.9 449.9 127.5 447.8 125.8C445.4 123.9 441.8 123.1 436.3 123.1C432.5 123.1 428.6 123.3 425.8 123.8V158.6H433.1V144.4H436C439.7 144.5 441.3 146 442.3 150.6ZM433 128.6L433.4 128.5C434.4 128.3 435.7 128.2 437.2 128.2C441.4 128.3 443.7 130.2 443.7 133.7C443.7 137.2 441 139.4 436.7 139.4H433V128.6Z"
                fill="#004C8E"
            />
            <path
                d="M473.8 122.8C463.6 122.8 456.5 130.4 456.5 141.3C456.5 152 463.2 159.3 473.2 159.3C483.8 159.3 490.7 152 490.7 140.7C490.7 131.7 485.5 122.8 473.8 122.8ZM473.7 153.6C468 153.6 464.4 148.7 464.4 141.1C464.4 133.5 468.1 128.3 473.7 128.3C479.2 128.3 483 133.5 483 140.8C482.9 148.6 479.3 153.6 473.7 153.6Z"
                fill="#004C8E"
            />
            <path
                d="M495.9 141.4C495.8 146.6 497.6 151.2 500.9 154.2C504.2 157.4 509 159 515.4 159C519.7 159 524.2 158 527.1 157.1V138.8H514.4V144.3H520V152.6L519.7 152.7C519 153.1 517.4 153.3 515.4 153.3C508.1 153.3 503.5 148.5 503.5 141C503.5 133.5 508.3 128.8 516 128.8C520 128.8 522.5 129.5 524.2 130.2L525.7 124.5C523.9 123.7 520.5 122.9 516.1 122.9C504 123 496 130.2 495.9 141.4Z"
                fill="#004C8E"
            />
            <path
                d="M551.6 150.6C552.6 154.8 553.4 157.6 554 158.7H561.5C561 157.4 560.4 155.1 559.7 152.5C559.4 151.4 559.1 150.2 558.8 149.1C557.8 145.5 556.4 143.5 554.2 142.6L553.9 142.5V141.6L554.2 141.5C557.3 140.4 560.3 137.4 560.3 133C560.3 129.9 559.2 127.5 557.1 125.8C554.7 123.9 551.1 123.1 545.6 123.1C541.8 123.1 537.9 123.3 535.1 123.8V158.6H542.4V144.4H545.3C549.1 144.5 550.7 146 551.6 150.6ZM542.3 128.6L542.7 128.5C543.7 128.3 545 128.2 546.5 128.2C550.7 128.3 553 130.2 553 133.7C553 137.2 550.3 139.4 546 139.4H542.3V128.6Z"
                fill="#004C8E"
            />
            <path
                d="M587.1 149.3L589.9 158.6H597.8L586.8 123.3H576.6L565.7 158.6H573.3L575.9 149.3H587.1ZM579.2 135.9C579.6 134.7 579.9 133.2 580.2 131.7C580.4 130.7 580.6 129.8 580.9 128.9L581 128.6H581.7L581.9 128.9L582.2 130C582.7 131.9 583.2 134.1 583.8 135.9L586.2 144.2H576.9L579.2 135.9Z"
                fill="#004C8E"
            />
            <path
                d="M635.2 145.2L635.7 158.6H642.8L640.8 123.3H630.8L627 135C625.7 139.5 624.4 144.5 623.5 148L623.4 148.3H622.5L622.4 147.9C621.7 144.3 620.7 140.2 619.3 135L615.8 123.3H605.6L603.3 158.6H610L610.6 145.3C610.8 142.4 610.9 139 611 135.7C611.1 133.9 611.1 132.1 611.2 130.3V129.9H612L612.2 130.3C613 134.6 614.2 139.7 615.5 144.4L619.3 158.1H625.1L629.4 144.3C630.9 139.8 632.4 134.7 633.5 130.3L633.6 130H634.6V130.5C634.9 135.9 635 141.5 635.2 145.2Z"
                fill="#004C8E"
            />
            <path
                d="M681.6 145.2L682.1 158.6H689.2L687.2 123.3H677.2L673.4 135C672.1 139.5 670.8 144.5 669.9 148L669.8 148.3H669L668.9 147.9C668.2 144.3 667.2 140.2 665.8 135L662.3 123.3H652.1L649.8 158.6H656.5L657.1 145.3C657.3 142.4 657.4 139 657.5 135.7C657.6 133.9 657.6 132.1 657.7 130.3V129.9H658.5L658.7 130.3C659.5 134.6 660.7 139.7 662 144.4L665.8 158.1H671.6L675.9 144.3C677.4 139.8 678.9 134.7 680 130.3L680.1 130H681.1V130.5C681.2 135.9 681.4 141.5 681.6 145.2Z"
                fill="#004C8E"
            />
            <path d="M717.9 143.3V137.6H704.4V129.2H718.7V123.4H697V158.6H719.4V152.8H704.4V143.3H717.9Z" fill="#004C8E" />
            <path
                d="M749.3 148.4C749.3 143.6 746.5 140.4 740.1 138C735.6 136.3 733 135.2 733 132.5C733 130.7 734.5 128.6 738.8 128.6C741.4 128.6 743.8 129.1 746.1 130.1L747.7 124.4C745.1 123.3 742.2 122.8 738.9 122.8C730.9 122.8 725.5 127 725.5 133.2C725.5 137.8 728.7 141.2 735.2 143.5C739.3 144.9 741.6 146.3 741.6 149C741.6 151.7 739.3 153.4 735.3 153.4C732.5 153.4 729.2 152.6 726.5 151.3L725 157.2C727 158.2 730.8 159.2 734.8 159.2C745.5 159.2 749.3 153.6 749.3 148.4Z"
                fill="#004C8E"
            />
            <path
                d="M54.2 0C24.3 0 0 23 0 51.4V146.5C0 153.1 5.29999 158.5 11.8 158.5C18.3 158.5 23.6 153.1 23.6 146.5V51.4C23.6 36 37.3 23.5 54.2 23.5C71.1 23.5 84.8 36 84.8 51.4V146.5C84.8 153.1 90.1 158.5 96.6 158.5C103.1 158.5 108.4 153.1 108.4 146.5V51.4C108.4 23 84.1 0 54.2 0Z"
                fill="#004C8E"
            />
            <path
                d="M181.7 0H139C132.7 0 127.5 5.30001 127.5 11.7C127.5 18.1 132.7 23.4 139 23.4H181.7C198.6 23.4 212.3 35.9 212.3 51.3C212.3 66.7 198.6 79.2 181.7 79.2H151.1V63.2C151.1 56.7 145.8 51.4 139.3 51.4C132.8 51.4 127.5 56.7 127.5 63.2V146.7C127.5 153.2 132.7 158.4 139 158.4H139.6C145.9 158.4 151.1 153.1 151.1 146.7V105.2C151.1 103.8 152.5 102.7 154.3 102.7H181.7C211.6 102.7 235.9 79.7 235.9 51.3C235.9 22.9 211.6 0 181.7 0Z"
                fill="#004C8E"
            />
            <path
                d="M307.2 0H264.5C258.2 0 253 5.30001 253 11.7C253 18.1 258.2 23.4 264.5 23.4H307.2C324.1 23.4 337.8 35.9 337.8 51.3C337.8 66.7 324.1 79.2 307.2 79.2H276.6V63.2C276.6 56.7 271.3 51.4 264.8 51.4C258.3 51.4 253 56.7 253 63.2V146.7C253 153.2 258.2 158.4 264.5 158.4H265.1C271.4 158.4 276.6 153.1 276.6 146.7V105.1C276.7 103.8 278.1 102.7 279.7 102.7H307.1C337 102.7 361.3 79.7 361.3 51.3C361.3 22.9 337.1 0 307.2 0Z"
                fill="#004C8E"
            />
            <path
                d="M65.8 80.3H63.6L63.4 75.7C63.1 72 59 69.1 54.1 69.1C49.2 69.1 45.1 72 44.8 75.7L44.6 80.3H42.4C41.5 80.3 40.8 81 40.8 81.9V102.1C40.8 103 41.5 103.7 42.4 103.7H65.9C66.8 103.7 67.5 103 67.5 102.1V81.9C67.4 81 66.7 80.3 65.8 80.3ZM55.2 92.7V95.5C55.2 96.2 54.7 96.7 54 96.7C53.3 96.7 52.8 96.2 52.8 95.5V92.7C52.1 92.3 51.5 91.7 51.5 90.7C51.5 89.3 52.6 88.2 54 88.2C55.4 88.2 56.5 89.3 56.5 90.7C56.6 91.7 55.9 92.3 55.2 92.7ZM47.4 80.3L47.5 76.9C47.7 74.4 50.5 72.4 53.9 72.4C57.3 72.4 60.1 74.4 60.3 76.9L60.4 80.3H47.4Z"
                fill="#F4150D"
            />
        </svg>
    );
}
