import classNames from "classnames";
import { ReactNode } from "react";

import { useToast } from "../../utils/hooks";

export default function CopyableElement({ value, children }: { value: string | number; children: ReactNode }): JSX.Element {
    const isCopyable = !!value && value !== "" && value !== "-";

    const notify = useToast();
    const onClick = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        await navigator.clipboard.writeText(value.toString());
        notify.copy(value.toString());
    };

    return (
        <div
            className={classNames({
                "cursor-copy": isCopyable
            })}
            onClick={e => isCopyable && onClick?.(e)}
        >
            {children}
        </div>
    );
}
