import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useApi } from "../hooks";
import { Deposit, DepositStore, Member } from "../model";
import { getDepositById, getDepositStoreByUrl, getMember } from "../services";
import { decodeUrl } from "../tools";

interface Interdeposit {
    deposit: Deposit | undefined;
    depositStore: DepositStore | undefined;
    member: Member | undefined;
    refreshDepositStore: () => void;
}

export const InterdepositContext = createContext({} as Interdeposit);

export function InterdepositContextProvider({ children }: { children: ReactNode }) {
    const { depositUrl, depositStoreUrl, memberUrl } = useParams();

    const decodedDepositUrl = useMemo(() => (depositUrl ? decodeUrl(depositUrl) : undefined), [depositUrl]);
    const decodedDepositStoreUrl = useMemo(() => (depositStoreUrl ? decodeUrl(depositStoreUrl) : undefined), [depositStoreUrl]);
    const decodedMemberUrl = useMemo(() => (memberUrl ? decodeUrl(memberUrl) : undefined), [memberUrl]);

    const [deposit, setDeposit] = useState<Deposit>();
    const [depositStore, setDepositStore] = useState<DepositStore>();
    const [member, setMember] = useState<Member>();

    const { fetchApi } = useApi();

    async function fetch<T>(url: string, fetcher: (url: string) => Promise<T>, setter: (value: T | undefined) => void) {
        setter(await fetchApi(() => fetcher(url)));
    }

    function load<T>(url: string | undefined, fetcher: (url: string) => Promise<T>, setter: (value: T | undefined) => void) {
        setter(undefined);

        if (url) {
            void (() => fetch(url, fetcher, setter))();
        }
    }

    const refreshDepositStore = () => {
        load(decodedDepositStoreUrl, getDepositStoreByUrl, setDepositStore);
    };

    useEffect(() => load(decodedDepositUrl, getDepositById, setDeposit), [decodedDepositUrl]);

    useEffect(() => refreshDepositStore(), [decodedDepositStoreUrl]);

    useEffect(() => load(decodedMemberUrl, getMember, setMember), [decodedMemberUrl]);

    const exposed = useMemo(() => ({ deposit, depositStore, member, refreshDepositStore }), [deposit, depositStore, member]);

    return <InterdepositContext.Provider value={exposed}>{children}</InterdepositContext.Provider>;
}
