import { useMemo, useState } from "react";
import { Translation, getI18nContext, translate, translateRatio } from ".";

export function I18n<T extends Translation>({ locale, locales, children }: { locale: string; locales: { [key: string]: T }; children: React.ReactNode }) {
    const [translation, setTranslation] = useState(locales[locale]);

    const setLocale = (newLocale: string) => setTranslation(locales[newLocale]);

    const I18nContext = getI18nContext<T>();

    const exposed = useMemo(() => ({ translation, translate, translateRatio, setLocale, locale }), [translation, locale]);

    return <I18nContext.Provider value={exposed}>{children}</I18nContext.Provider>;
}
