import { Deposit, Link, Representative, SealStatus, UserInfo } from ".";

export enum AgreementType {
    ACCESS_CLAUSE_STANDARD = "ACCESS_CLAUSE_STANDARD",
    ACCESS_CLAUSE_PREMIUM = "ACCESS_CLAUSE_PREMIUM",
    ESCROW = "ESCROW"
}

export type Agreement = {
    reference: string;
    type: AgreementType;
    canceled: boolean;
};

export type ManagedBy = {
    users: UserInfo[];
    members: Representative[];
    isSharedToRepresentatives: boolean;
};

export interface DepositStore {
    id: string;
    name: string;
    iddn: string;
    deposits?: Deposit[];
    sealStatus: SealStatus;
    createdAt: string;
    updatedAt: string;
    depositsCount: number;
    managedBy: ManagedBy;
    agreementTypes?: AgreementType[];
    agreements?: Agreement[];
    creator: {
        member: {
            role: Deposit.CreatorMemberRole;
        };
    };
    holdersCount: number;
    holders?: Deposit.Holder[];
    _links: {
        self: Link;
        deposits: Link;
        addDeposit: Link;
        holders: Link;
        addUserManager: Link;
        addMemberManager: Link;
        defaultManagedBy?: Link;
    };
}
