import { Button, useI18n } from "@vaultinum/app-sdk";
import { toast } from "react-hot-toast";
import { Translation } from "../i18n";

interface ConfirmationToastProps {
    onConfirm: () => void;
    message: string;
}

const ConfirmationToastContent = ({ toastId, onConfirm, message }: ConfirmationToastProps & { toastId: string }) => {
    const { translation } = useI18n<Translation>();

    const handleConfirmClick = () => {
        onConfirm();
        toast.dismiss(toastId);
    };

    const handleDismissClick = () => {
        toast.dismiss(toastId);
    };

    return (
        <div className="flex flex-col space-y-4">
            <span className="text-xs">{message}</span>
            <div className="flex justify-center space-x-2 items-center">
                <Button onClick={handleConfirmClick} label={translation.yes} variant="danger" />
                <Button onClick={handleDismissClick} label={translation.no} />
            </div>
        </div>
    );
};

export const confirmationToast = ({ onConfirm, message }: ConfirmationToastProps) => {
    toast((t) => <ConfirmationToastContent toastId={t.id} onConfirm={onConfirm} message={message} />, {
        duration: Infinity,
        position: "top-center",
    });
};
