export default function MinusIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                id="Vector minus (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 2C5.9087 2 4.38258 2.63214 3.25736 3.75736C2.13214 4.88258 1.5 6.4087 1.5 8C1.5 9.5913 2.13214 11.1174 3.25736 12.2426C4.38258 13.3679 5.9087 14 7.5 14C9.0913 14 10.6174 13.3679 11.7426 12.2426C12.8679 11.1174 13.5 9.5913 13.5 8C13.5 6.4087 12.8679 4.88258 11.7426 3.75736C10.6174 2.63214 9.0913 2 7.5 2ZM2.1967 2.6967C3.60322 1.29018 5.51088 0.5 7.5 0.5C9.48912 0.5 11.3968 1.29018 12.8033 2.6967C14.2098 4.10322 15 6.01088 15 8C15 9.98912 14.2098 11.8968 12.8033 13.3033C11.3968 14.7098 9.48912 15.5 7.5 15.5C5.51088 15.5 3.60322 14.7098 2.1967 13.3033C0.790176 11.8968 0 9.98912 0 8C0 6.01088 0.790176 4.10322 2.1967 2.6967ZM3.56775 8C3.56775 7.58579 3.90354 7.25 4.31775 7.25H10.6823C11.0965 7.25 11.4323 7.58579 11.4323 8C11.4323 8.41421 11.0965 8.75 10.6823 8.75H4.31775C3.90354 8.75 3.56775 8.41421 3.56775 8Z"
            />
        </svg>
    );
}
