export default function ShieldIcon({ className }: { className?: string }) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 20" className={className} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_907_5641)">
                <path
                    d="M17.9883 3.25781L10.5234 0.144531C10.2188 0.0117188 9.86719 0.0117188 9.55859 0.144531L2.09766 3.25391C1.63281 3.44922 1.32812 3.89844 1.32812 4.40234V11.3047C1.32812 16.1055 5.23828 20.0078 10.0391 20.0078C14.8398 20.0078 18.75 16.1016 18.75 11.3047V4.40234C18.75 3.90234 18.4492 3.45312 17.9883 3.25781ZM17.1875 11.3086C17.1875 15.2461 13.9766 18.4492 10.0391 18.4492C6.10156 18.4492 2.89062 15.2461 2.89062 11.3086V4.61719L10.0391 1.63672L17.1875 4.61719V11.3086Z"
                    fill="#004C93"
                />
                <path
                    d="M7.11719 9.41407C6.84766 9.07813 6.35548 9.02344 6.01954 9.29297C5.6836 9.5625 5.62891 10.0547 5.89844 10.3906L7.64844 12.5742C7.65235 12.5781 7.65626 12.582 7.65626 12.5859C7.66016 12.5938 7.66798 12.5977 7.67188 12.6055C7.67579 12.6094 7.67969 12.6172 7.68751 12.6211L7.70704 12.6406C7.71094 12.6445 7.71876 12.6484 7.72266 12.6563L7.74219 12.6758L7.75782 12.6914C7.76563 12.6953 7.76954 12.7031 7.77735 12.707C7.78907 12.7148 7.80079 12.7266 7.81251 12.7344C7.81641 12.7344 7.81641 12.7383 7.82032 12.7383C7.83204 12.7461 7.84376 12.7539 7.85157 12.7578C7.85548 12.7617 7.85938 12.7617 7.86329 12.7656C7.87501 12.7695 7.88282 12.7773 7.89454 12.7813C7.89844 12.7852 7.90235 12.7852 7.90626 12.7891C7.91407 12.793 7.92579 12.7969 7.9336 12.8047C7.93751 12.8086 7.94141 12.8086 7.94532 12.8125C7.95313 12.8164 7.96485 12.8203 7.97266 12.8242C7.97657 12.8242 7.98048 12.8281 7.98829 12.8281C7.9961 12.832 8.00782 12.8359 8.01563 12.8359C8.01954 12.8359 8.02735 12.8398 8.03126 12.8398C8.03907 12.8438 8.05079 12.8438 8.0586 12.8477C8.06251 12.8477 8.07032 12.8516 8.07423 12.8516C8.08204 12.8555 8.09376 12.8555 8.10157 12.8594C8.10548 12.8594 8.11329 12.8633 8.11719 12.8633C8.12501 12.8633 8.13673 12.8672 8.14454 12.8672C8.15235 12.8672 8.15626 12.8672 8.16407 12.8711C8.17188 12.8711 8.17969 12.8711 8.19141 12.875H8.25391C8.27344 12.875 8.29298 12.875 8.31641 12.8711H8.33204C8.34766 12.8711 8.36329 12.8672 8.37891 12.8672C8.38673 12.8672 8.39063 12.8633 8.39844 12.8633C8.41016 12.8594 8.42579 12.8594 8.43751 12.8555C8.44532 12.8555 8.45313 12.8516 8.46094 12.8477L8.4961 12.8359C8.50391 12.832 8.51173 12.832 8.51954 12.8281L8.55469 12.8164L8.57813 12.8047C8.58985 12.8008 8.60157 12.793 8.60938 12.7891L8.63282 12.7773C8.65235 12.7656 8.66798 12.7578 8.68751 12.7461L14.3164 9.00391C14.6758 8.76563 14.7734 8.28125 14.5352 7.92188C14.2969 7.5625 13.8125 7.46485 13.4531 7.70313L8.41798 11.0508L7.11719 9.41407Z"
                    fill="#004C93"
                />
            </g>
            <defs>
                <clipPath id="clip0_907_5641">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
