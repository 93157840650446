import { useI18n } from "@vaultinum/app-sdk";
import { Translation } from "../../../../i18n";
import { Notifications } from "../../../../model";

export default function EventHistory({ eventHistory }: { eventHistory: Notifications.Event[] }) {
    const { translation } = useI18n<Translation>();
    return (
        <div
            className="rounded-lg border animate-enter w-64 absolute top-0 left-0 right-0 bottom-0 p-5"
            style={{
                background: "rgba(55, 55, 55, 0.26)",
                boxShadow: "17px 20px 40px 0px rgba(0, 0, 0, 0.25)",
            }}
        >
            <div className="bg-white rounded-lg p-4">
                <span className="text-sm font-bold text-primary">{translation.eventHistory}</span>
                <div className="flex flex-col max-h-96 overflow-auto space-y-1">
                    {[...eventHistory].reverse().map((event, index) => (
                        <div key={index} className="flex flex-col animate-enter">
                            <span className="text-sm">{event.label}</span>
                            <span className="text-xxs font-light">{event.date}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
