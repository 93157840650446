export default function CreditCardIcon({ className }: { className?: string }) {
    return (
        <svg className={className} viewBox="0 0 30 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3C0 1.34315 1.34315 0 3 0H27C28.6569 0 30 1.34315 30 3V17C30 18.6569 28.6569 20 27 20H3C1.34315 20 0 18.6569 0 17V3Z" fill="#004C93" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30 7H0V4H30V7Z" fill="#EDF2F7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27 14.5C27 15.0523 26.5523 15.5 26 15.5H20C19.4477 15.5 19 15.0523 19 14.5C19 13.9477 19.4477 13.5 20 13.5H26C26.5523 13.5 27 13.9477 27 14.5Z"
                fill="#EDF2F7"
            />
        </svg>
    );
}
