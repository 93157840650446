import { I18n } from "@vaultinum/app-sdk";
import AppRouter from "./AppRouter";
import { Maintenance } from "./components";
import { fr } from "./i18n";

export default function App(): JSX.Element {
    if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
        return (
            <I18n locale="fr" locales={{ fr }}>
                <Maintenance />
            </I18n>
        );
    }

    return <AppRouter />;
}
