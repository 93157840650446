import { Chip } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";
import { Member } from "../../model";

const CREDIT_STATUS_COLORS: { [key in Member.CreditStatus]: "success" | "warning" } = {
    [Member.CreditStatus.ACTIVE]: "success",
    [Member.CreditStatus.EXPIRED]: "warning"
};

export default function MemberCreditStatus({ status, size = "small" }: { status: Member.CreditStatus; size?: "small" | "medium" }): JSX.Element {
    const { translation } = useI18n<Translation>();

    return <Chip label={translation.member.creditStatus[status]} type={CREDIT_STATUS_COLORS[status]} size={size} />;
}
