import React, { useEffect, useState } from "react";

import classNames from "classnames";
import Button from "../Button/Button";
import { MinusIcon, PlusIcon } from "../Icons";

export default function IncrementInput({
    onChange,
    value,
    max,
    min = 0,
    disabled
}: {
    onChange: (inputValue: number) => void;
    value?: number;
    max?: number;
    min?: number;
    disabled?: boolean;
}) {
    const [currentValue, setCurrentValue] = useState<number>(0);

    useEffect(() => {
        if (value !== undefined) {
            setCurrentValue(value);
        }
    }, [value]);

    const setInputValueIfValid = (inputValue: number) => {
        if (inputValue >= min && (max === undefined || inputValue <= max)) {
            setCurrentValue(inputValue);
            onChange(inputValue);
        }
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValueIfValid(Number(e.target.value));
    };

    const updateInputValueBy = (inputValue: number) => {
        setInputValueIfValid(currentValue + inputValue);
    };

    return (
        <div className="flex space-x-2.5">
            <Button
                variant="iconButton"
                onClick={() => updateInputValueBy(-1)}
                icon={
                    <MinusIcon
                        className={classNames("w-3.5 h-3.5", {
                            "fill-color-disabled cursor-not-allowed": disabled,
                            "fill-primary": !disabled
                        })}
                    />
                }
                disabled={disabled}
            />
            <input
                min={String(min)}
                max={String(max)}
                type="number"
                className={classNames(
                    "w-9 h-5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-deep-blue text-sm text-center bg-primary-xlight",
                    {
                        "cursor-not-allowed": disabled
                    }
                )}
                onChange={handleInputChange}
                value={currentValue}
                disabled={disabled}
            />
            <Button
                variant="iconButton"
                onClick={() => updateInputValueBy(1)}
                icon={
                    <PlusIcon
                        className={classNames("w-3.5 h-3.5", {
                            "fill-color-disabled cursor-not-allowed": disabled,
                            "fill-primary": !disabled
                        })}
                    />
                }
                disabled={disabled}
            />
        </div>
    );
}
