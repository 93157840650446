import { Area, AreaActive, AreaDisabled } from "../../../../components";
import { IconType } from "../../../../model";

export default function AreaButton({ areaIcon, onArea, processingClick }: { areaIcon: IconType; onArea: () => void; processingClick: boolean }) {
    if (processingClick) {
        return <AreaDisabled />;
    }
    if (areaIcon === IconType.DEFAULT) {
        return <Area onClick={onArea} />;
    } else if (areaIcon === IconType.ACTIVE) {
        return <AreaActive onClick={onArea} />;
    } else {
        return <AreaDisabled />;
    }
}
