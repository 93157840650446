import React from "react";

import { Deposit } from "../../model";
import { Chip, Cloud, Mail, QuestionMark } from "../../../design-system";

const ICON: { [key in Deposit.Type]: React.ReactNode } = {
    [Deposit.Type.DIGITAL]: <Cloud />,
    [Deposit.Type.PHYSICAL]: <Mail />,
    [Deposit.Type.UNKNOWN]: <QuestionMark />
};

export default function DepositStatusIcon({
    depositType,
    tooltipContent,
    size = "small"
}: {
    depositType: Deposit.Type;
    tooltipContent: string;
    size?: "small" | "medium";
}) {
    return <Chip label={ICON[depositType]} tooltipContent={tooltipContent} type="primary" size={size} invertColors />;
}
