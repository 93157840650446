(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@vercel/edge"), require("@vercel/edge-config"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@vercel/edge", "@vercel/edge-config"], factory);
	else if(typeof exports === 'object')
		exports["SharedWebAppSDK"] = factory(require("react"), require("@vercel/edge"), require("@vercel/edge-config"));
	else
		root["SharedWebAppSDK"] = factory(root["react"], root["@vercel/edge"], root["@vercel/edge-config"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__617__, __WEBPACK_EXTERNAL_MODULE__38__) => {
return 