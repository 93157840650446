export default function AreaActive({ onClick, color = "#0C0171" }: { onClick: () => void; color?: string }) {
    return (
        <svg cursor="pointer" onClick={onClick} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_ii_84_259)">
                <rect width="32" height="32" rx="5" fill="white" fillOpacity="0.5" />
            </g>
            <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#F5F5F5" />
            <g clipPath="url(#clip0_84_259)">
                <path
                    d="M25.1163 3.34882H19.5349C19.2881 3.34882 19.0515 3.44682 18.8771 3.62127C18.7026 3.79573 18.6046 4.03234 18.6046 4.27905C18.6046 4.52576 18.7026 4.76237 18.8771 4.93682C19.0515 5.11127 19.2881 5.20928 19.5349 5.20928H24.186V9.86044C24.186 10.1072 24.284 10.3438 24.4585 10.5182C24.6329 10.6927 24.8695 10.7907 25.1163 10.7907C25.363 10.7907 25.5996 10.6927 25.774 10.5182C25.9485 10.3438 26.0465 10.1072 26.0465 9.86044V4.27905C26.0465 4.03234 25.9485 3.79573 25.774 3.62127C25.5996 3.44682 25.363 3.34882 25.1163 3.34882ZM13.9535 20.093H9.3023V15.4418C9.3023 15.1951 9.20429 14.9585 9.02984 14.7841C8.85539 14.6096 8.61878 14.5116 8.37207 14.5116C8.12535 14.5116 7.88874 14.6096 7.71429 14.7841C7.53984 14.9585 7.44183 15.1951 7.44183 15.4418V21.0232C7.44183 21.2699 7.53984 21.5066 7.71429 21.681C7.88874 21.8555 8.12535 21.9535 8.37207 21.9535H13.9535C14.2002 21.9535 14.4368 21.8555 14.6112 21.681C14.7857 21.5066 14.8837 21.2699 14.8837 21.0232C14.8837 20.7765 14.7857 20.5399 14.6112 20.3655C14.4368 20.191 14.2002 20.093 13.9535 20.093Z"
                    fill={color}
                />
                <g clipPath="url(#clip1_84_259)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.0931 21.5117C20.427 21.8457 20.9685 21.8457 21.3024 21.5117C21.6364 21.1778 21.6364 20.6364 21.3024 20.3024C20.9685 19.9685 20.427 19.9685 20.0931 20.3024C19.7592 20.6364 19.7592 21.1778 20.0931 21.5117ZM22.2094 22.4187C21.3745 23.2536 20.021 23.2536 19.1861 22.4187C18.3513 21.5839 18.3513 20.2303 19.1861 19.3954C20.021 18.5606 21.3745 18.5606 22.2094 19.3954C23.0442 20.2303 23.0442 21.5839 22.2094 22.4187Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.0233 17.5814C24.3573 17.9154 24.8987 17.9154 25.2326 17.5814C25.5666 17.2475 25.5666 16.7061 25.2326 16.3721C24.8987 16.0382 24.3573 16.0382 24.0233 16.3721C23.6894 16.7061 23.6894 17.2475 24.0233 17.5814ZM26.1396 18.4884C25.3048 19.3233 23.9512 19.3233 23.1164 18.4884C22.2815 17.6536 22.2815 16.3 23.1164 15.4651C23.9512 14.6303 25.3048 14.6303 26.1396 15.4651C26.9745 16.3 26.9745 17.6536 26.1396 18.4884Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.1604 15.9516C19.2257 15.6035 19.5608 15.3742 19.9089 15.4395L23.2755 16.0707C23.6236 16.136 23.8529 16.4711 23.7876 16.8192C23.7223 17.1674 23.3872 17.3967 23.0391 17.3314L19.6725 16.7002C19.3244 16.6349 19.0951 16.2998 19.1604 15.9516Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.5402 20.0668C20.1921 20.1321 19.8569 19.9028 19.7916 19.5546L18.0148 10.0778C17.9495 9.72969 18.1788 9.39456 18.5269 9.32929C18.875 9.26401 19.2102 9.49331 19.2755 9.84145L21.0523 19.3182C21.1176 19.6664 20.8883 20.0015 20.5402 20.0668Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.0502 14.806C13.1155 14.4578 13.4506 14.2285 13.7988 14.2938L17.6547 15.0168C18.0028 15.082 18.2321 15.4172 18.1669 15.7653C18.1016 16.1134 17.7664 16.3427 17.4183 16.2775L13.5624 15.5545C13.2143 15.4892 12.985 15.1541 13.0502 14.806Z"
                        fill={color}
                    />
                </g>
            </g>
            <path
                d="M26.7964 28.173V29.0233H23.9802V28.173H26.7964ZM24.3363 23.7326V29.0233H23.2462V23.7326H24.3363ZM26.4294 25.8874V26.7159H23.9802V25.8874H26.4294ZM26.7927 23.7326V24.5865H23.9802V23.7326H26.7927Z"
                fill={color}
            />
            <path d="M22.0001 23.7326V29.0233H20.91L18.7843 25.4768V29.0233H17.6942V23.7326H18.7843L20.9137 27.2827V23.7326H22.0001Z" fill={color} />
            <path
                d="M16.6116 26.2544V26.5051C16.6116 26.9072 16.5571 27.2682 16.4481 27.588C16.3391 27.9077 16.1853 28.1803 15.9866 28.4056C15.788 28.6284 15.5506 28.7992 15.2744 28.9179C15.0007 29.0366 14.6966 29.096 14.3623 29.096C14.0305 29.096 13.7264 29.0366 13.4503 28.9179C13.1765 28.7992 12.9391 28.6284 12.7381 28.4056C12.537 28.1803 12.3808 27.9077 12.2693 27.588C12.1603 27.2682 12.1058 26.9072 12.1058 26.5051V26.2544C12.1058 25.8498 12.1603 25.4889 12.2693 25.1715C12.3783 24.8518 12.5322 24.5792 12.7308 24.354C12.9319 24.1287 13.1693 23.9567 13.443 23.838C13.7192 23.7193 14.0232 23.6599 14.3551 23.6599C14.6894 23.6599 14.9934 23.7193 15.2671 23.838C15.5433 23.9567 15.7807 24.1287 15.9794 24.354C16.1804 24.5792 16.3355 24.8518 16.4445 25.1715C16.5559 25.4889 16.6116 25.8498 16.6116 26.2544ZM15.5106 26.5051V26.2471C15.5106 25.9661 15.4852 25.719 15.4343 25.5058C15.3834 25.2927 15.3083 25.1134 15.209 24.9681C15.1097 24.8227 14.9886 24.7137 14.8456 24.641C14.7027 24.5659 14.5392 24.5284 14.3551 24.5284C14.171 24.5284 14.0075 24.5659 13.8645 24.641C13.724 24.7137 13.6041 24.8227 13.5048 24.9681C13.4079 25.1134 13.334 25.2927 13.2831 25.5058C13.2323 25.719 13.2068 25.9661 13.2068 26.2471V26.5051C13.2068 26.7837 13.2323 27.0308 13.2831 27.2464C13.334 27.4596 13.4091 27.64 13.5084 27.7878C13.6077 27.9332 13.7289 28.0434 13.8718 28.1185C14.0147 28.1936 14.1782 28.2311 14.3623 28.2311C14.5465 28.2311 14.71 28.1936 14.8529 28.1185C14.9958 28.0434 15.1157 27.9332 15.2126 27.7878C15.3095 27.64 15.3834 27.4596 15.4343 27.2464C15.4852 27.0308 15.5106 26.7837 15.5106 26.5051Z"
                fill={color}
            />
            <path
                d="M11.3685 28.173V29.0233H7.61845V28.173H11.3685ZM11.3139 24.3394L8.10174 29.0233H7.33502V28.3983L10.5727 23.7326H11.3139V24.3394ZM10.9397 23.7326V24.5865H7.33866V23.7326H10.9397Z"
                fill={color}
            />
            <rect x="8.44183" y="4.72095" width="7.67442" height="7.67442" stroke={color} strokeWidth="2" strokeDasharray="2 2" />
            <defs>
                <filter id="filter0_ii_84_259" x="-2" y="-2" width="36" height="36" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_84_259" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_84_259" result="effect2_innerShadow_84_259" />
                </filter>
                <clipPath id="clip0_84_259">
                    <rect width="22.3256" height="22.3256" fill="white" transform="translate(5.95349 1.48834)" />
                </clipPath>
                <clipPath id="clip1_84_259">
                    <rect width="13.6818" height="13.6818" fill="white" transform="translate(20.0931 26.0466) rotate(-135)" />
                </clipPath>
            </defs>
        </svg>
    );
}
