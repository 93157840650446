import { getAccessToken } from "./authentication.service";
import * as Http from "./http.service";

const headers = (): { [key: string]: string } => {
    return {
        Authorization: `Bearer ${getAccessToken()}`
    };
};

export async function doGet<T>(url: string, queryParams?: Record<string, string>): Promise<T> {
    return Http.doGet(url, queryParams, headers());
}

export async function doPost<T>(url: string, body?: { [key: string]: unknown } | FormData): Promise<T> {
    return Http.doPost(url, body, headers());
}

export async function doPut<T>(url: string, body?: { [key: string]: unknown }): Promise<T> {
    return Http.doPut(url, body, headers());
}

export async function doDelete<T>(url: string, body?: { [key: string]: unknown }): Promise<T> {
    return Http.doDelete(url, body, headers());
}

export async function download(url: string, queryParams?: Record<string, string>): Promise<{ name: string; blob: Blob }> {
    return Http.download(url, queryParams, headers());
}

