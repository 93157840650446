import { useI18n, useToast } from "@vaultinum/app-sdk";
import { InteractionEvent, Report } from "@vaultinum/vaultinum-legal-proof-api";
import { Dispatch, RefObject, SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_MANAGER_PAGE } from "../AppRouter";
import { useSession } from "../context";
import { Translation } from "../i18n";
import { ActionStatus, Notifications } from "../model";
import { AreaDraw } from "../tool";

interface Handlers {
    onFrame: (data: { images: string[] }) => void;
    onUrl: (data: { url: string }) => void;
    onCursor: (data: { cursor: string }) => void;
    onInvalidUrl: () => void;
    onSessionDuration: (sessionDuration: string) => void;
    onEventDone: ({ id, date, label }: Notifications.Event) => void;
    onNewTab: () => void;
    onEventError: () => void;
    onPauseInteractions: (data: { fullPage: boolean }) => void;
    onResumeInteractions: (data: { fullPage: boolean }) => void;
    onTaskUpdate: (data: { key: string }) => void;
    onBrowserError: () => void;
    onInvalidReportMetadata: (data: { name: string }) => void;
}

export const useSessionHandlers = (
    canvasRef: RefObject<HTMLCanvasElement>,
    setRemoteUrl: (url: string) => void,
    setSessionDuration: (sessionDuration: string) => void,
    setEventHistory: Dispatch<SetStateAction<Notifications.Event[]>>,
    sessionStarted: boolean,
    setSessionStarted: (sessionStarted: boolean) => void,
    setProcessingClick: (processingClick: boolean) => void,
    setActionsStatus: (actionStatus: ActionStatus) => void,
    userUid: string,
    setProgressionText: (progressionText: string) => void,
    setErrorDuringInitialization: (error: boolean) => void
): Handlers => {
    const { socket } = useSession();
    const { info, error } = useToast();
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();

    const onFrame = useCallback(
        (data: { images: string[] }) => {
            const canvas = canvasRef.current;
            if (!canvas) {
                return;
            }

            const context = canvas.getContext("2d");
            if (!context) {
                return;
            }

            const { width, height, left } = canvas.getBoundingClientRect();
            const offset = Math.ceil(left - canvas.offsetLeft);

            data.images.forEach((base64Image, index) => {
                const rowHeight = height / data.images.length;
                const image = new Image();
                image.onload = () => context.drawImage(image, 0, index * rowHeight, width + offset, rowHeight);
                image.src = `data:image/webp;base64,${base64Image}`;
            });

            setSessionStarted(true);
        },
        [canvasRef, setSessionStarted]
    );

    const onUrl = useCallback((data: { url: string }) => setRemoteUrl(data.url), [setRemoteUrl]);

    const onCursor = useCallback(
        (data: { cursor: string }) => {
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.style.cursor = data.cursor;
            }
        },
        [canvasRef]
    );

    const onInvalidUrl = useCallback(() => {
        setErrorDuringInitialization(true);
        error(translation.invalidUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, setErrorDuringInitialization]);

    const onSessionDuration = useCallback((duration: string) => setSessionDuration(duration), [setSessionDuration]);

    const onEventDone = useCallback(
        ({ id, date, label }: Notifications.Event) => {
            setEventHistory((prev) => [
                {
                    date,
                    label,
                },
                ...prev,
            ]);

            if (id === InteractionEvent.AREA) {
                info(translation.screenCapturedSuccessfully);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onNewTab = useCallback(() => info(translation.newTabNotSupported), [info, translation]);

    const onEventError = useCallback(() => error(translation.errorWhileProcessingEvent), [error, translation]);

    const onPauseInteractions = useCallback(
        ({ fullPage }: { fullPage: boolean }) => {
            if (sessionStarted) {
                setProcessingClick(true);
                new AreaDraw(socket, userUid, setActionsStatus, "wait");
            }
            socket.emit(InteractionEvent.PAUSE_INTERACTIONS, { userUid });
            if (fullPage) {
                info(translation.pauseInteractionsDuringFullPageCapture);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [info, translation, setProcessingClick, socket, userUid, setActionsStatus]
    );

    const onResumeInteractions = useCallback(
        ({ fullPage }: { fullPage: boolean }) => {
            setProcessingClick(false);
            document.getElementById("new-canvas")?.remove();
            socket.emit(InteractionEvent.RESUME_INTERACTIONS, { userUid });
            if (fullPage) {
                info(translation.resumeInteractionsAfterFullPageCapture);
            }
        },
        [info, translation, setProcessingClick, socket, userUid]
    );

    const onBrowserError = useCallback(() => {
        setErrorDuringInitialization(true);
        error(translation.browserError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, setErrorDuringInitialization]);

    const onTaskUpdate = useCallback((data: { key: string }) => {
        let text = "";
        switch (data.key) {
            case Report.TaskType.START_SESSION:
                text = translation.sessionStartFeedback.startSession;
                break;
            case Report.TaskType.BROWSER_SETUP:
                text = translation.sessionStartFeedback.browserSetup;
                break;
            case Report.TaskType.OPEN_PAGE:
                text = translation.sessionStartFeedback.openPage;
                break;
            case Report.TaskType.CAPTURE_FIRST_PAGE:
                text = translation.sessionStartFeedback.captureFirstPage;
                break;
            case Report.TaskType.CAPTURE_HTML:
                text = translation.sessionStartFeedback.captureHtml;
                break;
            default:
                break;
        }
        if (text) {
            setProgressionText(text);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onInvalidReportMetadata = useCallback(
        (data: { name: string }) => {
            error(data.name === "reportTitle" ? translation.reportTitleNoSpecialChars : translation.reportRequesterNoSpecialChars);
            navigate(REPORT_MANAGER_PAGE);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error, navigate]
    );

    return {
        onFrame,
        onUrl,
        onCursor,
        onInvalidUrl,
        onSessionDuration,
        onEventDone,
        onNewTab,
        onEventError,
        onPauseInteractions,
        onResumeInteractions,
        onTaskUpdate,
        onBrowserError,
        onInvalidReportMetadata,
    };
};
