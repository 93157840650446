import { ReactNode } from "react";
import { BoxIcon, Cloud, QuestionMark } from "../../../design-system";
import { Deposit } from "../../model";

const icons: { [key in Deposit.Type]: ReactNode } = {
    [Deposit.Type.DIGITAL]: <Cloud className="fill-primary" />,
    [Deposit.Type.PHYSICAL]: <BoxIcon className="fill-primary" />,
    [Deposit.Type.UNKNOWN]: <QuestionMark className="fill-primary stroke-primary" />
};

export default function DepositModeIcon({ mode }: { mode: Deposit.Type }) {
    return icons[mode];
}
