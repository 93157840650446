import classNames from "classnames";

export default function Dot({ color, size = "medium" }: { color: string; size?: "small" | "medium" | "large" }): JSX.Element {
    return (
        <span
            className={classNames("flex rounded-full", color, {
                "w-1.5 h-1.5": size === "small",
                "w-2.5 h-2.5": size === "medium",
                "w-3.5 h-3.5": size === "large"
            })}
        />
    );
}
