import classNames from "classnames";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { Button, Card, ClipBoardIcon, CopyableElement, EditIcon, useToast } from "../../../design-system";
import { useApi } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { Deposit, SealStatus } from "../../model";
import { deleteAllFiles, getDeleteDepositMetadataFn } from "../../services";
import DepositCardContent from "../DepositCardContent/DepositCardContent";
import { DepositModeIcon } from "../DepositIcon";
import { DepositStatus } from "../DepositStatus";

const DepositIddn = ({ deposit }: { deposit: Deposit }) => {
    return deposit.iddn ? <CopyableElement value={deposit.iddn}>{deposit.iddn}</CopyableElement> : <span>-</span>;
};

const Footer = ({ children }: { children: React.ReactNode }): JSX.Element => (
    <footer className={classNames("flex bg-primary-xlight px-2.5 py-2 border-t items-center")}>{children}</footer>
);

const copyToClipBoard = async (value: string) => {
    await navigator.clipboard.writeText(value);
};

const CopyButton = ({ value, label }: { value: string; label: string }): JSX.Element => {
    const notify = useToast();

    return (
        <Button
            variant="iconButton"
            className="h-7"
            label={label}
            outlined
            icon={<ClipBoardIcon className="w-5 h-5" />}
            onClick={async () => {
                await copyToClipBoard(value);
                notify.copy(value);
            }}
        />
    );
};

const actionButton = (
    translation: Translation,
    navigate: NavigateFunction,
    deposit: Deposit,
    onLeftClick?: (deposit: Deposit) => void,
    depositUpdateLinkGenerator?: (deposit: Deposit) => string,
    deleteAllFilesFn?: () => Promise<void>,
    getDeleteMetaData?: () => Promise<void>
) => {
    if (deleteAllFilesFn) {
        return undefined;
    } else if (getDeleteMetaData) {
        return <Button variant="danger" label={translation.cancel} onClick={() => onLeftClick?.(deposit)} />;
    } else if (deposit._links.update && depositUpdateLinkGenerator) {
        return (
            <Button
                variant="iconButton"
                outlined
                icon={<EditIcon className="w-4 h-4" />}
                className="w-7 h-7 flex justify-center items-center"
                onClick={() => navigate(depositUpdateLinkGenerator(deposit))}
            />
        );
    }

    return undefined;
};

export default function DepositCard({
    deposit,
    closeable = false,
    open = true,
    depositUpdateLinkGenerator,
    onLeftClick,
    onRightClick,
    refreshCallback,
    showToolTip = true,
    copyable = false,
    displayCopyUuid = false
}: {
    deposit: Deposit;
    closeable?: boolean;
    open?: boolean;
    depositUpdateLinkGenerator?: (deposit: Deposit) => string;
    onLeftClick?: (deposit: Deposit) => void;
    onRightClick?: (deposit: Deposit) => void;
    refreshCallback?: () => void;
    showToolTip?: boolean;
    copyable?: boolean;
    displayCopyUuid?: boolean;
}) {
    const { translation } = useI18n<Translation>();
    const { fetchApi } = useApi();
    const [cancelingUpload, setCancelingUpload] = useState(false);
    const navigate = useNavigate();

    const deleteAllFilesFn = deleteAllFiles(deposit);
    const getDeleteMetaData = getDeleteDepositMetadataFn(deposit);

    const leftContent = (): JSX.Element | undefined => {
        const actionButtonComponent = actionButton(
            translation,
            navigate,
            deposit,
            onLeftClick,
            depositUpdateLinkGenerator,
            deleteAllFilesFn,
            getDeleteMetaData
        );
        if (actionButtonComponent) {
            return (
                <div className="flex items-center gap-2">
                    {actionButtonComponent}
                    {displayCopyUuid && (
                        <>
                            <CopyButton value={deposit.id} label={translation.uuid} />
                            <CopyButton value={deposit.depositStoreId} label={translation.uuidParent} />
                        </>
                    )}
                    {copyable && [SealStatus.SEALED, SealStatus.ARCHIVED].includes(deposit.sealStatus) && (
                        <CopyButton value={translation.depositCopyValue(deposit)} label={translation.resume} />
                    )}
                </div>
            );
        } else {
            return undefined;
        }
    };

    const rightContent = (): JSX.Element | undefined => {
        if (deleteAllFilesFn) {
            return (
                <Button
                    label={translation.cancelUpload}
                    onClick={async () => {
                        setCancelingUpload(true);

                        await fetchApi(deleteAllFilesFn);

                        if (refreshCallback) {
                            refreshCallback();
                        }
                    }}
                    loading={cancelingUpload}
                />
            );
        } else if (deposit._links.resume) {
            return <Button label={translation.continue} onClick={() => onRightClick?.(deposit)} />;
        } else if (deposit._links.certificate) {
            return (
                <Button
                    label={translation.deposit.downloadCertificate}
                    disabled={!deposit._links.certificate?.href}
                    onClick={() => {
                        window.open(deposit._links.certificate?.href);
                    }}
                />
            );
        } else {
            return undefined;
        }
    };

    const rightContentResult = rightContent();
    const leftContentResult = leftContent();

    return (
        <Card
            open={open}
            closable={closeable}
            title={<span className="font-bold">{deposit.name}</span>}
            subTitle={<DepositIddn deposit={deposit} />}
            icon={
                <span title={translation.deposit.depositType[deposit.depositType]}>
                    <DepositModeIcon mode={deposit.depositType} />
                </span>
            }
            rightContent={
                <span className="px-1.5">
                    <DepositStatus
                        label={translation.deposit.sealStatus[deposit.depositType][deposit.sealStatus]}
                        status={deposit.sealStatus}
                        size="small"
                        showToolTip={showToolTip}
                    />
                </span>
            }
            footer={
                (rightContentResult || leftContentResult) && (
                    <Footer>
                        <div
                            className={classNames("flex w-full", {
                                "justify-between items-center": leftContentResult && rightContentResult,
                                "justify-end": !leftContentResult && rightContentResult
                            })}
                        >
                            {leftContentResult}
                            {rightContentResult}
                        </div>
                    </Footer>
                )
            }
        >
            <DepositCardContent deposit={deposit} />
        </Card>
    );
}
