import { InteractionEvent } from "@vaultinum/vaultinum-legal-proof-api";
import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { useAuth } from "../../../../auth";
import { useSession } from "../../../../context";

const RED_POINT_SIZE = 25;

export default function Video({
    canvasRef,
    dimensions,
    sessionStarted,
    progressionText,
    errorDuringInitialization,
}: {
    canvasRef: React.RefObject<HTMLCanvasElement>;
    dimensions: { width: number; height: number };
    sessionStarted: boolean;
    progressionText: string;
    errorDuringInitialization: boolean;
}) {
    const { socket } = useSession();
    const { user } = useAuth();
    const userUid = user?.uid ?? "";

    const progressionTextRef = useRef<CanvasRenderingContext2D | null>(null);

    useEffect(() => {
        if (canvasRef.current) {
            const context = canvasRef.current.getContext("2d");
            if (context) {
                progressionTextRef.current = context;
            }
        }
    }, [canvasRef]);

    useEffect(() => {
        const context = progressionTextRef.current;
        if (context) {
            context.clearRect(0, 0, dimensions.width, dimensions.height);
            context.font = "18px Arial";
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillStyle = "black";
            context.fillText(progressionText, dimensions.width / 2, dimensions.height / 2);
        }
    }, [progressionText, dimensions]);

    useEffect(() => {
        if (errorDuringInitialization) {
            const context = progressionTextRef.current;
            if (context) {
                context.clearRect(0, 0, dimensions.width, dimensions.height);
            }
        }
    }, [errorDuringInitialization, dimensions]);

    function mouseAction(action: "click" | "move" | "scroll" | "down" | "up", x: number, y: number) {
        if (!socket.active) {
            return;
        }

        socket.emit(InteractionEvent.MOUSE, { userUid, action, x, y });
    }

    function keyboardAction(key: string) {
        socket.emit(InteractionEvent.KEYBOARD, { userUid, key });
    }

    function handleMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const x = e.clientX - (canvasRef.current?.getBoundingClientRect().left || 0);
        const y = e.clientY - (canvasRef.current?.getBoundingClientRect().top || 0);
        mouseAction("move", x, y);
    }

    function handleMouseScroll(e: React.WheelEvent<HTMLCanvasElement>) {
        const x = e.deltaX;
        const y = e.deltaY;
        mouseAction("scroll", x, y);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLCanvasElement>) {
        if (e.ctrlKey && e.key !== "Control") {
            keyboardAction(`Control+${e.key.toUpperCase()}`);
        } else {
            keyboardAction(e.key);
        }
    }

    function getCoordinates(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const rect = canvasRef.current?.getBoundingClientRect();

        const x = e.clientX - (rect?.left ?? 0);
        const y = e.clientY - (rect?.top ?? 0);

        return { x, y };
    }

    function handleMouseClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const { x, y } = getCoordinates(e);

        if (sessionStarted) {
            const circle = document.createElement("span");
            circle.style.left = `${x - RED_POINT_SIZE / 2}px`;
            circle.style.top = `${y - RED_POINT_SIZE / 2}px`;
            circle.style.height = `${RED_POINT_SIZE}px`;
            circle.style.width = `${RED_POINT_SIZE}px`;
            circle.className = "bg-danger rounded-full opacity-30 absolute";
            document.getElementById("lpr-video")?.appendChild(circle);
            setTimeout(() => {
                circle.remove();
            }, 250);
        }
        mouseAction("click", x, y);
    }

    function handleMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const { x, y } = getCoordinates(e);
        mouseAction("down", x, y);
    }

    function handleMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        const { x, y } = getCoordinates(e);
        mouseAction("up", x, y);
    }

    return (
        <canvas
            id="lpr-canvas"
            className={classNames("flex w-full h-full bg-white rounded-md", {
                "border-danger-high border-4": sessionStarted,
            })}
            ref={canvasRef}
            width={dimensions.width}
            height={dimensions.height}
            onMouseMove={handleMouseMove}
            onClick={handleMouseClick}
            onWheel={handleMouseScroll}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            tabIndex={0}
        />
    );
}
