import { useI18n } from "@vaultinum/app-sdk";
import { useNavigate } from "react-router-dom";
import { REPORT_MANAGER_PAGE } from "../AppRouter";
import { useSession } from "../context";
import { Button } from "../design-system";
import { Translation } from "../i18n";

export function getDownloadReportStatus(isDownloaded: boolean, hasError: boolean, translation: Translation) {
    if (!isDownloaded) {
        return translation.reportGenerating;
    } else if (hasError) {
        return translation.errorDuringDownload;
    } else {
        return translation.reportGeneratedSuccessfully;
    }
}

export function getDownloadReportSubStatus(isDownloaded: boolean, hasError: boolean, translation: Translation) {
    if (!isDownloaded) {
        return translation.thanksForWaiting;
    } else if (hasError) {
        return translation.weAreWorkingOnIt;
    } else {
        return translation.reportDownloaded;
    }
}

export function getDownloadReportFooterStatus(isDownloaded: boolean, hasError: boolean, translation: Translation) {
    if (!isDownloaded) {
        return translation.reportWillBeDownloaded;
    } else if (hasError) {
        return translation.retryLater;
    } else {
        return translation.reportDownloadedAutomatically;
    }
}

export function DownloadButtons({
    isDownloaded,
    hasError,
    handleDownloadReport,
}: {
    isDownloaded: boolean;
    hasError: boolean;
    handleDownloadReport: () => void;
}) {
    const { socket } = useSession();
    const { translation } = useI18n<Translation>();
    const navigate = useNavigate();

    function onFinish() {
        navigate(REPORT_MANAGER_PAGE);
        socket?.disconnect();
    }

    if (hasError) {
        return <Button label={translation.end} onClick={onFinish} variant="danger" />;
    } else if (!hasError && isDownloaded) {
        return (
            <>
                <Button label={translation.downloadReport} onClick={handleDownloadReport} />
                <Button label={translation.end} onClick={onFinish} variant="danger" />
            </>
        );
    }

    return <></>;
}
