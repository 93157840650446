import { MemberCredit, UserInfo } from "../model";
import { doPut } from "./api.service";

export function allocateCredits(userInfo: UserInfo): ((allocated: number) => Promise<void>) | undefined {
    const link = userInfo._links.allocateCredits;

    if (!link.available) {
        return undefined;
    }

    return (allocated: number) => doPut<void>(link.href, { allocated });
}

export function updateMemberCredit(memberCredit: MemberCredit): ((purchased: number, expiresAt: string) => Promise<void>) | undefined {
    const link = memberCredit._links.update;

    if (!link.available) {
        return undefined;
    }

    return (purchased: number, expiresAt: string) => doPut<void>(link.href, { purchased, expiresAt });
}
