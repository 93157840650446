export default function ListIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 19 11" xmlns="http://www.w3.org/2000/svg" className={className}>
            <g id="Group 846">
                <path
                    id="Vector"
                    d="M6.47312 1H17.5M6.47312 5.5H17.5M6.47312 10H17.5M1.5 1H1.51103M1.5 5.5H1.51103M1.5 10H1.51103"
                    stroke="#004C93"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
}
