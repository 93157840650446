import { ExportApi, Link, Member, MemberExports, Method } from "../model";
import { doGet, download } from "./api.service";

type MemberExportsApi = {
    _links: {
        deposits: Link;
    };
};

const absolute = (url: string): string => {
    return `${process.env.REACT_APP_EXPORT_API_URL}${url}`;
};

export type Export = {
    name: string;
    blob: Blob;
};

export function getExportApi(): Promise<ExportApi> {
    return doGet<ExportApi>(absolute("/v1/api"));
}

export function downloadDepositsSealedStats(exportApi: ExportApi): ((startInclusive: string, endInclusive: string) => Promise<Export>) | undefined {
    const link = exportApi.deposits._links.sealedStats;

    if (!link) {
        return undefined;
    }

    return async (startInclusive: string, endInclusive: string): Promise<Export> => {
        const response = await download(link.href, { startInclusive, endInclusive });

        return {
            name: response.name,
            blob: response.blob
        };
    };
}

export async function getMemberExports(member: Member): Promise<MemberExports> {
    const memberExportsApi = await doGet<MemberExportsApi>(absolute(`/v1/exports/members/${member.id}`));

    const depositsLink = memberExportsApi._links.deposits;

    const deposits = depositsLink.available && depositsLink.method === Method.GET ? async () => download(`${depositsLink.href}?lang=fr`) : undefined;

    return {
        deposits
    };
}
