import { Translation } from "../i18n";
import { Deposit, InputFields } from "../model";
import { Apis } from "../services";

export function getStandardFields(apis: Apis, translation: Translation, labelPosition: "left" | "top" | undefined = "left"): InputFields[] {
    const productClassToOption = (productClass: { value: Deposit.ProductClass; code: string }) => {
        return {
            value: productClass.value,
            label: `${productClass.code} - ${translation.deposit.productClass[productClass.value]}`
        };
    };

    const allWorkTypes = (apis.deposit.workTypes || []).map(workType => {
        return {
            value: workType.value,
            label: `${workType.code} - ${translation.deposit.workType[workType.value]}`
        };
    });
    const allWorkNature = (apis.deposit.workNatures || []).map(workNature => ({
        value: workNature,
        label: translation.deposit.workNature[workNature]
    }));
    const allCountries = (apis.deposit.countries || []).map(country => ({ value: country, label: `${country} - ${translation.country[country]}` }));
    const allProductClasses = (apis.deposit.productClasses || []).map(productClass => {
        if (productClass.values) {
            return {
                label: translation.deposit.productClass[productClass.value],
                values: productClass.values.map(productClassToOption)
            };
        } else {
            return productClassToOption(productClass);
        }
    });

    return [
        {
            name: "name",
            label: (
                <>
                    {translation.depositStore.title} <span className="text-danger-high">*</span>
                </>
            ),
            helperText: translation.helper.title,
            labelPosition: labelPosition
        },
        {
            name: "subtitle",
            label: translation.subtitle,
            helperText: translation.helper.subtitle,
            labelPosition: labelPosition
        },
        {
            name: "workVersion",
            label: translation.version,
            className: "w-1/2",
            labelPosition: labelPosition
        },
        {
            name: "workVersionDate",
            label: translation.versionDate,
            helperText: translation.helper.versionDate,
            className: "w-1/2",
            type: "date",
            labelPosition: labelPosition
        },
        {
            name: "workNature",
            label: translation.work.nature,
            className: "w-1/2",
            labelPosition: labelPosition,
            type: "select",
            options: allWorkNature
        },
        {
            name: "productClass",
            label: (
                <>
                    {translation.productClass} <span className="text-danger-high">*</span>
                </>
            ),
            className: "w-1/2",
            labelPosition: labelPosition,
            type: "select",
            options: allProductClasses
        },
        {
            name: "workType",
            label: (
                <>
                    {translation.work.type} <span className="text-danger-high">*</span>
                </>
            ),
            className: "w-1/2",
            labelPosition: labelPosition,
            type: "select",
            options: allWorkTypes
        },
        {
            name: "countryCode",
            label: (
                <>
                    {translation.work.nationality} <span className="text-danger-high">*</span>
                </>
            ),
            className: "w-1/2",
            labelPosition: labelPosition,
            type: "select",
            options: allCountries
        }
    ];
}

export function getFullFields(
    api: Apis,
    translation: Translation,
    subscriptionTypeCodes: Deposit.SubscriptionTypeCode[] = Object.values(Deposit.SubscriptionTypeCode),
    labelPosition: "left" | "top" | undefined = "left"
): InputFields[] {
    const subscriptionTypes = subscriptionTypeCodes.map(typeCode => ({
        value: typeCode,
        label: `${typeCode} - ${translation.deposit.subscriptionTypeCode[typeCode]}`
    }));

    return [
        {
            name: "subscriptionTypeCode",
            label: translation.deposit.subscriptionType,
            helperText: `${translation.mandatory}.`,
            className: "w-1/2",
            labelPosition: labelPosition,
            type: "select",
            options: subscriptionTypes
        },
        ...getStandardFields(api, translation)
    ];
}
