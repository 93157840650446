import classNames from "classnames";
import { useEffect, useState } from "react";

export default function SuspensionPoints() {
    const [visibleIndex, setVisibleIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleIndex((prevIndex) => (prevIndex + 1) % 4);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {[1, 2, 3].map((index) => (
                <div
                    key={index}
                    className={classNames("animate-pulse", {
                        "opacity-0": visibleIndex < index,
                        [`delay-${(index - 1) * 100}`]: true,
                    })}
                >
                    .
                </div>
            ))}
        </>
    );
}
