export default function ErrorIcon({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M12.5 25A12.5 12.5 0 1 0 0 12.5 12.514 12.514 0 0 0 12.5 25Zm0-22.5a10 10 0 1 1-10 10 10.011 10.011 0 0 1 10-10Z" />
            <path d="m13.556 12.5 3.222-3.215a.753.753 0 0 0-1.064-1.065L12.5 11.443 9.286 8.22a.752.752 0 1 0-1.064 1.065l3.222 3.214-3.222 3.215a.75.75 0 0 0 .243 1.228.75.75 0 0 0 .82-.164l3.215-3.222 3.214 3.222a.749.749 0 0 0 1.064 0 .749.749 0 0 0 0-1.064L13.557 12.5Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m16.778 9.285-3.221 3.214 3.221 3.215a.75.75 0 0 1-.243 1.228.75.75 0 0 1-.82-.164L12.5 13.556l-3.214 3.222a.75.75 0 0 1-1.228-.243.75.75 0 0 1 .164-.82l3.222-3.216-3.222-3.214A.753.753 0 0 1 9.286 8.22l3.214 3.223 3.214-3.223a.752.752 0 1 1 1.064 1.065Zm-4.278 1.45 2.86-2.868a1.254 1.254 0 0 1 1.772 1.771l-2.868 2.861 2.867 2.86a1.254 1.254 0 0 1 .273 1.368 1.249 1.249 0 0 1-.677.677 1.25 1.25 0 0 1-1.367-.273l-2.86-2.867-2.86 2.866a1.253 1.253 0 0 1-1.367.274 1.25 1.25 0 0 1-.677-.677 1.25 1.25 0 0 1 .273-1.367l2.867-2.86-2.868-2.862A1.254 1.254 0 0 1 8.754 7.5c.332 0 .65.132.885.367l2.861 2.868Z"
            />
        </svg>
    );
}
