import { Report } from "@vaultinum/vaultinum-legal-proof-api";
import React, { createContext, useContext, useMemo, useState } from "react";
import { Socket } from "socket.io-client";
import ReportType = Report.ReportType;

type SessionContextType = {
    socket: Socket;
    setSocket: (socket: Socket) => void;
    reportMetadata: Report.RequestMetadata;
    setReportMetadata: (reportMetadata: Report.RequestMetadata) => void;
    sessionDuration: string;
    setSessionDuration: (sessionDuration: string) => void;
};

const SessionContext = createContext({} as SessionContextType);

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
    const [socket, setSocket] = useState<Socket>({} as Socket);
    const [reportMetadata, setReportMetadata] = useState<Report.RequestMetadata>({
        reportTitle: "",
        requester: "",
        reportType: ReportType.OTHER,
    });
    const [sessionDuration, setSessionDuration] = useState("");

    const contextValue = useMemo(
        () => ({ socket, setSocket, reportMetadata, setReportMetadata, sessionDuration, setSessionDuration }),
        [reportMetadata, sessionDuration, socket]
    );

    return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export const useSession = () => {
    return useContext(SessionContext);
};
