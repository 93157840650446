export const Colors = {
    slate: {
        primary: "#004C93",
        "primary-mdlight": "#2265A4",
        secondary: "#4a7398",
        "primary-light": "#D4E4FA",
        "primary-xlight": "#EDF2F7"
    },
    grey: {
        neutral: "#3E5463",
        "color-disabled": "#8C8C8C",
        "input-border-color": "#C0C3C6",
        "medium-grey": "#D3D3D3",
        "neutral-light": "#DCE3E8",
        disabled: "#F2F2F2",
        "light-grey": "#E6E6E6",
        "medium-light-grey": "#ECECEC"
    },
    green: {
        success: "#075E45",
        "success-light": "#C7EBD1",
        "green-light": "#49A329"
    },
    blue: {
        label: "#2B3643",
        "deep-blue": "#325574",
        "light-border": "#6683BB"
    },
    orange: {
        warning: "#8F3415",
        danger: "#A1160A",
        "warning-light": "#FCDDC7",
        primary: "#FF8300"
    },
    red: {
        "primary-red": "#FF0101",
        "danger-high": "#F4150D",
        "danger-disabled": "#FFA5A1",
        "danger-light": "#FADCD9"
    }
};
