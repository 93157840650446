import { io, Socket } from "socket.io-client";

export function initializeSocket(token: string): Socket {
    const lprEngineUrl = new URL(process.env.REACT_APP_LPR_ENGINE_URL || "http://localhost:3000");
    const socketOptions = lprEngineUrl.pathname && lprEngineUrl.pathname !== "/" ? { path: `${lprEngineUrl.pathname}/socket.io` } : undefined;
    const serverUrl = lprEngineUrl.origin;
    return io(serverUrl, {
        ...socketOptions,
        auth: {
            token,
        },
    });
}

export function removeListeners(socket: Socket): void {
    socket.removeAllListeners("frame");
    socket.removeAllListeners("url");
    socket.removeAllListeners("cursor");
    socket.removeAllListeners("invalid-url");
    socket.removeAllListeners("session-duration");
    socket.removeAllListeners("event-done");
    socket.removeAllListeners("new-tab");
    socket.removeAllListeners("pause-interactions");
    socket.removeAllListeners("resume-interactions");
}
