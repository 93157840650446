export default function LetterOpen({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M14.682 4.784c-.7-.551-1.28-.994-4.62-3.42C9.595 1.022 8.667.2 8.012.2h-.023c-.656 0-1.584.822-2.053 1.164-3.34 2.425-3.92 2.87-4.619 3.42A1.343 1.343 0 0 0 .8 5.848V12.8a1.8 1.8 0 0 0 1.8 1.8h10.8a1.8 1.8 0 0 0 1.8-1.8V5.848c0-.417-.188-.81-.518-1.064ZM13.85 12.8a.45.45 0 0 1-.45.45H2.6a.45.45 0 0 1-.45-.45V7.982l3.828 3.19a3.173 3.173 0 0 0 4.046-.003l3.826-3.187V12.8Zm0-6.576-4.691 3.884c-.652.543-1.67.542-2.32 0L2.15 6.225l.003-.379c.654-.515 1.188-.927 4.582-3.392.086-.063.187-.141.299-.227.218-.17.704-.547.966-.659.262.112.749.489.968.659.111.086.212.164.302.23 3.388 2.46 3.925 2.874 4.58 3.392v.376Z"
                fill="#004C93"
            />
        </svg>
    );
}
