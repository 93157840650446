import classNames from "classnames";
import { ReactNode } from "react";

export default function Tooltip({ children, showTooltip, className }: { children: ReactNode; showTooltip?: boolean; className?: string }) {
    return (
        <div
            className={classNames(
                "absolute z-10 px-3 py-2 text-xs transition-opacity duration-30 rounded-lg shadow-sm",
                {
                    "opacity-1 visible": showTooltip,
                    "opacity-0 invisible": !showTooltip
                },
                className
            )}
        >
            {children}
        </div>
    );
}
