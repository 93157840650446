import { useI18n } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { useState } from "react";
import { Translation } from "../../../i18n";
import ReportForm from "./ReportForm";
import ReportList from "./ReportList";

enum SelectedTab {
    NEW,
    MY_REPORTS,
}

function MyReportsTab() {
    const { translation } = useI18n<Translation>();

    return (
        <div className="max-w-full max-h-full overflow-y-scroll">
            <h1 className="font-bold text-primaryDark">{translation.myReports}</h1>
            <div className="overflow-auto">
                <ReportList />
            </div>
        </div>
    );
}

function TabButton({
    isSelected,
    onClick,
    children,
    roundedClass,
}: {
    isSelected: boolean;
    onClick: () => void;
    children: React.ReactNode;
    roundedClass: string;
}) {
    return (
        <button
            className={classNames(
                "flex w-full items-center justify-center font-semibold gap-2 px-6 py-3 text-[18px]",
                roundedClass,
                isSelected ? "bg-white text-primaryDark" : "bg-orange text-white"
            )}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default function ReportManager() {
    const { translation } = useI18n<Translation>();
    const [selectedTab, setSelectedTab] = useState(SelectedTab.NEW);

    return (
        <div className="flex flex-col justify-center mx-auto bg-white rounded-4xl">
            <div className="flex w-full space-x-2">
                <TabButton isSelected={selectedTab === SelectedTab.NEW} onClick={() => setSelectedTab(SelectedTab.NEW)} roundedClass="rounded-tl-4xl">
                    {translation.new}
                </TabButton>
                <TabButton
                    isSelected={selectedTab === SelectedTab.MY_REPORTS}
                    onClick={() => setSelectedTab(SelectedTab.MY_REPORTS)}
                    roundedClass="rounded-tr-4xl"
                >
                    {translation.history}
                </TabButton>
            </div>
            <div className="p-8 w-[625px] h-[450px]">
                {selectedTab === SelectedTab.NEW && <ReportForm />}
                {selectedTab === SelectedTab.MY_REPORTS && <MyReportsTab />}
            </div>
        </div>
    );
}
