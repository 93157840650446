import { Button, InputSelectCheckbox, ListOption, MinusIcon } from "../../../design-system";
import { Managers } from "../../hooks";
import { Translation, useI18n } from "../../i18n";
import { Representative, UserInfo } from "../../model";
import { removeDepositStoreContributor, removeDepositStoreMember } from "../../services";

const canUserBeRemoved = (user: UserInfo, readonly: boolean | undefined): boolean =>
    !readonly && (!user._links?.removeUserManager || !!removeDepositStoreContributor(user));

const canRepresentativeBeRemoved = (representative: Representative, readonly: boolean | undefined): boolean =>
    !readonly && (!representative._links?.removeMemberManager || !!removeDepositStoreMember(representative));

const getUserLabel = (translation: Translation, user: UserInfo): string =>
    `${translation.userWalletRole(user.role)} | ${user.firstName} ${user.lastName} - ${user.email}`;

const formatRepresentativesList = (representatives: Representative[]): ListOption[] => {
    return representatives.map(representative => {
        return {
            id: representative.id,
            label: representative.name,
            type: "radio"
        };
    });
};

const formatUsersList = (users: UserInfo[], readonly?: boolean): ListOption[] => {
    return users.map(user => {
        return {
            id: user.id,
            label: `${user.firstName} ${user.lastName} - ${user.email}`,
            disabled: !canUserBeRemoved(user, readonly),
            type: "checkbox"
        };
    });
};

export default function ListSelect({ titleList, managers, readonly }: { titleList: string; managers: Managers; readonly?: boolean }): JSX.Element {
    const { translation } = useI18n<Translation>();
    const {
        userManagers,
        contributors,
        onUserManagerAdd,
        representativeManagers,
        representatives,
        onRepresentativeManagerAdd,
        onUserManagerRemove,
        onRepresentativeManagerRemove
    } = managers;

    const handleList = (options: ListOption[]) => {
        options
            .filter(option => userManagers.every(manager => manager.id !== option.id))
            .map(option => contributors?.find(contributor => contributor.id === option.id))
            .forEach(manager => {
                if (manager) {
                    onUserManagerAdd(manager);
                }
            });

        userManagers
            .filter(manager => options.every(option => manager.id !== option.id))
            .map(manager => manager.id)
            .forEach(onUserManagerRemove);

        options
            .filter(option => representativeManagers.every(manager => manager.id !== option.id))
            .map(option => representatives?.find(contributor => contributor.id === option.id))
            .forEach(manager => {
                if (manager) {
                    onRepresentativeManagerAdd(manager);
                }
            });

        representativeManagers
            .filter(manager => options.every(option => manager.id !== option.id))
            .map(manager => manager.id)
            .forEach(onRepresentativeManagerRemove);
    };

    const renderManagers = () => {
        return (
            <>
                {!userManagers.length && !representativeManagers.length && (
                    <li className="flex gap-3.5">
                        <span className="block text-sm text-label">{translation.noResult}</span>
                    </li>
                )}
                {!!userManagers.length && (
                    <ul>
                        {userManagers.map(manager => (
                            <li key={manager.id} className="flex items-center justify-between px-2.5 py-1.5 hover:bg-slate-100 border-b">
                                <span className="pr-2.5 text-label">{getUserLabel(translation, manager)}</span>
                                {canUserBeRemoved(manager, readonly) && (
                                    <Button
                                        variant="iconButton"
                                        onClick={() => onUserManagerRemove(manager.id)}
                                        icon={<MinusIcon className="fill-primary w-4 h-4" />}
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                )}
                {!!representativeManagers.length && (
                    <ul>
                        {representativeManagers.map(manager => (
                            <li key={manager.id} className="flex items-center justify-between px-2.5 py-1.5 hover:bg-slate-100 border-b">
                                <span className="pr-2.5 text-label">{manager.name}</span>
                                {canRepresentativeBeRemoved(manager, readonly) && (
                                    <Button
                                        variant="iconButton"
                                        onClick={() => onRepresentativeManagerRemove(manager.id)}
                                        icon={<MinusIcon className="fill-primary w-4 h-4" />}
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </>
        );
    };

    const formatListSelected = () => {
        const listFormatManagers = userManagers.map(manager => ({
            id: manager.id,
            label: getUserLabel(translation, manager),
            disabled: canUserBeRemoved(manager, readonly)
        }));

        const listFormatRepresentatives = representativeManagers.map(representative => ({
            id: representative.id,
            label: representative.name,
            disabled: canRepresentativeBeRemoved(representative, readonly)
        }));

        return [...listFormatManagers, ...listFormatRepresentatives];
    };

    return (
        <div className="w-full text-label">
            <div className="flex">
                <h3 className="text-sm font-bold">{titleList}</h3>
            </div>
            <div className="bg-white mb-5 mt-2.5">{renderManagers()}</div>
            {!readonly && (
                <InputSelectCheckbox
                    list={[
                        ...(contributors?.length
                            ? [
                                  {
                                      groupName: translation.contributors,
                                      list: formatUsersList(
                                          contributors.map(contributor => ({ ...contributor, groupName: translation.contributors })),
                                          readonly
                                      )
                                  }
                              ]
                            : []),
                        ...(representatives?.length
                            ? [
                                  {
                                      groupName: translation.representatives,
                                      list: formatRepresentativesList(
                                          representatives.map(representative => ({ ...representative, groupName: translation.representatives }))
                                      )
                                  }
                              ]
                            : [])
                    ]}
                    placeholder={translation.findOrSelect}
                    inputLabel={translation.findByContributors}
                    noResultLabel={translation.noResult}
                    handleList={handleList}
                    listFiltered={formatListSelected()}
                />
            )}
        </div>
    );
}
