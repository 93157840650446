import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";

import { getNodeText } from "../../helpers";
import { useHover } from "../../utils/hooks";
import { ChevronSmall, Tooltip } from "../index";

export default function Card({
    title,
    footer,
    subTitle,
    icon,
    children,
    topLeftContent,
    onClick,
    closable = true,
    open,
    tooltip,
    rightContent,
    small,
    isLoading
}: {
    title: ReactNode;
    footer?: ReactNode;
    subTitle?: ReactNode;
    icon?: ReactNode;
    children?: ReactNode;
    topLeftContent?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    closable?: boolean;
    open?: boolean;
    tooltip?: ReactNode;
    rightContent?: ReactNode;
    small?: boolean;
    isLoading?: boolean;
}) {
    const [openCard, setOpenCard] = useState<boolean>(!!open);
    const iconRef = useRef<HTMLDivElement>(null);
    const isHover = useHover(iconRef);

    useEffect(() => {
        setOpenCard(!!open);
    }, [open]);

    const paddingStyle = () => {
        return classNames({
            "pl-2.5 pr-1": !small,
            "px-1": small,
            "py-1": (subTitle && !children) || small || (!subTitle && !small) || (children && !small),
            "py-1.5": (!subTitle && !small) || (children && !small)
        });
    };

    return (
        <>
            {isLoading && (
                <div
                    className={classNames("animate-pulse flex items-center w-full space-x-2.5 bg-white border border-gray-200 rounded shadow order", {
                        "h-[34px] px-1": small,
                        "h-[50px] px-2.5": !small
                    })}
                >
                    <div
                        className={classNames("rounded bg-blue-50", {
                            "w-6 h-6": small,
                            "w-8 h-8": !small
                        })}
                    ></div>
                    <div className="w-full space-y-1">
                        <div className="w-9/12 h-3.5 bg-gray-200"></div>
                        {!small && <div className="w-7/12 h-3.5 bg-gray-100"></div>}
                    </div>
                </div>
            )}
            {!isLoading && (
                <div
                    onClick={e => (children && closable ? setOpenCard(prev => !prev) : onClick?.(e))}
                    className={classNames("relative flex flex-col p-0 w-full bg-white border border-gray-200 rounded shadow hover:bg-zinc-50", {
                        "cursor-pointer": (onClick || children) && closable
                    })}
                >
                    {topLeftContent && <span className="absolute top-[-1px] left-0.5 text-xxs text-color-disabled mt-[-1px]">{topLeftContent}</span>}
                    <header className={classNames("flex justify-between w-full", paddingStyle())}>
                        <div className="flex flex-row items-center min-w-0">
                            {icon && (
                                <div ref={iconRef}>
                                    <span
                                        className={classNames("flex relative items-center justify-center bg-blue-100 rounded mr-2.5", {
                                            "h-6 w-6 p-1": small,
                                            "h-8 w-8 p-2": !small
                                        })}
                                    >
                                        {icon}
                                        {tooltip && <Tooltip children={tooltip} className="text-white top-10 bg-label" showTooltip={isHover} />}
                                    </span>
                                </div>
                            )}
                            <div className="flex flex-col min-w-0 text-sm">
                                <p className="font-normal truncate text-label" title={getNodeText(title)}>
                                    {title}
                                </p>
                                {subTitle && (
                                    <p className="font-light truncate text-color-disabled" title={getNodeText(subTitle)}>
                                        {subTitle}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end gap-2 text-xs text-right">
                            <div className="flex flex-col items-end justify-center space-y-1">{rightContent}</div>
                            {children && closable && (
                                <ChevronSmall
                                    className={classNames("w-3 fill-primary ease-in-out duration-200 mr-1", { "rotate-0": openCard, "rotate-180": !openCard })}
                                />
                            )}
                        </div>
                    </header>
                    {children && openCard && children}
                    {footer && openCard && footer}
                </div>
            )}
        </>
    );
}
