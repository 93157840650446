export default function Contract({ className }: { className?: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg " width="100%" height="100%" viewBox="0 0 19 25" className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 0H0V25H19V0ZM17 3H2V5H17V3ZM2 7H17V9H2V7ZM17 11H2V13H17V11ZM11 20H17V22H11V20ZM4.5 22C5.88071 22 7 20.8807 7 19.5C7 18.1193 5.88071 17 4.5 17C3.11929 17 2 18.1193 2 19.5C2 20.8807 3.11929 22 4.5 22Z"
            />
        </svg>
    );
}
