import { ReactNode } from "react";
import { Footer, Header } from "../components";

export default function PublicPageLayout({ children }: { children: ReactNode }) {
    return (
        <div className="flex flex-col min-h-screen bg-primary">
            <Header />
            <div className="flex flex-grow items-center justify-center">{children}</div>
            <Footer />
        </div>
    );
}
