import { REPORT_TITLE_REGEX } from "@vaultinum/vaultinum-legal-proof-api";
import { FieldValues } from "react-hook-form";
import * as yup from "yup";
import { Translation } from "../i18n";

export const validationSchema = (translation: Translation): yup.ObjectSchema<FieldValues> =>
    yup.object({
        reportTitle: yup
            .string()
            .required(translation.reportTitleRequired)
            .matches(REPORT_TITLE_REGEX, translation.reportTitleNoSpecialChars)
            .test("not-only-spaces", translation.reportTitleRequired, (value) => value?.trim() !== ""),
        requester: yup
            .object({
                label: yup.string().required(translation.requesterRequired),
                value: yup
                    .string()
                    .required(translation.requesterRequired)
                    .matches(REPORT_TITLE_REGEX, translation.reportRequesterNoSpecialChars)
                    .test("not-only-spaces", translation.requesterRequired, (value) => value?.trim() !== ""),
            })
            .required(translation.requesterRequired),
        reportType: yup.string().required(translation.reportTypeRequired),
    });
