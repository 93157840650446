import { useEffect, useRef } from "react";

export default function useOutsideClick<T extends HTMLElement>(callback: () => void) {
    const ref = useRef<T>(null);

    useEffect(() => {
        const handleClick = ({ target }: MouseEvent): void => {
            if (ref.current && !ref.current.contains(target as Node)) {
                callback();
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [ref]);

    return ref;
}
