import { useI18n } from "@vaultinum/app-sdk";
import { Translation } from "../../../../i18n";

export default function SessionDuration({ sessionDuration }: { sessionDuration: string }) {
    const { translation } = useI18n<Translation>();
    return (
        <div className="flex justify-center items-center space-x-1 text-xs">
            <span className="text-xs">{translation.observationInProgress}</span>
            <span className="text-xs font-bold">{sessionDuration}</span>
        </div>
    );
}
