/* eslint-disable */
import { StoryFn } from "@storybook/react";

export const templateForComponent =
    <P,>(Component: (props: P) => any) =>
    (props: P): StoryFn<P> => {
        const template = (args: JSX.IntrinsicAttributes & P) => <Component {...args} />;
        const story: any = template.bind({});
        story.args = props;
        return story;
    };
/* eslint-enable */
