import { Environment } from "../model";

export function getEnvironment(): Environment {
    return (process.env.REACT_APP_ENVIRONMENT as Environment) || Environment.PRODUCTION;
}

export function isDevelopment() {
    return getEnvironment() === Environment.DEVELOPMENT;
}

export function isStaging() {
    return getEnvironment() === Environment.STAGING;
}

export function isProduction() {
    return getEnvironment() === Environment.PRODUCTION;
}
