export default function LeftArrow({ disabled }: { disabled: boolean }) {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_26_37)">
                <path
                    d="M5.85763 8.25H14.9659V9.75H5.85763L9.87148 13.773L8.81339 14.8335L2.99316 9L8.81339 3.1665L9.87148 4.227L5.85763 8.25Z"
                    fill={disabled ? "#C1C1C1" : "#717171"}
                />
            </g>
            <defs>
                <clipPath id="clip0_26_37">
                    <rect width="17.959" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
