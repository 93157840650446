import { Requester, REQUESTERS_COLLECTION, USERS_COLLECTION } from "@vaultinum/vaultinum-legal-proof-api";
import { addDoc, collection, DocumentData, DocumentSnapshot, getFirestore, onSnapshot, query } from "firebase/firestore";

function convertDocToRequesters(document: DocumentSnapshot<DocumentData>): Requester {
    return {
        ...(document.data() as Omit<Requester, "id">),
        id: document.id,
    };
}

export const getRequesters = (userUid: string, onUpdate: (requesters: Requester[]) => void) =>
    onSnapshot(query(collection(getFirestore(), USERS_COLLECTION, userUid, REQUESTERS_COLLECTION)), (querySnapshot) => {
        onUpdate(querySnapshot.docs.map(convertDocToRequesters));
    }, () => onUpdate([]));

export async function createRequester(userUid: string, requester: Omit<Requester, "id">): Promise<void> {
    await addDoc(collection(getFirestore(), USERS_COLLECTION, userUid, REQUESTERS_COLLECTION), requester);
}
