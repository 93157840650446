import classNames from "classnames";
import { ReactNode } from "react";

export default function BoxContainer({
    children,
    icon,
    title,
    rightTitle,
    topRightContent
}: {
    children: ReactNode;
    icon?: ReactNode;
    title?: string;
    rightTitle?: ReactNode;
    topRightContent?: ReactNode;
}): JSX.Element {
    return (
        <div className="border-2 p-5 relative rounded-lg w-full h-full">
            {topRightContent && <div className="absolute top-1 right-1">{topRightContent}</div>}
            {title && (
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1.5">
                        <div className="w-4 h-4">{icon}</div>
                        <p className="font-semibold text-sm text-primary uppercase">{title}</p>
                    </div>
                    {rightTitle}
                </div>
            )}
            <div
                className={classNames({
                    "pt-5": title ?? rightTitle
                })}
            >
                {children}
            </div>
        </div>
    );
}
