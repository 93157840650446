import { Toast, useI18n, useToast } from "@vaultinum/app-sdk";
import { InteractionEvent, Report } from "@vaultinum/vaultinum-legal-proof-api";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../auth";
import { useSession } from "../../../context";
import { Translation } from "../../../i18n";
import { downloadReport } from "../../../services";
import { DownloadButtons, getDownloadReportFooterStatus, getDownloadReportStatus, getDownloadReportSubStatus } from "../../../tool";
import SuspensionPoints from "./components/SuspensionPoints";

type DownloadingStatusKey =
    | Report.TaskType.SSL
    | Report.TaskType.WHOIS
    | Report.TaskType.DNS
    | Report.TaskType.OS
    | Report.TaskType.IP_DETAILS
    | Report.TaskType.GENERATE_REPORT
    | Report.TaskType.STORE_METADATA
    | Report.TaskType.SEAL_REPORT
    | Report.TaskType.CREATE_ZIP
    | Report.TaskType.UPLOAD_REPORT;

export default function Downloading() {
    const { user } = useAuth();
    const { socket, sessionDuration } = useSession();
    const { error } = useToast();
    const { translation } = useI18n<Translation>();

    const [hasError, setHasError] = useState<boolean>(false);
    const [reportId, setReportId] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
    const [progressText, setProgressText] = useState("");
    const [progressPercentage, setProgressPercentage] = useState(0);

    const handleDownloadReport = useCallback(
        async ({ reportId, fileName }: { reportId: string; fileName: string }) => {
            try {
                await downloadReport(user, reportId, fileName, error, translation);
                setReportId(reportId);
                setFileName(fileName);
                setIsDownloaded(true);
            } catch (err) {
                setHasError(true);
                error(translation.errorDuringDownload);
            } finally {
                socket?.disconnect();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error, user, socket]
    );

    const handleDownloadReportFailure = useCallback(async () => {
        socket?.disconnect();
        setHasError(true);
        error(translation.errorDuringReportGeneration);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, socket]);

    const handleProgressStatus = useCallback(
        (data: { key: DownloadingStatusKey; percentage: number }) => {
            setProgressText(translation.download.status[data.key]);
            setProgressPercentage(data.percentage);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (!socket.active) {
            return;
        }
        socket.on(InteractionEvent.TASK_PROGRESS, handleProgressStatus);
        socket.on(InteractionEvent.REPORT_BUILD_FINISHED, handleDownloadReport);
        return () => {
            socket.off(InteractionEvent.TASK_PROGRESS, handleProgressStatus);
            socket.off(InteractionEvent.REPORT_BUILD_FINISHED, handleDownloadReport);
        };
    }, [handleDownloadReport, handleDownloadReportFailure, handleProgressStatus, socket]);

    return (
        <>
            <Toast position="top-center" />
            <div className="flex flex-col items-center justify-center h-full">
                <div className="flex flex-col items-center space-y-4">
                    <div className="flex flex-col items-center space-y-5 pb-4">
                        <h1 className="text-warning text-5xl font-semibold flex items-center">
                            {getDownloadReportStatus(isDownloaded, hasError, translation)}
                            {!isDownloaded && !hasError && <SuspensionPoints />}
                        </h1>
                        <h2 className="text-white text-5xl font-normal">{getDownloadReportSubStatus(isDownloaded, hasError, translation)}</h2>
                        <div className="flex flex-col space-y-1">
                            {!isDownloaded && (
                                <div className="flex space-x-2 text-white pt-4 justify-center">
                                    <p className="text-sm">{translation.observationMadeIn}</p>
                                    <p className="font-semibold">{sessionDuration}</p>
                                </div>
                            )}
                            <p className="text-sm text-center text-white max-w-xl">
                                {getDownloadReportFooterStatus(isDownloaded, hasError, translation)}
                            </p>
                        </div>
                    </div>
                    {!isDownloaded && (
                        <div className="flex flex-col w-full space-y-1">
                            <div className="w-full bg-light-grey rounded-full h-4 relative">
                                <div
                                    className="from-warning to-input-border-color bg-gradient-to-tr h-4 rounded-full"
                                    style={{ width: `${progressPercentage}%` }}
                                >
                                    <div className="absolute inset-0 flex items-center justify-center text-xs text-primaryDark">
                                        {progressPercentage}%
                                    </div>
                                </div>
                            </div>
                            <span className="text-xs text-white text-center">{progressText}</span>
                        </div>
                    )}

                    <div className="flex flex-col items-center space-y-6">
                        <DownloadButtons
                            isDownloaded={isDownloaded}
                            hasError={hasError}
                            handleDownloadReport={() => handleDownloadReport({ reportId, fileName })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
