import classNames from "classnames";
import { Link } from "react-router-dom";

import { ExternalLinkIcon } from "../Icons";

export default function CustomLink({
    label,
    path,
    disabled = false,
    target = "_self",
    small,
    className,
    iconColor = "fill-primary"
}: {
    label: string;
    path?: string;
    disabled?: boolean;
    target?: "_blank" | "_self";
    small?: boolean;
    className?: string;
    iconColor?: "fill-primary" | "fill-white";
}) {
    return (
        <Link
            className={classNames("underline flex items-center", className, {
                "text-color-disabled cursor-not-allowed": disabled,
                "text-primary hover:font-bold cursor-pointer": !disabled,
                "text-sm": small
            })}
            to={!disabled && path ? path : "#"}
            target={target}
            aria-disabled={disabled}
        >
            {label}
            {target === "_blank" && (
                <ExternalLinkIcon
                    className={classNames("ml-1.5 mt-1", {
                        "fill-color-disabled cursor-not-allowed": disabled,
                        [`${iconColor} hover:font-bold`]: !disabled
                    })}
                />
            )}
        </Link>
    );
}
