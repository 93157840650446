import { useI18n } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import { Button } from "../design-system";
import { Translation } from "../i18n";
import { CompanyLogo } from "./Icons";


export default function Header() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { translation } = useI18n<Translation>();

    const handleLogout = async () => {
        try {
            const auth = getAuth();
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error(error);
        }
    };

    function gotToAppWebsite() {
        window.open("https://evidency.io/a-propos-evidency/", "_blank", "noopener");
    }

    return (
        <header className={classNames("py-6 w-full flex justify-between items-center px-24 border-b-2 border-primary bg-primary text-white text-lg")}>
            <CompanyLogo className="h-[60px]" />
            <div className="flex justify-end items-center space-x-8 h-full">
                {!!user && <>
                    <span>{user.email}</span>
                    <span>|</span>
                </>}
                <span className="hover:underline hover:cursor-pointer" onClick={gotToAppWebsite}>{translation.about}</span>
                {!!user && (<Button className="h-full" label={translation.logoff} onClick={handleLogout} />)}
            </div>
        </header>
    );
}
