const RoundC = ({ className }: { className?: string }) => {
    return (
        <svg viewBox="0 0 15 15" className={className}>
            <circle cx="7.5" cy="7.5" r="7.5" />
            <path
                d="M5.30893 7.392C5.30893 7.904 5.43023 8.348 5.67283 8.724C5.91544 9.092 6.23334 9.372 6.62652 9.564C7.02808 9.756 7.46727 9.852 7.94412 9.852C8.3624 9.852 8.73049 9.8 9.04839 9.696C10.6327 9.17769 11.1556 11.092 9.41229 11.556C8.99401 11.708 8.46279 11.784 7.81863 11.784C7.11591 11.784 6.46757 11.68 5.87361 11.472C5.27965 11.264 4.76934 10.964 4.34269 10.572C3.91604 10.18 3.5856 9.716 3.35136 9.18C3.11712 8.644 3 8.048 3 7.392C3 6.736 3.11712 6.14 3.35136 5.604C3.5856 5.068 3.91604 4.604 4.34269 4.212C4.76934 3.82 5.27965 3.52 5.87361 3.312C6.46757 3.104 7.11591 3 7.81863 3C8.46279 3 8.99401 3.076 9.41229 3.228C10.8774 3.76042 10.1099 5.59204 9.06415 5.09204C8.74625 4.98804 8.3624 4.932 7.94412 4.932C7.46727 4.932 7.02808 5.028 6.62652 5.22C6.23334 5.412 5.91544 5.692 5.67283 6.06C5.43023 6.428 5.30893 6.872 5.30893 7.392Z"
                fill="white"
            />
        </svg>
    );
};

const RoundD = ({ className }: { className?: string }) => {
    return (
        <svg viewBox="0 0 15 15" className={className}>
            <circle cx="7.5" cy="7.5" r="7.5" />
            <path
                d="M4 4C4 3.44772 4.44772 3 5 3H6.088V11.4H5C4.44771 11.4 4 10.9523 4 10.4V4ZM7 11.4H5.344V9.6H6.928C7.248 9.6 7.556 9.56 7.852 9.48C8.156 9.4 8.42 9.268 8.644 9.084C8.876 8.9 9.06 8.656 9.196 8.352C9.332 8.04 9.4 7.656 9.4 7.2C9.4 6.744 9.332 6.364 9.196 6.06C9.06 5.748 8.876 5.5 8.644 5.316C8.42 5.132 8.156 5 7.852 4.92C7.556 4.84 7.248 4.8 6.928 4.8H5.344V3H7C7.92 3 8.716 3.172 9.388 3.516C10.06 3.86 10.58 4.344 10.948 4.968C11.316 5.592 11.5 6.336 11.5 7.2C11.5 8.056 11.316 8.8 10.948 9.432C10.58 10.056 10.06 10.54 9.388 10.884C8.716 11.228 7.92 11.4 7 11.4Z"
                fill="white"
            />
        </svg>
    );
};

const RoundR = ({ className }: { className?: string }) => {
    return (
        <svg viewBox="0 0 15 15" className={className}>
            <circle cx="7.5" cy="7.5" r="7.5" />
            <path
                d="M6.6489 7.596H8.99683L10.4786 9.85068C10.9157 10.5156 10.4387 11.3999 9.64297 11.3999H9.55474C9.20796 11.3999 8.88593 11.2202 8.70379 10.9251L6.6489 7.596ZM4 4C4 3.44772 4.44772 3 5 3H6.028V10.4C6.028 10.9523 5.58028 11.4 5.028 11.4H5C4.44772 11.4 4 10.9523 4 10.4V4ZM5.272 4.716V3H7.06C7.78 3 8.38 3.116 8.86 3.348C9.34 3.58 9.704 3.904 9.952 4.32C10.2 4.728 10.324 5.204 10.324 5.748C10.324 6.284 10.2 6.76 9.952 7.176C9.704 7.584 9.34 7.904 8.86 8.136C8.38 8.368 7.78 8.484 7.06 8.484H5.272V6.912H6.916C7.188 6.912 7.42 6.872 7.612 6.792C7.812 6.704 7.964 6.58 8.068 6.42C8.172 6.252 8.224 6.052 8.224 5.82C8.224 5.588 8.172 5.392 8.068 5.232C7.964 5.064 7.812 4.936 7.612 4.848C7.42 4.76 7.188 4.716 6.916 4.716H5.272Z"
                fill="white"
            />
        </svg>
    );
};

const RoundS = ({ className }: { className?: string }) => {
    return (
        <svg viewBox="0 0 15 15" className={className}>
            <circle cx="7.5" cy="7.5" r="7.5" />
            <path
                d="M4.62512 9.2097C5.04578 8.90668 5.62723 9.0521 5.98 9.432C6.196 9.656 6.428 9.828 6.676 9.948C6.932 10.068 7.2 10.128 7.48 10.128C7.776 10.128 8.02 10.056 8.212 9.912C8.404 9.76 8.5 9.556 8.5 9.3C8.5 9.084 8.444 8.912 8.332 8.784C8.22 8.648 8.04 8.52 7.792 8.4C7.544 8.28 7.216 8.144 6.808 7.992C6.608 7.92 6.376 7.82 6.112 7.692C5.856 7.564 5.612 7.4 5.38 7.2C5.148 6.992 4.956 6.744 4.804 6.456C4.652 6.16 4.576 5.804 4.576 5.388C4.576 4.892 4.704 4.464 4.96 4.104C5.224 3.744 5.576 3.472 6.016 3.288C6.464 3.096 6.96 3 7.504 3C8.064 3 8.548 3.092 8.956 3.276C9.372 3.46 9.716 3.692 9.988 3.972C10.0305 4.01577 10.0715 4.05964 10.1109 4.10361C10.4395 4.47036 10.2734 5.01296 9.84345 5.25296L9.64712 5.36254C9.32216 5.54391 8.92062 5.42096 8.644 5.172C8.492 5.02 8.32 4.904 8.128 4.824C7.944 4.736 7.744 4.692 7.528 4.692C7.24 4.692 7.02 4.756 6.868 4.884C6.716 5.004 6.64 5.156 6.64 5.34C6.64 5.532 6.712 5.7 6.856 5.844C7.008 5.988 7.22 6.12 7.492 6.24C7.772 6.36 8.108 6.488 8.5 6.624C8.796 6.736 9.072 6.868 9.328 7.02C9.584 7.164 9.808 7.34 10 7.548C10.2 7.756 10.356 7.996 10.468 8.268C10.58 8.54 10.636 8.852 10.636 9.204C10.636 9.628 10.548 10.008 10.372 10.344C10.204 10.672 9.972 10.948 9.676 11.172C9.388 11.396 9.056 11.564 8.68 11.676C8.312 11.796 7.932 11.856 7.54 11.856C6.996 11.856 6.488 11.76 6.016 11.568C5.552 11.368 5.148 11.1 4.804 10.764C4.64995 10.6135 4.51115 10.4567 4.38758 10.2934C4.12788 9.95014 4.25429 9.47682 4.60352 9.22526L4.62512 9.2097Z"
                fill="white"
            />
        </svg>
    );
};

const RoundX = ({ className }: { className?: string }) => {
    return (
        <svg viewBox="0 0 15 15" className={className}>
            <circle cx="7.5" cy="7.5" r="7.5" />
            <path
                d="M8.59762 4.11702C8.77356 3.79812 9.10898 3.6001 9.47319 3.6001H9.5556C10.3475 3.6001 10.8252 4.47679 10.3959 5.14222L8.75081 7.6921L10.5577 10.4524C10.9931 11.1175 10.5159 12.0001 9.72102 12.0001H9.65442C9.28955 12.0001 8.95364 11.8014 8.77797 11.4816L7.52681 9.2041L6.23009 11.493C6.05255 11.8064 5.72019 12.0001 5.36001 12.0001H5.30622C4.50694 12.0001 4.03067 11.1088 4.47488 10.4443L6.31481 7.6921L4.70158 5.13344C4.28173 4.46754 4.76028 3.6001 5.54748 3.6001H5.64212C6.0118 3.6001 6.35132 3.80405 6.52495 4.13041L7.53881 6.0361L8.59762 4.11702Z"
                fill="white"
            />
        </svg>
    );
};

const icons = {
    C: RoundC,
    D: RoundD,
    R: RoundR,
    S: RoundS,
    X: RoundX
};

export default function RoundLetter({ letter, className }: { letter: keyof typeof icons; className?: string }) {
    const Letter = icons[letter];

    return <Letter className={className} />;
}
