import * as jose from "jose";
import { createContext, ReactNode, useEffect, useMemo, useState } from "react";

import { User } from "../model";
import {
    authenticateWithInterdeposit as authenticateWithInterdepositFromService,
    authenticateWithMicrosoft as authenticateWithMicrosoftFromService,
    clearToken,
    logout as disconnect,
    getTokenFromStorage,
    refreshToken,
    storeToken,
    Token
} from "../services";

interface Context {
    authenticateWithInterdeposit: (login: string, password: string) => Promise<void>;
    authenticateWithMicrosoft: () => Promise<void>;
    logout: (onSuccess?: () => void) => Promise<void>;
    isUserAdmin: boolean;
    isUserManager: boolean;
    isLoading: boolean;
    user?: User;
}

class TokenManager {
    private save: (token?: Token) => void;

    constructor(save: (token?: Token) => void) {
        this.save = save;
    }

    getToken(): Token | undefined {
        return getTokenFromStorage();
    }

    store(token: Token): void {
        const claims = jose.decodeJwt(token.accessToken);

        const tokenToStore = {
            ...token,
            user: {
                ...token.user,
                sub: claims.sub as string,
                memberId: claims.memberId as string,
                roles: claims.roles as User.Role[]
            }
        };

        storeToken(tokenToStore);

        this.save(tokenToStore);
    }

    delete(): void {
        this.save(undefined);

        clearToken();
    }
}

export const UserContext = createContext({} as Context);

export function UserContextProvider({ children }: { children: ReactNode }) {
    const [token, setToken] = useState<Token>();
    const [isLoading, setIsLoading] = useState(true);

    const tokenManager = new TokenManager(setToken);

    useEffect(() => {
        const storedToken = tokenManager.getToken();

        void (async () => {
            if (storedToken) {
                try {
                    tokenManager.store(await refreshToken(storedToken));
                } catch (e) {
                    tokenManager.delete();
                }
            }

            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (!token) {
            return () => {};
        }

        const timeoutID = setTimeout(
            async () => {
                tokenManager.store(await refreshToken(token));
            },
            (token.expiresIn - 5) * 1000
        );

        return () => clearTimeout(timeoutID);
    }, [token]);

    const authenticateWithInterdeposit = async (login: string, password: string) => {
        tokenManager.store(await authenticateWithInterdepositFromService(login, password));
    };

    const authenticateWithMicrosoft = async () => {
        tokenManager.store(await authenticateWithMicrosoftFromService());
    };

    const logout = async (onSuccess: () => void = () => {}) => {
        await disconnect(onSuccess);

        tokenManager.delete();
    };

    const getUserRoles = (): User.Role[] => {
        return token?.user.roles ?? [];
    };

    const isUserAdmin = getUserRoles().includes(User.Role.ADMIN);

    const isUserManager = getUserRoles().includes(User.Role.PUBLISH_USER);

    const exposed = useMemo(
        () => ({ authenticateWithInterdeposit, authenticateWithMicrosoft, logout, isLoading, user: token?.user, isUserManager, isUserAdmin }),
        [isLoading, token]
    );

    return <UserContext.Provider value={exposed}>{children}</UserContext.Provider>;
}
