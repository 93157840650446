import classNames from "classnames";
import { ReactNode, useState } from "react";
import { useOutsideClick } from "../../utils/hooks";
import { ChevronSmall } from "../Icons";
import { Avatar, CompanyLogo } from "../index";

type User = {
    firstName: string;
    lastName: string;
    roles: string[];
    avatar?: string;
};

type Member = {
    name: string;
};

const getUserAvatar = (user: User) => {
    return (
        <Avatar
            icon={user.avatar ? <img src={`data:image/png;base64, ${user.avatar}`} alt={user.firstName} /> : user.firstName.charAt(0) + user.lastName.charAt(0)}
        />
    );
};

export default function Header({
    openMenu,
    headerPopUpContent,
    bottomRightContent,
    centerContent,
    env,
    onClick,
    user,
    member
}: {
    openMenu: boolean;
    headerPopUpContent: ReactNode;
    bottomRightContent?: ReactNode;
    centerContent?: ReactNode;
    env: "development" | "production" | "staging";
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    user?: User;
    member?: Member;
}) {
    const [openHeaderContent, setOpenHeaderContent] = useState<boolean>(false);

    const ref = useOutsideClick<HTMLButtonElement>(() => setOpenHeaderContent(false));

    return (
        <header
            className={classNames("fixed z-50 h-16 w-full bg-white flex justify-between pr-6 py-2 border-b-2", {
                "border-b-success": env === "development",
                "border-b-primary": env === "production",
                "border-b-warning": env === "staging"
            })}
        >
            <div className="z-50 flex items-center space-x-4 pl-[2px]">
                <button
                    onClick={onClick}
                    className={classNames("ml-[1.2rem] mr-5 transition-width duration-200 ", {
                        "w-60 overflow-hidden": openMenu,
                        "w-7": !openMenu
                    })}
                >
                    <CompanyLogo small={!openMenu} className="w-auto h-8" />
                </button>
                {centerContent}
            </div>
            <button onClick={() => setOpenHeaderContent(prev => !prev)} className="z-50 flex items-center space-x-2 " ref={ref}>
                <div className="flex flex-col items-end">
                    {user && (
                        <p className="text-right">
                            <span className="font-bold">
                                {user.firstName} {user.lastName}
                            </span>
                            <span className="text-gray-600"> ({user?.roles.join(", ")})</span>
                        </p>
                    )}
                    {member?.name && <p className="text-gray-600 text-right">{member?.name}</p>}
                </div>
                <div className="flex space-x-2 cursor-pointer">
                    {user && getUserAvatar(user)}
                    <div
                        className={classNames("flex items-center ease-in-out duration-200", {
                            "rotate-0": openHeaderContent,
                            "rotate-180": !openHeaderContent
                        })}
                    >
                        <ChevronSmall className="fill-primary h-2.5 w-2.5" />
                    </div>
                </div>
            </button>
            <div
                className={classNames("flex items-start flex-col p-4 absolute right-0 w-72 bg-white mr-1 overflow-hidden shadow rounded z-10", {
                    "h-auto top-24 opacity-100 transition-opacity ease-out duration-200": openHeaderContent,
                    "h-0 pt-0 -top-96": !openHeaderContent
                })}
            >
                {headerPopUpContent}
            </div>
            <div className="absolute right-0 mr-1 top-16">{bottomRightContent}</div>
        </header>
    );
}
