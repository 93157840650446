import classNames from "classnames";

export function getSelectStyles() {
    return {
        menu: () => "bg-white border rounded-md overflow-hidden",
        option: (state: { isSelected: boolean }) =>
            classNames("p-2 cursor-pointer", {
                "bg-neutral-light": state.isSelected,
                "hover:bg-primary-light": !state.isSelected,
            }),
        control: (state: { isFocused: boolean }) =>
            classNames("border border-input-border-color rounded-lg px-2 cursor-pointer placeholder:text-neutral-light", {
                "border-primary shadow-none": state.isFocused,
            }),
        valueContainer: () => "gap-1",
        clearIndicator: () => "cursor-pointer",
        menuPortal: () => "z-50",
        placeholder: () => "text-neutral-primary",
        indicatorSeparator: () => "hidden",
    };
}
