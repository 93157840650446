import classNames from "classnames";

import { useToast } from "../../utils/hooks";

export default function CopyableText({ value }: { value: string }): JSX.Element {
    const isCopyable = !!value && !!value.length && value !== "-";

    const notify = useToast();
    const onClick = async (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        await navigator.clipboard.writeText(value);
        notify.copy(value.toString());
    };

    return (
        <span
            className={classNames({
                "cursor-copy": isCopyable
            })}
            onClick={e => isCopyable && onClick?.(e)}
        >
            {value}
        </span>
    );
}
