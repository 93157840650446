import { Chip } from "../../../design-system";
import { SealStatus } from "../../model";

const COLORS: { [key in SealStatus]: "success" | "neutral" | "warning" | "primary" } = {
    [SealStatus.OPEN]: "warning",
    [SealStatus.IN_PROGRESS]: "primary",
    [SealStatus.SEALED]: "success",
    [SealStatus.ARCHIVED]: "success",
    [SealStatus.ERROR]: "primary",
    [SealStatus.STANDBY]: "warning"
};

export default function DepositStatus({
    label,
    status,
    size = "medium",
    showToolTip = true
}: {
    label: string;
    status: SealStatus;
    size?: "small" | "medium";
    showToolTip?: boolean;
}): JSX.Element {
    return <Chip label={label} type={COLORS[status]} size={size} tooltipContent={status} showToolTip={showToolTip} />;
}
