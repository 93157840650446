export default function EditIcon({ className }: { className?: string }) {
    return (
        <svg width="100%" height="100%" className={className} viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5960_1297)">
                <path d="M13.355 0.970002C13.06 0.674987 12.59 0.674987 12.295 0.970002L6.60002 6.66498C6.51003 6.75 6.45003 6.85999 6.41002 6.97998L5.48003 10.085C5.40001 10.35 5.47502 10.635 5.67003 10.83C5.81001 10.975 6.00502 11.05 6.20003 11.05C6.27 11.05 6.34502 11.04 6.41502 11.02L9.52 10.09C9.64003 10.05 9.75001 9.98999 9.835 9.89999L15.53 4.20499C15.67 4.065 15.75 3.875 15.75 3.67499C15.75 3.47501 15.67 3.28501 15.53 3.14499L13.355 0.970002Z" />
                <path d="M15 7.75C14.5857 7.75 14.25 8.08594 14.25 8.5V13.5C14.25 14.1895 13.6892 14.75 13 14.75H3C2.31079 14.75 1.75 14.1895 1.75 13.5V3.5C1.75 2.81054 2.31079 2.25 3 2.25H8C8.4143 2.25 8.75 1.91406 8.75 1.5C8.75 1.08594 8.4143 0.75 8 0.75H3C1.48364 0.75 0.25 1.98389 0.25 3.5V13.5C0.25 15.0161 1.48364 16.25 3 16.25H13C14.5164 16.25 15.75 15.0161 15.75 13.5V8.5C15.75 8.08594 15.4143 7.75 15 7.75Z" />
            </g>
            <defs>
                <clipPath id="clip0_5960_1297">
                    <rect width="16" height="16" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}
