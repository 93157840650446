import { useContext } from "react";
import { getI18nContext, I18nContextInterface } from ".";
import { Translation } from "./Translation";

export function useI18n<T extends Translation>() {
    const context: I18nContextInterface<T> = useContext(getI18nContext());

    if (context.translation === undefined) {
        throw new Error('I18n context has not been initialized. Consider using <I18n locale="ll">...</I18n> before hook useI18n.');
    }

    return context;
}
