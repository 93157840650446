export default function LetterClose({ className }: { className?: string }) {
    return (
        <svg viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M.8 2.4A1.8 1.8 0 0 1 2.6.6h10.8a1.8 1.8 0 0 1 1.8 1.8v7.2c0 .993-.807 1.8-1.8 1.8H2.6A1.8 1.8 0 0 1 .8 9.6V2.4Zm1.35 0v.622l4.852 3.982a1.57 1.57 0 0 0 1.996 0l4.852-3.982v-.647a.469.469 0 0 0-.45-.45H2.6a.468.468 0 0 0-.45.45V2.4Zm0 2.368V9.6c0 .247.201.45.45.45h10.8c.248 0 .45-.203.45-.45V4.768l-3.994 3.28a2.96 2.96 0 0 1-3.738 0L2.15 4.767Z"
                fill="#004C93"
            />
        </svg>
    );
}
