export default function AppLogo({ className }: { className?: string }) {
    return (
        <svg width="78" height="16" viewBox="0 0 78 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M39.8302 2.66975L40.1005 3.54545H40.8631L39.8012 0.221537H38.8166L37.7644 3.54545H38.498L38.749 2.66975H39.8302ZM39.0676 1.39856C39.1062 1.28557 39.1352 1.14432 39.1641 1.00308C39.1834 0.908918 39.2027 0.824173 39.2317 0.739428L39.2413 0.71118H39.3089L39.3282 0.739428L39.3572 0.843006C39.4055 1.02191 39.4537 1.22907 39.5116 1.39856L39.7433 2.1801H38.8456L39.0676 1.39856Z" fill="#004C8E" />
            <path d="M41.201 1.92587C41.1913 2.41551 41.3651 2.84865 41.6837 3.13114C42.0022 3.43246 42.4656 3.58312 43.0834 3.58312C43.4985 3.58312 43.9329 3.48896 44.2129 3.40421V1.68105H42.9869V2.19894H43.5275V2.98048L43.4985 2.9899C43.4309 3.02756 43.2765 3.0464 43.0834 3.0464C42.3787 3.0464 41.9347 2.59442 41.9347 1.8882C41.9347 1.18199 42.398 0.739428 43.1413 0.739428C43.5275 0.739428 43.7688 0.80534 43.9329 0.871254L44.0777 0.334532C43.904 0.259203 43.5758 0.183873 43.151 0.183873C41.9733 0.183873 41.201 0.871255 41.201 1.92587Z" fill="#004C8E" />
            <path d="M46.9931 2.10478V1.56805H45.6899V0.767675H47.0703V0.221537H44.9755V3.54545H47.1379V2.99931H45.6899V2.10478H46.9931Z" fill="#004C8E" />
            <path d="M48.5376 1.05016C48.75 1.53039 49.0106 2.01061 49.1458 2.24602L49.8891 3.53604H50.6517V0.221537H50.005V1.17257C50.005 1.74696 50.0243 2.18011 50.0725 2.585L50.0822 2.63208H49.9856L49.976 2.60383C49.8215 2.21777 49.6092 1.79404 49.4064 1.44564L48.6824 0.221537H47.8233V3.54545H48.4701V2.56617C48.4701 2.02003 48.4604 1.53039 48.4411 1.07841V1.05016L48.528 1.02191L48.5376 1.05016Z" fill="#004C8E" />
            <path d="M53.2003 0.165039C52.0708 0.165039 51.3178 0.871255 51.3178 1.93529C51.3178 2.96165 52.0129 3.59253 53.123 3.59253C53.5092 3.59253 53.8181 3.52662 53.9822 3.46071L53.876 2.94282C53.7022 2.99931 53.4512 3.03698 53.2485 3.03698C52.5245 3.03698 52.0708 2.59442 52.0708 1.87879C52.0708 1.15374 52.5149 0.71118 53.2389 0.71118C53.4609 0.71118 53.6829 0.748843 53.876 0.824173L54.0208 0.296865C53.876 0.240368 53.5864 0.165039 53.2003 0.165039Z" fill="#004C8E" />
            <path d="M56.6562 2.10478V1.56805H55.3626V0.767675H56.7334V0.221537H54.6483V3.54545H56.8106V2.99931H55.3626V2.10478H56.6562Z" fill="#004C8E" />
            <path d="M59.4364 2.20835C59.8805 2.20835 60.2569 2.07653 60.479 1.85054C60.6527 1.68105 60.7396 1.44564 60.7396 1.15374C60.7396 0.871254 60.6334 0.635851 60.45 0.475775C60.2376 0.296868 59.9191 0.202703 59.4943 0.202703C59.2047 0.202703 58.9248 0.221536 58.6641 0.259201V3.54545H59.0309V2.17069L59.0889 2.1801C59.1854 2.20835 59.3206 2.20835 59.4364 2.20835ZM59.0213 1.89762V0.504023L59.0503 0.494606C59.1371 0.475773 59.3012 0.456942 59.4943 0.456942C60.0542 0.456942 60.3728 0.720595 60.3728 1.16316C60.3728 1.64338 60.0253 1.93529 59.446 1.93529C59.2819 1.93529 59.1564 1.92587 59.0599 1.89762H59.0213Z" fill="#004C8E" />
            <path d="M62.873 0.165039C61.9366 0.165039 61.2802 0.88067 61.2802 1.90704C61.2802 2.89574 61.9173 3.59253 62.8248 3.59253C63.7901 3.59253 64.4176 2.90515 64.4176 1.84112C64.4176 0.843007 63.7901 0.165039 62.873 0.165039ZM62.8441 3.32888C62.0814 3.32888 61.676 2.61325 61.676 1.90704C61.676 1.18199 62.0428 0.447525 62.8537 0.447525C63.655 0.447525 64.0218 1.19141 64.0218 1.87879C64.0218 2.72625 63.5391 3.32888 62.8441 3.32888Z" fill="#004C8E" />
            <path d="M66.3579 3.60195C67.1881 3.60195 67.6515 3.09348 67.6515 2.17069V0.221537H67.275V2.19894C67.275 2.92398 66.9564 3.32888 66.3772 3.32888C65.827 3.32888 65.5084 2.91457 65.5084 2.19894V0.221537H65.1319V2.18952C65.1416 3.35713 65.8077 3.60195 66.3579 3.60195Z" fill="#004C8E" />
            <path d="M70.171 2.76391C70.2386 3.06523 70.3255 3.41363 70.3931 3.54545H70.7696C70.702 3.37596 70.6248 3.06523 70.5282 2.66033C70.4413 2.30252 70.2869 2.08594 70.0359 2.0012L70.0069 1.99178V1.91645L70.0359 1.90704C70.4317 1.77521 70.673 1.46448 70.673 1.07841C70.673 0.824174 70.5765 0.607601 70.4124 0.466358C70.2 0.278035 69.8911 0.202703 69.4181 0.202703C69.1188 0.202703 68.8099 0.221537 68.5879 0.268618V3.55487H68.9547V2.07653H69.4277C69.8621 2.08594 70.0649 2.27427 70.171 2.76391ZM68.9451 0.504023L68.974 0.494606C69.0512 0.475773 69.2057 0.456942 69.4374 0.456942C69.9876 0.466358 70.2965 0.701764 70.2965 1.13491C70.2965 1.55864 69.9683 1.82229 69.4567 1.82229H68.9451V0.504023Z" fill="#004C8E" />
            <path d="M72.6037 0.221537V3.54545H74.4765V3.26297H72.9705V0.221537H72.6037Z" fill="#004C8E" />
            <path d="M76.9381 2.47201L77.3146 3.54545H77.7104L76.5423 0.221537H76.0597L74.8916 3.54545H75.2681L75.6349 2.47201H76.9381ZM76.05 1.17257C76.1079 0.993665 76.1658 0.795925 76.2238 0.56052L76.2334 0.53227H76.301L76.3203 0.56052L76.3299 0.598185C76.3782 0.786509 76.4265 0.974834 76.5037 1.18199L76.8705 2.21777H75.6832L76.05 1.17257Z" fill="#004C8E" />
            <path d="M37.9671 6.00308C38.2084 5.96542 38.5559 5.93717 39.0483 5.93717C39.5406 5.93717 39.8881 6.03133 40.1295 6.21024C40.3515 6.37973 40.5059 6.66221 40.5059 7.0012C40.5059 7.34018 40.3901 7.62267 40.1777 7.81099C39.9074 8.05581 39.502 8.1688 39.029 8.1688C38.9228 8.1688 38.8262 8.15939 38.7587 8.14997V9.36466H37.9671V6.00308ZM38.7587 7.54734C38.8262 7.56617 38.9131 7.56617 39.029 7.56617C39.4537 7.56617 39.7144 7.35901 39.7144 7.01061C39.7144 6.69988 39.4923 6.51155 39.0965 6.51155C38.9324 6.51155 38.8262 6.53039 38.7683 6.5398V7.54734H38.7587Z" fill="#F4150D" />
            <path d="M41.0562 6.00308C41.3168 5.96542 41.6933 5.93717 42.1181 5.93717C42.6394 5.93717 43.0062 6.0125 43.2572 6.20082C43.4696 6.3609 43.5854 6.5963 43.5854 6.91645C43.5854 7.3496 43.2668 7.65091 42.9676 7.75449V7.77333C43.2089 7.86749 43.3537 8.09348 43.4406 8.41363C43.5468 8.79969 43.6626 9.24225 43.7302 9.37408H42.9193C42.8614 9.27992 42.7745 8.99743 42.678 8.58312C42.5814 8.15939 42.4366 8.04639 42.1084 8.03698H41.8671V9.37408H41.0755V6.00308H41.0562ZM41.8478 7.47201H42.1663C42.5621 7.47201 42.8035 7.28368 42.8035 6.98236C42.8035 6.67163 42.5814 6.51155 42.2146 6.51155C42.0215 6.51155 41.9154 6.53039 41.8574 6.5398V7.47201H41.8478Z" fill="#F4150D" />
            <path d="M47.4178 7.63208C47.4178 8.75261 46.7131 9.43057 45.6899 9.43057C44.6473 9.43057 44.0295 8.66786 44.0295 7.698C44.0295 6.68105 44.7052 5.90892 45.7478 5.90892C46.8193 5.8995 47.4178 6.69046 47.4178 7.63208ZM44.8693 7.66975C44.8693 8.3383 45.1975 8.80911 45.7285 8.80911C46.2691 8.80911 46.578 8.31005 46.578 7.65091C46.578 7.03886 46.2787 6.51155 45.7285 6.51155C45.1782 6.51155 44.8693 7.01061 44.8693 7.66975Z" fill="#F4150D" />
            <path d="M48.528 6.60572H47.5819V5.956H50.2946V6.60572H49.3292V9.36466H48.528V6.60572Z" fill="#F4150D" />
            <path d="M52.8624 7.92398H51.5688V8.73378H53.0168V9.36466H50.7676V5.956H52.9396V6.58689H51.5688V7.2931H52.8624V7.92398Z" fill="#F4150D" />
            <path d="M56.1928 9.2705C56.048 9.34583 55.7198 9.42116 55.2854 9.42116C54.0691 9.42116 53.4416 8.6867 53.4416 7.71683C53.4416 6.55864 54.3007 5.90892 55.3626 5.90892C55.7777 5.90892 56.0866 5.99366 56.2314 6.05958L56.0673 6.67163C55.9032 6.60572 55.6812 6.54922 55.4012 6.54922C54.7641 6.54922 54.2814 6.91645 54.2814 7.67916C54.2814 8.35713 54.6965 8.79027 55.4109 8.79027C55.6522 8.79027 55.9225 8.74319 56.077 8.67728L56.1928 9.2705Z" fill="#F4150D" />
            <path d="M57.525 6.60572H56.579V5.956H59.2916V6.60572H58.3262V9.36466H57.525V6.60572Z" fill="#F4150D" />
            <path d="M60.5658 5.956V9.36466H59.7646V5.956H60.5658Z" fill="#F4150D" />
            <path d="M64.5334 7.63208C64.5334 8.75261 63.8287 9.43057 62.8054 9.43057C61.7629 9.43057 61.1451 8.66786 61.1451 7.698C61.1451 6.68105 61.8208 5.90892 62.8634 5.90892C63.9349 5.8995 64.5334 6.69046 64.5334 7.63208ZM61.9849 7.66975C61.9849 8.3383 62.3131 8.80911 62.8441 8.80911C63.3847 8.80911 63.6936 8.31005 63.6936 7.65091C63.6936 7.03886 63.3943 6.51155 62.8441 6.51155C62.2938 6.51155 61.9849 7.01061 61.9849 7.66975Z" fill="#F4150D" />
            <path d="M65.103 9.36466V5.956H66.0297L66.7634 7.20835C66.9757 7.56617 67.1785 7.9899 67.3426 8.37596H67.3619C67.3136 7.92398 67.2943 7.46259 67.2943 6.95412V5.956H68.028V9.36466H67.1881L66.4352 8.0464C66.2228 7.67916 66.0007 7.24602 65.827 6.84112L65.8077 6.85054C65.827 7.30251 65.8366 7.78274 65.8366 8.3383V9.36466H65.103Z" fill="#F4150D" />
            <path d="M72.2852 8.87502C72.6037 8.56429 72.7775 8.12173 72.7775 7.585C72.7775 7.06711 72.623 6.68105 72.2948 6.38914C71.9859 6.10666 71.5322 5.97483 70.8951 5.97483C70.5861 5.97483 70.2772 5.99366 69.9683 6.04074V9.31758C70.2 9.34583 70.4606 9.35525 70.7503 9.35525C71.426 9.35525 71.9569 9.18575 72.2852 8.87502ZM70.3351 9.05393V6.29498L70.3738 6.28557C70.4703 6.26673 70.6537 6.23848 70.9144 6.23848C71.397 6.23848 71.7735 6.3609 72.0342 6.61513C72.2755 6.85054 72.3913 7.1801 72.3913 7.59442C72.3913 8.53604 71.8314 9.08218 70.8468 9.08218C70.6248 9.08218 70.48 9.07276 70.3834 9.05393H70.3351Z" fill="#004C8E" />
            <path d="M75.2488 7.72625V7.44376H73.878V6.28557H75.326V6.00308H73.5111V9.327H75.4032V9.04451H73.878V7.72625H75.2488Z" fill="#004C8E" />
            <path d="M78 8.43246C78 7.98048 77.749 7.698 77.1312 7.47201C76.6582 7.2931 76.3975 7.13302 76.3975 6.77521C76.3975 6.50214 76.6196 6.21965 77.1022 6.21965C77.3822 6.21965 77.6042 6.29498 77.749 6.3609L77.8455 6.08783C77.7201 6.03133 77.4884 5.94658 77.1215 5.94658C76.4748 5.94658 76.021 6.31382 76.021 6.83171C76.021 7.25544 76.3106 7.54734 76.9188 7.75449C77.4208 7.94282 77.6139 8.14056 77.6139 8.47012C77.6139 8.84677 77.305 9.09159 76.8319 9.09159C76.5616 9.09159 76.2817 9.01626 76.0403 8.89385L75.9535 9.17634C76.1465 9.28933 76.4941 9.37408 76.803 9.37408C77.5366 9.37408 78 9.00685 78 8.43246Z" fill="#004C8E" />
            <path d="M39.029 13.8939C39.5116 13.8939 39.8978 13.7714 40.1488 13.5455C40.3515 13.3571 40.4577 13.0841 40.4577 12.7639C40.4577 12.4626 40.3225 12.1801 40.1005 12.0106C39.8688 11.8317 39.5309 11.747 39.0483 11.747C38.6428 11.747 38.2953 11.7658 38.0154 11.8129V15.0991H38.7201V13.875L38.7683 13.8844C38.8359 13.8939 38.9228 13.8939 39.029 13.8939ZM38.7104 13.3665V12.2931L38.7394 12.2837C38.778 12.2743 38.8842 12.2554 39.0772 12.2554C39.4923 12.2554 39.7433 12.4626 39.7433 12.8016C39.7433 13.1688 39.4634 13.4042 39.0097 13.4042C38.8842 13.4042 38.7973 13.3948 38.7297 13.3854L38.7104 13.3665Z" fill="#004C8E" />
            <path d="M42.6973 14.3458C42.7938 14.7413 42.871 15.005 42.929 15.1085H43.653C43.6047 14.9861 43.5468 14.7696 43.4792 14.5247C43.4503 14.4212 43.4213 14.3082 43.3923 14.2046C43.2958 13.8656 43.1607 13.6773 42.9483 13.5925L42.9193 13.5831V13.4984L42.9483 13.489C43.2475 13.3854 43.5371 13.1029 43.5371 12.6886C43.5371 12.3967 43.4309 12.1707 43.2282 12.0106C42.9965 11.8317 42.649 11.7564 42.1181 11.7564C41.7512 11.7564 41.3748 11.7752 41.1045 11.8223V15.0991H41.8092V13.762H42.0891C42.4463 13.7714 42.6007 13.9127 42.6973 14.3458ZM41.7995 12.2743L41.8381 12.2648C41.9347 12.246 42.0602 12.2366 42.205 12.2366C42.6104 12.246 42.8324 12.4249 42.8324 12.7545C42.8324 13.0841 42.5718 13.2912 42.1567 13.2912H41.7995V12.2743Z" fill="#004C8E" />
            <path d="M45.7381 11.7281C44.7535 11.7281 44.0681 12.4438 44.0681 13.4701C44.0681 14.4777 44.7149 15.165 45.6802 15.165C46.7035 15.165 47.3696 14.4777 47.3696 13.4136C47.3696 12.5662 46.8676 11.7281 45.7381 11.7281ZM45.7285 14.6283C45.1782 14.6283 44.8307 14.1669 44.8307 13.4513C44.8307 12.7357 45.1879 12.246 45.7285 12.246C46.2594 12.246 46.6262 12.7357 46.6262 13.423C46.6166 14.1575 46.2691 14.6283 45.7285 14.6283Z" fill="#004C8E" />
            <path d="M47.8715 13.4795C47.8619 13.9692 48.0357 14.4023 48.3542 14.6848C48.6728 14.9861 49.1361 15.1368 49.754 15.1368C50.1691 15.1368 50.6035 15.0426 50.8834 14.9579V13.2347H49.6574V13.7526H50.198V14.5342L50.1691 14.5436C50.1015 14.5812 49.947 14.6001 49.754 14.6001C49.0493 14.6001 48.6052 14.1481 48.6052 13.4419C48.6052 12.7357 49.0686 12.2931 49.8119 12.2931C50.198 12.2931 50.4394 12.359 50.6035 12.4249L50.7483 11.8882C50.5745 11.8129 50.2463 11.7375 49.8215 11.7375C48.6535 11.747 47.8812 12.4249 47.8715 13.4795Z" fill="#004C8E" />
            <path d="M53.2485 14.3458C53.3451 14.7413 53.4223 15.005 53.4802 15.1085H54.2042C54.1559 14.9861 54.098 14.7696 54.0305 14.5247C54.0015 14.4212 53.9725 14.3082 53.9436 14.2046C53.847 13.8656 53.7119 13.6773 53.4995 13.5925L53.4706 13.5831V13.4984L53.4995 13.489C53.7988 13.3854 54.0884 13.1029 54.0884 12.6886C54.0884 12.3967 53.9822 12.1707 53.7795 12.0106C53.5478 11.8317 53.2003 11.7564 52.6693 11.7564C52.3025 11.7564 51.926 11.7752 51.6557 11.8223V15.0991H52.3604V13.762H52.6404C53.0072 13.7714 53.1616 13.9127 53.2485 14.3458ZM52.3507 12.2743L52.3894 12.2648C52.4859 12.246 52.6114 12.2366 52.7562 12.2366C53.1616 12.246 53.3837 12.4249 53.3837 12.7545C53.3837 13.0841 53.123 13.2912 52.7079 13.2912H52.3507V12.2743Z" fill="#004C8E" />
            <path d="M56.6755 14.2234L56.9458 15.0991H57.7084L56.6465 11.7752H55.6619L54.6097 15.0991H55.3433L55.5943 14.2234H56.6755ZM55.9129 12.9616C55.9515 12.8487 55.9805 12.7074 56.0094 12.5662C56.0287 12.472 56.048 12.3873 56.077 12.3025L56.0866 12.2743H56.1542L56.1735 12.3025L56.2025 12.4061C56.2507 12.585 56.299 12.7922 56.3569 12.9616L56.5886 13.7432H55.6908L55.9129 12.9616Z" fill="#004C8E" />
            <path d="M61.3188 13.8374L61.3671 15.0991H62.0525L61.8594 11.7752H60.8941L60.5272 12.8769C60.4017 13.3006 60.2762 13.7714 60.1894 14.101L60.1797 14.1293H60.0928L60.0832 14.0916C60.0156 13.7526 59.9191 13.3665 59.7839 12.8769L59.446 11.7752H58.4614L58.2394 15.0991H58.8861L58.9441 13.8468C58.9634 13.5737 58.973 13.2536 58.9827 12.9428C58.9923 12.7733 58.9923 12.6038 59.002 12.4343V12.3967H59.0792L59.0985 12.4343C59.1757 12.8392 59.2916 13.3195 59.4171 13.762L59.7839 15.052H60.3438L60.7589 13.7526C60.9037 13.3289 61.0485 12.8487 61.1547 12.4343L61.1644 12.4061H61.2609V12.4532C61.2899 12.9616 61.2995 13.489 61.3188 13.8374Z" fill="#004C8E" />
            <path d="M65.798 13.8374L65.8463 15.0991H66.5317L66.3386 11.7752H65.3733L65.0064 12.8769C64.8809 13.3006 64.7555 13.7714 64.6686 14.101L64.6589 14.1293H64.5817L64.572 14.0916C64.5045 13.7526 64.4079 13.3665 64.2728 12.8769L63.9349 11.7752H62.9502L62.7282 15.0991H63.375L63.4329 13.8468C63.4522 13.5737 63.4619 13.2536 63.4715 12.9428C63.4812 12.7733 63.4812 12.6038 63.4908 12.4343V12.3967H63.5681L63.5874 12.4343C63.6646 12.8392 63.7804 13.3195 63.9059 13.762L64.2728 15.052H64.8327L65.2478 13.7526C65.3926 13.3289 65.5374 12.8487 65.6436 12.4343L65.6532 12.4061H65.7498V12.4532C65.7594 12.9616 65.7787 13.489 65.798 13.8374Z" fill="#004C8E" />
            <path d="M69.3022 13.6584V13.1217H67.999V12.3308H69.3795V11.7846H67.2847V15.0991H69.447V14.553H67.999V13.6584H69.3022Z" fill="#004C8E" />
            <path d="M72.3334 14.1387C72.3334 13.6867 72.0631 13.3854 71.4453 13.1594C71.0109 12.9993 70.7599 12.8957 70.7599 12.6415C70.7599 12.472 70.9047 12.2743 71.3198 12.2743C71.5708 12.2743 71.8025 12.3213 72.0245 12.4155L72.179 11.8788C71.928 11.7752 71.648 11.7281 71.3295 11.7281C70.5572 11.7281 70.0359 12.1236 70.0359 12.7074C70.0359 13.1406 70.3448 13.4607 70.9723 13.6773C71.3681 13.8091 71.5901 13.9409 71.5901 14.1952C71.5901 14.4494 71.3681 14.6095 70.9819 14.6095C70.7116 14.6095 70.3931 14.5342 70.1324 14.4117L69.9876 14.9673C70.1807 15.0615 70.5475 15.1556 70.9337 15.1556C71.9666 15.1556 72.3334 14.6283 72.3334 14.1387Z" fill="#004C8E" />
            <path d="M5.23218 0.165039C2.34579 0.165039 0 2.33076 0 5.00496V13.9598C0 14.5812 0.511632 15.0897 1.13911 15.0897C1.76658 15.0897 2.27822 14.5812 2.27822 13.9598V5.00496C2.27822 3.55487 3.60074 2.37784 5.23218 2.37784C6.86361 2.37784 8.18614 3.55487 8.18614 5.00496V13.9598C8.18614 14.5812 8.69777 15.0897 9.32525 15.0897C9.95272 15.0897 10.4644 14.5812 10.4644 13.9598V5.00496C10.4644 2.33076 8.11856 0.165039 5.23218 0.165039Z" fill="#004C8E" />
            <path d="M17.5403 0.165039H13.4183C12.8101 0.165039 12.3082 0.664099 12.3082 1.26674C12.3082 1.86937 12.8101 2.36843 13.4183 2.36843H17.5403C19.1718 2.36843 20.4943 3.54545 20.4943 4.99555C20.4943 6.44564 19.1718 7.62267 17.5403 7.62267H14.5864V6.11608C14.5864 5.50402 14.0747 5.00496 13.4473 5.00496C12.8198 5.00496 12.3082 5.50402 12.3082 6.11608V13.9786C12.3082 14.5907 12.8101 15.0803 13.4183 15.0803H13.4762C14.0844 15.0803 14.5864 14.5812 14.5864 13.9786V10.0709C14.5864 9.93905 14.7215 9.83547 14.8953 9.83547H17.5403C20.4267 9.83547 22.7725 7.66975 22.7725 4.99555C22.7725 2.32135 20.4267 0.165039 17.5403 0.165039Z" fill="#004C8E" />
            <path d="M29.6554 0.165039H25.5334C24.9252 0.165039 24.4233 0.664099 24.4233 1.26674C24.4233 1.86937 24.9252 2.36843 25.5334 2.36843H29.6554C31.2869 2.36843 32.6094 3.54545 32.6094 4.99555C32.6094 6.44564 31.2869 7.62267 29.6554 7.62267H26.7015V6.11608C26.7015 5.50402 26.1898 5.00496 25.5624 5.00496C24.9349 5.00496 24.4233 5.50402 24.4233 6.11608V13.9786C24.4233 14.5907 24.9252 15.0803 25.5334 15.0803H25.5913C26.1995 15.0803 26.7015 14.5812 26.7015 13.9786V10.0615C26.7111 9.93905 26.8463 9.83547 27.0007 9.83547H29.6458C32.5322 9.83547 34.878 7.66975 34.878 4.99555C34.878 2.32135 32.5418 0.165039 29.6554 0.165039Z" fill="#004C8E" />
            <path d="M6.35198 7.72625H6.1396L6.1203 7.2931C6.09133 6.9447 5.69554 6.67163 5.22252 6.67163C4.7495 6.67163 4.35371 6.9447 4.32475 7.2931L4.30545 7.72625H4.09307C4.00619 7.72625 3.93861 7.79216 3.93861 7.8769V9.77897C3.93861 9.86372 4.00619 9.92963 4.09307 9.92963H6.36163C6.44851 9.92963 6.51609 9.86372 6.51609 9.77897V7.8769C6.50643 7.79216 6.43886 7.72625 6.35198 7.72625ZM5.32871 8.89385V9.15751C5.32871 9.22342 5.28044 9.2705 5.21287 9.2705C5.1453 9.2705 5.09703 9.22342 5.09703 9.15751V8.89385C5.02945 8.85619 4.97153 8.79969 4.97153 8.70553C4.97153 8.5737 5.07772 8.47012 5.21287 8.47012C5.34802 8.47012 5.45421 8.5737 5.45421 8.70553C5.46386 8.79969 5.39629 8.85619 5.32871 8.89385ZM4.57574 7.72625L4.5854 7.40609C4.6047 7.17069 4.875 6.98236 5.20322 6.98236C5.53143 6.98236 5.80173 7.17069 5.82104 7.40609L5.83069 7.72625H4.57574Z" fill="#F4150D" />
        </svg>
    );
}