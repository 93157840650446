import { fr as sharedFr } from "@vaultinum/app-sdk";

import { Translation } from "../Translation";

export const fr: Translation = {
    ...sharedFr,
    errorHandling: () => <></>, //obligatoire
    httpErrorHandling: () => <></>, //obligatoire
    projectName: "Constats horodatés automatiques",
    connectNow: "Connectez-vous !",
    createReport: "Créez un constat internet horodaté à forte valeur probante",
    about: "A propos",
    information: "Informations légales",
    login: "Connexion",
    forgottenAccessKey: "Clé d'accès oubliée ? Contactez l'APP par email à constat@app.asso.fr",
    email: "Email",
    accessKey: "Clé d'accès",
    requiredFields: "champs obligatoires",
    requiredField: "Ce champ est obligatoire",
    reportForm: "Informations du rapport",
    reportFormInstructions: 'Cliquez ensuite sur "Débuter la constatation" pour continuer',
    legalStatusHelper: "Personne physique ou personne morale",
    option: "option",
    printScreenOption: "Ajouter en annexe une copie écran complète de chaque page visitée.",
    start: "Débuter la constatation",
    report: "Rapport",
    logoff: "Déconnexion",
    requester: "Requérant",
    title: "Titre",
    createOrSearchRequester: "Créer ou rechercher un requérant",
    wrongLoginAccessKeyCombination: "Email ou clé d'accès invalide",
    connect: "Connexion",
    newTabNotSupported:
        "Le site que vous consultez a ouvert un lien dans un nouvel onglet de navigation. Cette fonctionnalité n'est pas encore supportée.",
    connectionToServerError: "Erreur de connexion au serveur, rechargez la page pour recommencer la constatation.",
    errorCreatingRequester: "Erreur lors de la création du requérant",
    errorGettingRequesters: "Erreur lors de la récupération des requérants",
    eventHistory: "Historique d'interactions",
    cancel: "Annuler",
    end: "Terminer",
    observationInProgress: "Constatations en cours :",
    mustBeConnectedToDownload: "Vous devez être connecté pour télécharger le rapport",
    errorDuringDownload: "Nous éprouvons des difficultés techniques...",
    errorDuringReportGeneration: "Une erreur est survenue lors de la génération du rapport, veuillez réessayer plus tard",
    observationMadeIn: "Constatation réalisée en : ",
    reportWillBeDownloaded: "Le rapport va être téléchargé automatiquement, cela peut prendre quelques minutes",
    downloadReport: "Télécharger le rapport",
    reportGenerating: "Rapport en cours de génération",
    reportGeneratedSuccessfully: "C'est terminé !",
    reportDownloaded: "Rapport téléchargé automatiquement",
    reportTitleRequired: "Le titre du rapport est obligatoire",
    reportTitleNoSpecialChars: "Le titre du rapport ne doit pas contenir de caractères spéciaux",
    reportRequesterNoSpecialChars: "Le requérant ne doit pas contenir de caractères spéciaux",
    requesterRequired: "Le requérant est obligatoire",
    emailRequired: "L'email est obligatoire",
    accessKeyRequired: "La clé d'accès est obligatoire",
    reportTypeRequired: "Le type de rapport est obligatoire",
    reportDownloadedAutomatically:
        "Le rapport a été téléchargé automatiquement. Vous pouvez aussi cliquer sur le bouton ci-dessous pour le télécharger manuellement",
    thanksForWaiting: "Merci de patienter",
    weAreWorkingOnIt: "Nous travaillons à résoudre le problème",
    retryLater: "Veuillez réessayer dans quelques minutes. Si le problème persiste, contactez-nous à constats@app.asso.fr",
    pauseInteractionsDuringFullPageCapture: "Capture de page complète en cours, merci de patienter.",
    resumeInteractionsAfterFullPageCapture: "Capture de page complète terminée.",
    reportTypeLabel: "Champ d’application du rapport",
    reportTypeIPLabel: "Propriété intellectuelle.",
    reportTypeIPSubLabel: "En partenariat exclusif avec l’Agence pour la Protection des Programmes",
    reportTypeOtherLabel: "Autre : Diffamation, concurrence déloyale, etc.",
    yes: "Oui",
    no: "Non",
    confirmCancelReport: "Êtes-vous sûr de vouloir annuler la constatation ?",
    confirmEndReport: "Êtes-vous sûr de vouloir terminer la constatation ?",
    updateInProgress: "Mise à jour en cours",
    maintenanceMessage: "Merci de votre compréhension pendant ces améliorations essentielles. On se retrouve très bientôt !",
    errorWhileProcessingEvent: "Une erreur s'est produite lors de la captation du dernier évènement. Veuillez recommencer votre dernière action.",
    sessionStartFeedback: {
        startSession: "Initialisation de la session...",
        browserSetup: "Configuration du navigateur...",
        openPage: "Ouverture de la page...",
        captureFirstPage: "Capture d'écran de la page...",
        captureHtml: "Capture du code source de la page...",
    },
    reportList: {
        header: {
            title: "Titre",
            requester: "Requérant",
            reportType: "Type",
            status: "Statut",
            visitedWebsites: "Sites visités",
            creationDate: "Date",
        },
        reportType: {
            IP: "PI",
            OTHER: "Autre",
        },
        noReport: "Aucun rapport disponible",
    },
    myReports: "Mes rapports",
    history: "Historique",
    new: "Nouveau",
    download: {
        status: {
            ssl: "Vérification de la connexion sécurisée",
            whois: "Recherche des informations Whois",
            dns: "Recherche des informations DNS",
            generateReport: "Génération du rapport",
            ipDetails: "Recherche des informations sur l'adresse IP",
            os: "Recherche des informations sur le système d'exploitation",
            storeMetadata: "Enregistrement des métadonnées",
            uploadReport: "Enregistrement du rapport",
            sealReport: "Scellement du rapport",
            createZip: "Création du fichier ZIP",
        },
    },
    reportNotFound: "Rapport introuvable",
    invalidUrl: "URL invalide",
    browserError: "Erreur lors de l'initialisation du navigateur, veuillez réessayer",
    platformDevelopedInPartnershipWith: "Plateforme développée en partenariat avec",
    needHelp: "Besoin d'aide ? Contactez-nous",
    screenCapturedSuccessfully: "Capture d'écran effectuée avec succès",
};
