import { InteractionEvent } from "@vaultinum/vaultinum-legal-proof-api";

export enum IconType {
    DEFAULT = "default",
    ACTIVE = "active",
    DISABLED = "disabled",
}

export interface ActionStatus {
    navigationButtons: boolean;
    endButton: boolean;
    areaIcon: IconType;
}

export namespace Notifications {
    export interface Event {
        id?: InteractionEvent;
        date: string;
        label: string;
    }
}
