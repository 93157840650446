export default function TrashIcon({ className }: { className?: string }) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 15" className={className} xmlns="http://www.w3.org/2000/svg">
            <g id="remove 1">
                <path
                    id="Vector"
                    d="M1.69824 3.43967C1.69824 3.62615 1.77232 3.80499 1.90418 3.93685C2.03604 4.06871 2.21489 4.14279 2.40137 4.14279H2.77637V12.4514C2.77699 13.0649 3.02094 13.6531 3.45471 14.0869C3.88847 14.5208 4.47662 14.7649 5.09012 14.7656H10.9101C11.5236 14.7649 12.1118 14.5208 12.5455 14.0869C12.9793 13.6531 13.2232 13.0649 13.2239 12.4514V4.14279H13.5989C13.7853 4.14279 13.9642 4.06871 14.0961 3.93685C14.2279 3.80499 14.302 3.62615 14.302 3.43967C14.302 3.25319 14.2279 3.07435 14.0961 2.94248C13.9642 2.81062 13.7853 2.73654 13.5989 2.73654H11.1379V2.01092C11.1379 1.03123 10.3411 0.233887 9.36137 0.233887H6.63887C5.65918 0.233887 4.8623 1.03076 4.8623 2.01092V2.73607H2.40137C2.21489 2.73607 2.03604 2.81015 1.90418 2.94201C1.77232 3.07388 1.69824 3.25272 1.69824 3.4392V3.43967ZM6.26855 2.01139C6.26855 1.80654 6.43449 1.64061 6.63887 1.64061H9.36137C9.56574 1.64061 9.73168 1.80654 9.73168 2.01139V2.73654H6.26809V2.01092L6.26855 2.01139ZM4.18262 4.14279H11.8176V12.4514C11.8174 12.692 11.7217 12.9228 11.5516 13.093C11.3814 13.2632 11.1508 13.359 10.9101 13.3594H5.09012C4.84947 13.359 4.61879 13.2632 4.44866 13.093C4.27854 12.9228 4.18287 12.692 4.18262 12.4514V4.14279Z"
                />
                <path
                    id="Vector_2"
                    d="M6.25244 12.0642C6.43892 12.0642 6.61776 11.9902 6.74963 11.8583C6.88149 11.7264 6.95557 11.5476 6.95557 11.3611V6.14111C6.95557 5.95463 6.88149 5.77579 6.74963 5.64393C6.61776 5.51207 6.43892 5.43799 6.25244 5.43799C6.06596 5.43799 5.88712 5.51207 5.75526 5.64393C5.6234 5.77579 5.54932 5.95463 5.54932 6.14111V11.3611C5.54932 11.5476 5.6234 11.7264 5.75526 11.8583C5.88712 11.9902 6.06596 12.0642 6.25244 12.0642ZM9.74744 12.0642C9.93392 12.0642 10.1128 11.9902 10.2446 11.8583C10.3765 11.7264 10.4506 11.5476 10.4506 11.3611V6.14111C10.4506 5.95463 10.3765 5.77579 10.2446 5.64393C10.1128 5.51207 9.93392 5.43799 9.74744 5.43799C9.56096 5.43799 9.38212 5.51207 9.25026 5.64393C9.1184 5.77579 9.04432 5.95463 9.04432 6.14111V11.3611C9.04432 11.5476 9.1184 11.7264 9.25026 11.8583C9.38212 11.9902 9.56096 12.0642 9.74744 12.0642Z"
                />
            </g>
        </svg>
    );
}
