import { yupResolver } from "@hookform/resolvers/yup";
import { Info, InputFields, useI18n } from "@vaultinum/app-sdk";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Button, FormFieldsInput } from "../../design-system";
import { Translation } from "../../i18n";
import { LoginSchema } from "../../schemas";

function WelcomeMessage({ translation }: { translation: Translation }) {
    return (
        <span className="flex flex-col text-left text-6xl leading-[70px] max-w-2xl">
            <span className="text-warning m-0 font-semibold">{translation.connectNow}</span>
            <span className="text-white m-0 font-normal">{translation.createReport}</span>
        </span>
    );
}

export default function LoginPage() {
    const [errorMessage, setErrorMessage] = useState("");
    const { translation } = useI18n<Translation>();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FieldValues>({
        resolver: yupResolver(LoginSchema(translation)),
        mode: "onChange",
    });

    const LOGIN_FORM: InputFields[] = [
        {
            name: "email",
            label: translation.addRequiredMarkerToString(translation.email),
            labelPosition: "top",
            autofocus: true,
        },
        {
            name: "accessKey",
            label: translation.addRequiredMarkerToString(translation.accessKey),
            labelPosition: "top",
            type: "password",
        },
    ];

    const login = async (data: FieldValues): Promise<void> => {
        try {
            await signInWithEmailAndPassword(getAuth(), data.email, data.accessKey);
        } catch (error) {
            setErrorMessage(translation.wrongLoginAccessKeyCombination);
        }
    };

    const handleErrorMessage = () => {
        if (errorMessage) {
            return (
                <Info type="danger" className="mb-2">
                    {errorMessage}
                </Info>
            );
        } else {
            return null;
        }
    };

    return (
        <div className="flex h-full items-center justify-center px-32 max-w-3/4">
            <WelcomeMessage translation={translation} />
            <main className="flex flex-col space-y-6">
                <div className="w-[530px]">
                    <div className="relative flex flex-col items-center p-10 bg-white rounded-4xl">
                        <div className="flex flew-row justify-between items-start w-full">
                            <h2 className="text-3xl font-bold text-primary mb-5">{translation.login}</h2>
                        </div>
                        <form className="w-full">
                            {errorMessage && handleErrorMessage()}
                            <FormFieldsInput fields={LOGIN_FORM} control={control} errors={errors} />
                            <div className="flex items-end place-content-between pt-5">
                                <div className="-mt-1">
                                    <span className="text-sm">*</span>
                                    <span className="text-xs font-semibold text-primaryDark">{translation.requiredFields}</span>
                                </div>
                                <Button type="submit" label={translation.connect} onClick={handleSubmit(login)} withLoader={true} />
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}
