import { ReactNode } from "react";
import { Button, CopyableElement, CopyableText, CustomLink } from "../index";

export type LayoutSideElement = {
    label?: ReactNode;
    elementType?: "button" | "link" | "textWithIcon" | "textWithIconAndLink" | "component";
    action?: (e: React.MouseEvent<HTMLElement>) => void;
    path?: string;
    icon?: ReactNode;
    targetLink?: "_blank" | "_self";
    disabled?: boolean;
    variant?: "default" | "danger";
    buttonIcon?: ReactNode;
    buttonLoading?: boolean;
    smallLink?: boolean;
    component?: ReactNode;
    linkLabel?: string;
};

export function LayoutSide({ sideElements, title, copyableTitle }: { sideElements: LayoutSideElement[]; title?: ReactNode; copyableTitle?: string }) {
    const handleTypeComponent = (sideElement: LayoutSideElement) => {
        switch (sideElement.elementType) {
            case "link":
                return (
                    <CustomLink
                        path={sideElement.path ? sideElement.path : "#"}
                        label={sideElement.label as string}
                        target={sideElement.targetLink}
                        disabled={sideElement.disabled}
                        small={sideElement.smallLink}
                    />
                );
            case "button":
                return (
                    <Button
                        onClick={e => (sideElement.action ? sideElement.action(e) : undefined)}
                        label={sideElement.label as string}
                        disabled={sideElement.disabled}
                        variant={sideElement.variant}
                        icon={sideElement.buttonIcon}
                        loading={sideElement.buttonLoading}
                    />
                );
            case "textWithIcon":
                return (
                    <>
                        <div className="flex space-x-2">
                            <span className="w-5 h-5">{sideElement.icon}</span>
                            <CopyableText value={sideElement.label as string} />
                        </div>
                        {sideElement.component}
                    </>
                );
            case "textWithIconAndLink":
                return (
                    <div className="flex space-x-2">
                        <span className="flex w-5 h-5">{sideElement.icon}</span>
                        <div className="flex flex-col">
                            <span>{sideElement.label} </span>
                            <CustomLink
                                path={sideElement.path ? sideElement.path : "#"}
                                label={sideElement.label as string}
                                target={sideElement.targetLink}
                                disabled={sideElement.disabled}
                                small={sideElement.smallLink}
                            />
                        </div>
                    </div>
                );
            case "component":
                return sideElement.component;
            default:
                return <CopyableText value={sideElement.label as string} />;
        }
    };

    return (
        <div className="w-full space-y-2.5 p-4 border-b-2 border-light-grey">
            {title && !copyableTitle && <h2 className="text-base font-bold">{title}</h2>}
            {copyableTitle && <CopyableElement value={copyableTitle}>{<h2 className="text-base font-bold">{title}</h2>}</CopyableElement>}
            <div className="text-sm text-deep-blue space-y-2.5">
                {sideElements.map((el, index) => (
                    <div key={index}>{handleTypeComponent(el)}</div>
                ))}
            </div>
        </div>
    );
}
