export default function Area({ onClick, color = "#0C0171" }: { onClick: () => void; color?: string }) {
    return (
        <svg onClick={onClick} cursor="pointer" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2.5" y="2.5" width="31" height="31" rx="2.5" stroke="white" strokeOpacity="0.04" />
            <g filter="url(#filter0_d_84_200)">
                <rect x="2" y="2" width="32" height="32" rx="5" fill="white" />
                <rect x="2.5" y="2.5" width="31" height="31" rx="4.5" stroke="white" strokeOpacity="0.04" />
            </g>
            <g clipPath="url(#clip0_84_200)">
                <path
                    d="M26.3721 5.34882H20.7907C20.5439 5.34882 20.3073 5.44682 20.1329 5.62127C19.9584 5.79573 19.8604 6.03234 19.8604 6.27905C19.8604 6.52576 19.9584 6.76237 20.1329 6.93682C20.3073 7.11127 20.5439 7.20928 20.7907 7.20928H25.4418V11.8604C25.4418 12.1072 25.5398 12.3438 25.7143 12.5182C25.8887 12.6927 26.1253 12.7907 26.3721 12.7907C26.6188 12.7907 26.8554 12.6927 27.0298 12.5182C27.2043 12.3438 27.3023 12.1072 27.3023 11.8604V6.27905C27.3023 6.03234 27.2043 5.79573 27.0298 5.62127C26.8554 5.44682 26.6188 5.34882 26.3721 5.34882ZM15.2093 22.093H10.5581V17.4418C10.5581 17.1951 10.4601 16.9585 10.2856 16.7841C10.1112 16.6096 9.87458 16.5116 9.62786 16.5116C9.38115 16.5116 9.14454 16.6096 8.97009 16.7841C8.79564 16.9585 8.69763 17.1951 8.69763 17.4418V23.0232C8.69763 23.2699 8.79564 23.5066 8.97009 23.681C9.14454 23.8555 9.38115 23.9535 9.62786 23.9535H15.2093C15.456 23.9535 15.6926 23.8555 15.867 23.681C16.0415 23.5066 16.1395 23.2699 16.1395 23.0232C16.1395 22.7765 16.0415 22.5399 15.867 22.3655C15.6926 22.191 15.456 22.093 15.2093 22.093Z"
                    fill={color}
                />
                <g clipPath="url(#clip1_84_200)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.3489 23.5117C21.6828 23.8457 22.2243 23.8457 22.5582 23.5117C22.8921 23.1778 22.8921 22.6364 22.5582 22.3024C22.2243 21.9685 21.6828 21.9685 21.3489 22.3024C21.015 22.6364 21.015 23.1778 21.3489 23.5117ZM23.4652 24.4187C22.6303 25.2536 21.2768 25.2536 20.4419 24.4187C19.6071 23.5839 19.6071 22.2303 20.4419 21.3954C21.2768 20.5606 22.6303 20.5606 23.4652 21.3954C24.3 22.2303 24.3 23.5839 23.4652 24.4187Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.2791 19.5814C25.6131 19.9154 26.1545 19.9154 26.4884 19.5814C26.8224 19.2475 26.8224 18.7061 26.4884 18.3721C26.1545 18.0382 25.6131 18.0382 25.2791 18.3721C24.9452 18.7061 24.9452 19.2475 25.2791 19.5814ZM27.3954 20.4884C26.5606 21.3233 25.207 21.3233 24.3722 20.4884C23.5373 19.6536 23.5373 18.3 24.3722 17.4651C25.207 16.6303 26.5606 16.6303 27.3954 17.4651C28.2303 18.3 28.2303 19.6536 27.3954 20.4884Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.4162 17.9516C20.4815 17.6035 20.8166 17.3742 21.1647 17.4395L24.5313 18.0707C24.8794 18.136 25.1087 18.4711 25.0434 18.8192C24.9781 19.1674 24.643 19.3967 24.2949 19.3314L20.9283 18.7002C20.5802 18.6349 20.3509 18.2998 20.4162 17.9516Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.796 22.0668C21.4479 22.1321 21.1127 21.9028 21.0474 21.5546L19.2706 12.0778C19.2053 11.7297 19.4346 11.3946 19.7827 11.3293C20.1308 11.264 20.466 11.4933 20.5313 11.8414L22.3081 21.3182C22.3734 21.6664 22.1441 22.0015 21.796 22.0668Z"
                        fill={color}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.306 16.806C14.3713 16.4578 14.7064 16.2285 15.0546 16.2938L18.9105 17.0168C19.2586 17.082 19.4879 17.4172 19.4227 17.7653C19.3574 18.1134 19.0222 18.3427 18.6741 18.2775L14.8182 17.5545C14.4701 17.4892 14.2408 17.1541 14.306 16.806Z"
                        fill={color}
                    />
                </g>
            </g>
            <path
                d="M28.0522 30.173V31.0233H25.236V30.173H28.0522ZM25.5921 25.7326V31.0233H24.502V25.7326H25.5921ZM27.6852 27.8874V28.7159H25.236V27.8874H27.6852ZM28.0485 25.7326V26.5865H25.236V25.7326H28.0485Z"
                fill={color}
            />
            <path d="M23.2559 25.7326V31.0233H22.1658L20.0401 27.4768V31.0233H18.95V25.7326H20.0401L22.1695 29.2827V25.7326H23.2559Z" fill={color} />
            <path
                d="M17.8674 28.2544V28.5051C17.8674 28.9072 17.8129 29.2682 17.7039 29.588C17.5949 29.9077 17.4411 30.1803 17.2424 30.4056C17.0438 30.6284 16.8064 30.7992 16.5302 30.9179C16.2565 31.0366 15.9524 31.096 15.6181 31.096C15.2863 31.096 14.9822 31.0366 14.7061 30.9179C14.4323 30.7992 14.1949 30.6284 13.9939 30.4056C13.7928 30.1803 13.6366 29.9077 13.5251 29.588C13.4161 29.2682 13.3616 28.9072 13.3616 28.5051V28.2544C13.3616 27.8498 13.4161 27.4889 13.5251 27.1715C13.6341 26.8518 13.788 26.5792 13.9866 26.354C14.1877 26.1287 14.4251 25.9567 14.6988 25.838C14.975 25.7193 15.279 25.6599 15.6109 25.6599C15.9452 25.6599 16.2492 25.7193 16.5229 25.838C16.7991 25.9567 17.0365 26.1287 17.2352 26.354C17.4362 26.5792 17.5913 26.8518 17.7003 27.1715C17.8117 27.4889 17.8674 27.8498 17.8674 28.2544ZM16.7664 28.5051V28.2471C16.7664 27.9661 16.741 27.719 16.6901 27.5058C16.6392 27.2927 16.5641 27.1134 16.4648 26.9681C16.3655 26.8227 16.2444 26.7137 16.1014 26.641C15.9585 26.5659 15.795 26.5284 15.6109 26.5284C15.4268 26.5284 15.2633 26.5659 15.1203 26.641C14.9798 26.7137 14.8599 26.8227 14.7606 26.9681C14.6637 27.1134 14.5898 27.2927 14.5389 27.5058C14.4881 27.719 14.4626 27.9661 14.4626 28.2471V28.5051C14.4626 28.7837 14.4881 29.0308 14.5389 29.2464C14.5898 29.4596 14.6649 29.64 14.7642 29.7878C14.8635 29.9332 14.9847 30.0434 15.1276 30.1185C15.2705 30.1936 15.434 30.2311 15.6181 30.2311C15.8023 30.2311 15.9658 30.1936 16.1087 30.1185C16.2516 30.0434 16.3715 29.9332 16.4684 29.7878C16.5653 29.64 16.6392 29.4596 16.6901 29.2464C16.741 29.0308 16.7664 28.7837 16.7664 28.5051Z"
                fill={color}
            />
            <path
                d="M12.6243 30.173V31.0233H8.87425V30.173H12.6243ZM12.5697 26.3394L9.35754 31.0233H8.59082V30.3983L11.8285 25.7326H12.5697V26.3394ZM12.1955 25.7326V26.5865H8.59445V25.7326H12.1955Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.69769 5.72095H10.1861V6.52715H9.50389V7.20932H8.69769V5.72095ZM8.69769 13.907V15.3954H10.1861V14.5892H9.50389V13.907H8.69769ZM16.8837 15.3954H18.3721V13.907H17.5659V14.5892H16.8837V15.3954ZM18.3721 7.20932V5.72095H16.8837V6.52715H17.5659V7.20932H18.3721ZM11.1163 5.72095V7.20932H12.7287V5.72095H11.1163ZM14.3411 5.72095V7.20932H15.9535V5.72095H14.3411ZM18.3721 8.13955H16.8837V9.75195H18.3721V8.13955ZM18.3721 11.3644H16.8837V12.9768H18.3721V11.3644ZM15.9535 15.3954V13.907H14.3411V15.3954H15.9535ZM12.7287 15.3954V13.907H11.1163V15.3954H12.7287ZM8.69769 12.9768H10.1861V11.3644H8.69769V12.9768ZM8.69769 9.75196H10.1861V8.13955H8.69769V9.75196Z"
                fill={color}
            />
            <defs>
                <filter id="filter0_d_84_200" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_84_200" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_84_200" result="shape" />
                </filter>
                <clipPath id="clip0_84_200">
                    <rect width="22.3256" height="22.3256" fill="white" transform="translate(7.20929 3.48834)" />
                </clipPath>
                <clipPath id="clip1_84_200">
                    <rect width="13.6818" height="13.6818" fill="white" transform="translate(21.3489 28.0466) rotate(-135)" />
                </clipPath>
            </defs>
        </svg>
    );
}
