import classNames from "classnames";
import { useState } from "react";

import { ChevronSmall, Input, useOutsideClick } from "../..";

export type FilterItems = {
    [key: string]: {
        title: string;
        elements: string[];
    };
};

export function FilterBox({
    filters,
    title,
    resetTitle,
    filtersSelected,
    onItemsSelected,
    disabled
}: {
    filters: FilterItems;
    title: string;
    resetTitle: string;
    filtersSelected?: string[];
    onItemsSelected?: (selectedItems: string[]) => void;
    disabled?: boolean;
}) {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<string[]>(filtersSelected ?? []);
    const isOutside = useOutsideClick<HTMLDivElement>(() => setIsVisible(false));

    const onFilterChanged = (itemSelected: string) => {
        let items = [];
        if (selectedItems.includes(itemSelected)) {
            items = selectedItems.filter(item => item !== itemSelected);
        } else {
            items = [...selectedItems, itemSelected];
        }

        setSelectedItems(items);
        onItemsSelected?.(items);
    };

    const resetFilter = () => {
        const emtpyArray: string[] = [];
        setSelectedItems(emtpyArray);
        onItemsSelected?.(emtpyArray);
    };

    return (
        <div className="relative" ref={isOutside}>
            <button
                disabled={disabled}
                onClick={() => setIsVisible(prev => !prev)}
                className={classNames("p-1 px-3 text-sm flex items-center relative rounded", {
                    "border border-gray-200": selectedItems.length === 0,
                    "border border-primary text-primary font-semibold": selectedItems.length !== 0,
                    "bg-disabled cursor-not-allowed text-disabled-dark": disabled,
                    "text-label": !disabled
                })}
            >
                {title}
                {selectedItems.length !== 0 && (
                    <span className={classNames("bg-primary text-white font-bold rounded-full text-xxs w-4 h-4 flex items-center justify-center ml-1")}>
                        {selectedItems.length}
                    </span>
                )}
                <ChevronSmall
                    className={classNames("ml-2 mt-0.5 w-2.5 transition-all", {
                        "rotate-180": !isVisible,
                        "rotate-0": isVisible,
                        "fill-disabled-dark": disabled,
                        "fill-primary": !disabled
                    })}
                />
            </button>
            {isVisible && (
                <div className="flex flex-col items-start mt-2 p-2 border border-gray-200 rounded absolute z-[70] bg-white right-0 w-[14rem] text-sm shadow-md">
                    {Object.keys(filters).map((item, index) => (
                        <div key={filters[item].title}>
                            <p
                                className={classNames("font-semibold text-label mb-1", {
                                    "mt-2": index !== 0
                                })}
                            >
                                {filters[item].title}
                            </p>
                            {filters[item].elements.map(subItem => (
                                <Input
                                    key={subItem}
                                    type="checkbox"
                                    onChange={() => onFilterChanged(subItem)}
                                    fieldName={subItem}
                                    label={subItem}
                                    checked={selectedItems.includes(subItem)}
                                />
                            ))}
                        </div>
                    ))}
                    <span className="border-t-2 border-gray-200 w-full mt-2 pt-2">
                        <button onClick={resetFilter}>{resetTitle}</button>
                    </span>
                </div>
            )}
        </div>
    );
}
