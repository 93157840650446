export default function Notification({ onClick }: { onClick?: () => void }) {
    return (
        <svg
            style={{ cursor: "pointer" }}
            onClick={onClick}
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4809 2.16667H2.51908C2.05533 2.16667 1.67939 2.53976 1.67939 3V15C1.67939 15.4602 2.05533 15.8333 2.51908 15.8333H19.4809C19.9447 15.8333 20.3206 15.4602 20.3206 15V3C20.3206 2.53976 19.9447 2.16667 19.4809 2.16667ZM2.51908 0.5C1.12783 0.5 0 1.61929 0 3V15C0 16.3807 1.12783 17.5 2.51908 17.5H19.4809C20.8722 17.5 22 16.3807 22 15V3C22 1.61929 20.8722 0.5 19.4809 0.5H2.51908Z"
                fill="#717171"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.05344 17V1.33333H8.56489V17H7.05344Z" fill="#717171" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.68702 13.3333C2.68702 13.0572 2.91259 12.8333 3.19084 12.8333H5.54198C5.82024 12.8333 6.0458 13.0572 6.0458 13.3333C6.0458 13.6095 5.82024 13.8333 5.54198 13.8333H3.19084C2.91259 13.8333 2.68702 13.6095 2.68702 13.3333Z"
                fill="#717171"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.68702 10.8333C2.68702 10.5572 2.91259 10.3333 3.19084 10.3333H5.54198C5.82024 10.3333 6.0458 10.5572 6.0458 10.8333C6.0458 11.1095 5.82024 11.3333 5.54198 11.3333H3.19084C2.91259 11.3333 2.68702 11.1095 2.68702 10.8333Z"
                fill="#717171"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.35878 8.33333C3.35878 8.05719 3.58435 7.83333 3.8626 7.83333H5.54198C5.82024 7.83333 6.0458 8.05719 6.0458 8.33333C6.0458 8.60948 5.82024 8.83333 5.54198 8.83333H3.8626C3.58435 8.83333 3.35878 8.60948 3.35878 8.33333Z"
                fill="#717171"
            />
        </svg>
    );
}
