import classNames from "classnames";
import { ReactNode } from "react";

export default function Box({
    children,
    checked,
    isClickable,
    isBordered = false,
    disabled
}: {
    children: ReactNode;
    checked?: boolean;
    isClickable?: boolean;
    isBordered?: boolean;
    disabled?: boolean;
}) {
    return (
        <div
            className={classNames("relative border rounded-none border-input-border-color w-full h-full p-5", {
                "bg-white border-input-border-color hover:border-primary hover:border hover:shadow-border-primary cursor-pointer":
                    !checked && isClickable && !disabled,
                "bg-white border-primary shadow-border-primary cursor-pointer": checked && isClickable && !disabled,
                "border-none": !isBordered,
                "bg-disabled cursor-not-allowed": disabled
            })}
        >
            {children}
        </div>
    );
}
