import classNames from "classnames";
import { ReactNode } from "react";
import { toast, ToastOptions } from "react-hot-toast";

import { ClipBoardIcon, CloseIcon, ErrorIcon, InfoIcon, SuccessIcon } from "../../components";

type CustomToastOptions = Pick<ToastOptions, "duration">;

export default function useToast() {
    const toastCustom = (icon: ReactNode, type: "info" | "success" | "error", options?: CustomToastOptions): ((message: string | ReactNode) => void) => {
        return (text: string | ReactNode) =>
            toast.custom(
                message => (
                    <div
                        className={`${
                            message.visible ? "animate-enter" : "animate-leave"
                        } max-w-md w-full bg-white pointer-events-auto flex ring-1 ring-black ring-opacity-5 rounded`}
                    >
                        <div className="flex-1 w-0 p-3">
                            <div className="flex space-x-3">
                                <div
                                    className={classNames("flex items-center justify-center flex-shrink-0 w-8 h-8 rounded fill-white", {
                                        "bg-primary": type === "info",
                                        "bg-success": type === "success",
                                        "bg-danger": type === "error"
                                    })}
                                >
                                    {icon}
                                </div>
                                <div className="flex-1">
                                    <p>{text}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <button
                                onClick={() => toast.dismiss(message.id)}
                                className="flex justify-center w-full p-2 text-sm font-medium border border-transparent fill-primary focus:outline-none focus:ring-2 focus:ring-primary"
                            >
                                <CloseIcon className="w-5 h-5" />
                            </button>
                        </div>
                    </div>
                ),
                options
            );
    };

    return {
        error: toastCustom(<ErrorIcon className="w-5 h-5 fill-white" />, "error", { duration: Infinity }),
        info: toastCustom(<InfoIcon className="w-5 h-5" />, "info"),
        copy: (copiedValue: string) => toastCustom(<ClipBoardIcon className="w-5 h-5" />, "info")(`"${copiedValue}" ajouté au presse papier`),
        success: toastCustom(<SuccessIcon className="w-5 h-5" />, "success")
    };
}
