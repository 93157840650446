import { InteractionEvent } from "@vaultinum/vaultinum-legal-proof-api";
import { Socket } from "socket.io-client";
import { ActionStatus, IconType } from "../model";

export class AreaDraw {
    private canvas: HTMLCanvasElement;

    private ctx: CanvasRenderingContext2D;

    private rectangleInfo: { startX: number; startY: number; width: number; height: number };

    private isDrawing: boolean;

    private socket: Socket;

    private userUid: string;

    private setActionsStatus: (actionStatus: ActionStatus) => void;

    private drawRectangleRequestId: number | null = null;

    private handleMouseDownBound = this.handleMouseDown.bind(this);

    private handleMouseMoveBound = this.handleMouseMove.bind(this);

    private handleMouseUpBound = this.handleMouseUp.bind(this);

    private canvasRect: DOMRect | undefined;

    constructor(socket: Socket, userUid: string, setActionsStatus: (actionStatus: ActionStatus) => void, cursor: "crosshair" | "wait") {
        this.socket = socket;
        this.userUid = userUid;
        this.setActionsStatus = setActionsStatus;

        this.canvas = this.createCanvas();
        this.ctx = this.canvas.getContext("2d") as CanvasRenderingContext2D;

        this.rectangleInfo = {
            startX: 0,
            startY: 0,
            width: 0,
            height: 0,
        };

        this.initCanvas(cursor);
        this.ctx.fillStyle = "rgba(0, 0, 0, 0.35)";
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.addEventListeners(cursor);
        this.isDrawing = false;
    }

    private escapeHandler(event: KeyboardEvent): void {
        if (event.key === "Escape") {
            this.setIsDrawing(false);
            this.cleanUpAfterDrawing();
        }
    }

    private createCanvas(): HTMLCanvasElement {
        const canvas = document.createElement("canvas");
        document.body.appendChild(canvas);
        return canvas;
    }

    private initCanvas(cursor: "crosshair" | "wait"): void {
        const lprCanvas = document.getElementById("lpr-canvas") as HTMLCanvasElement;
        this.canvasRect = lprCanvas.getBoundingClientRect();

        this.canvas.id = "new-canvas";
        this.canvas.width = this.canvasRect.width;
        this.canvas.height = this.canvasRect.height;
        this.canvas.style.width = `${this.canvasRect.width}px`;
        this.canvas.style.height = `${this.canvasRect.height}px`;

        this.canvas.style.position = "absolute";
        this.canvas.style.top = `${this.canvasRect.top + window.scrollY}px`;
        this.canvas.style.borderRadius = "0.375rem";
        this.canvas.style.left = `${this.canvasRect.left}px`;
        this.canvas.style.zIndex = "10000";
        this.canvas.style.cursor = cursor;
    }

    private addEventListeners(cursor: "crosshair" | "wait"): void {
        if (cursor === "wait") {
            return;
        }
        const { canvas } = this;

        canvas.addEventListener("mousedown", this.handleMouseDownBound);
        canvas.addEventListener("mousemove", this.handleMouseMoveBound);
        canvas.addEventListener("mouseup", this.handleMouseUpBound);
        window.addEventListener("keydown", this.escapeHandler);
    }

    private removeEventListeners(): void {
        this.canvas.removeEventListener("mousedown", this.handleMouseDownBound);
        this.canvas.removeEventListener("mousemove", this.handleMouseMoveBound);
        this.canvas.removeEventListener("mouseup", this.handleMouseUpBound);
        window.removeEventListener("keydown", this.escapeHandler);
    }

    private handleMouseDown(e: MouseEvent): void {
        this.setIsDrawing(true);
        const { canvas } = this;
        this.rectangleInfo.startX = e.clientX - canvas.offsetLeft;
        this.rectangleInfo.startY = e.clientY + window.scrollY - canvas.offsetTop;
    }

    private handleMouseMove(event: MouseEvent): void {
        if (this.drawRectangleRequestId === null) {
            // Sync rendering with screen refresh rate so that the rectangle is drawn smoothly
            // and not at each mouse move event
            this.drawRectangleRequestId = requestAnimationFrame(() => {
                this.drawRectangle(event);
                this.drawRectangleRequestId = null;
            });
        }
    }

    private drawRectangle(e: MouseEvent): void {
        if (!this.getIsDrawing()) {
            return;
        }
        const { ctx, rectangleInfo } = this;

        const currentX = e.clientX - this.canvas.offsetLeft;
        const currentY = e.clientY + window.scrollY - this.canvas.offsetTop;
        const width = currentX - rectangleInfo.startX;
        const height = currentY - rectangleInfo.startY;

        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.clearRect(rectangleInfo.startX, rectangleInfo.startY, width, height);

        rectangleInfo.width = width;
        rectangleInfo.height = height;

        // Draw rectangle border to better visualize the area
        ctx.beginPath();
        ctx.rect(rectangleInfo.startX, rectangleInfo.startY, width, height);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "#F4150D";
        ctx.stroke();
    }

    private async handleMouseUp(): Promise<void> {
        await this.handleEndDrawing();
    }

    private processAreaEvent(): void {
        let { startX, startY, width, height } = this.rectangleInfo;

        this.socket.emit(InteractionEvent.AREA, {
            userUid: this.userUid,
            x: startX,
            y: startY,
            width,
            height,
            scrollY: window.scrollY,
        });
    }

    private cleanUpAfterDrawing(): void {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.removeEventListeners();
        document.getElementById("new-canvas")?.remove();
        this.setActionsStatus({
            areaIcon: IconType.DEFAULT,
            navigationButtons: true,
            endButton: true,
        });
    }

    private setIsDrawing(isDrawing: boolean): void {
        this.isDrawing = isDrawing;
    }

    private getIsDrawing(): boolean {
        return this.isDrawing;
    }

    private async handleEndDrawing(): Promise<void> {
        this.setIsDrawing(false);
        this.processAreaEvent();
        this.cleanUpAfterDrawing();
    }
}
