import { yupResolver } from "@hookform/resolvers/yup";
import { useI18n, useToast } from "@vaultinum/app-sdk";
import { Report, REPORT_TITLE_REGEX, Requester } from "@vaultinum/vaultinum-legal-proof-api";
import { useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Creatable from "react-select/creatable";
import { MAIN_PAGE } from "../../../AppRouter";
import { useAuth } from "../../../auth";
import { AppLogo } from "../../../components";
import { useSession } from "../../../context";
import { Button, Input } from "../../../design-system";
import { Translation } from "../../../i18n";
import { validationSchema } from "../../../schemas";
import { createRequester, getRequesters } from "../../../services";
import { getSelectStyles } from "../../../styles";
import ReportType = Report.ReportType;

export default function ReportForm() {
    const { error } = useToast();
    const navigate = useNavigate();
    const { translation } = useI18n<Translation>();
    const { setReportMetadata } = useSession();

    const [requesters, setRequesters] = useState<Omit<Requester, "id">[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();

    const {
        control,
        formState: { isValid, errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm<FieldValues>({
        resolver: yupResolver(validationSchema(translation)),
        mode: "onChange",
    });

    useEffect(() => {
        if (!user) {
            return;
        }
        try {
            setIsLoading(true);
            getRequesters(user.uid, setRequesters);
        } catch (err) {
            error(translation.errorGettingRequesters);
        }
        setIsLoading(false);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, user]);

    const handleCreateRequester = async (name: string) => {
        try {
            if (!REPORT_TITLE_REGEX.test(name)) {
                error(translation.reportRequesterNoSpecialChars);
                return;
            }
            if (!user) {
                return;
            }

            await createRequester(user.uid, {
                name,
            });
            setRequesters([...requesters, { name }]);
            setValue("requester", {
                label: name,
                value: name,
            });
        } catch (err) {
            error(translation.errorCreatingRequester);
        }
    };

    const onSubmit = async () => {
        const { reportTitle, requester, reportType } = getValues();
        setReportMetadata({ reportTitle, requester: requester.value, reportType });
        navigate(MAIN_PAGE);
    };

    return (
        <div>
            <h1 className="font-bold text-primaryDark">{translation.reportForm}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col space-y-2">
                    <Controller
                        control={control}
                        name="reportType"
                        render={({ field }) => (
                            <>
                                <label
                                    className="text-md text-primaryDark font-semibold truncate after:ml-1 after:content-['*']"
                                    children={translation.reportTypeLabel}
                                />
                                <Input
                                    {...field}
                                    fieldName={ReportType.IP.toString()}
                                    value={ReportType.IP}
                                    label={
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-primaryDark font-semibold pr-2">{translation.reportTypeIPLabel}</span>
                                                <span>{translation.reportTypeIPSubLabel}</span>
                                            </div>
                                            <AppLogo className="w-40" />
                                        </div>
                                    }
                                    checked={getValues().reportType === ReportType.IP}
                                    type="radio"
                                />
                                <Input
                                    {...field}
                                    fieldName={ReportType.OTHER.toString()}
                                    value={ReportType.OTHER}
                                    checked={getValues().reportType === ReportType.OTHER}
                                    label={<span className="text-primaryDark font-semibold">{translation.reportTypeOtherLabel}</span>}
                                    type="radio"
                                />
                            </>
                        )}
                    />
                    <Controller
                        control={control}
                        name="reportTitle"
                        render={({ field }) => (
                            <div className="flex flex-col -space-y-1">
                                <label
                                    className="text-primaryDark text-sm font-semibold truncate after:ml-1 after:text-primary after:content-['*']"
                                    children={translation.title}
                                />
                                <Input {...field} />
                                {errors.reportTitle && <span className="text-danger-high">{errors.reportTitle?.message?.toString()}</span>}
                            </div>
                        )}
                    />
                    <Controller
                        control={control}
                        name="requester"
                        render={({ field }) => (
                            <div className="flex flex-col space-y-1">
                                <label
                                    className="text-primaryDark text-sm font-semibold truncate after:ml-1 after:text-primary after:content-['*']"
                                    children={translation.requester}
                                />
                                <div className="flex items-center gap-2">
                                    <div className="relative flex flex-1 items-center">
                                        <Creatable
                                            {...field}
                                            options={requesters.map((requester) => {
                                                return {
                                                    label: requester.name,
                                                    value: requester.name,
                                                };
                                            })}
                                            required
                                            isLoading={isLoading}
                                            isMulti={false}
                                            onCreateOption={handleCreateRequester}
                                            classNames={getSelectStyles()}
                                            className="w-full"
                                            unstyled
                                            placeholder={translation.createOrSearchRequester}
                                            formatCreateLabel={(userInput) => `${userInput} (nouveau requérant)`}
                                        />
                                    </div>
                                </div>
                                <span className="text-neutral text-xs">{translation.legalStatusHelper}</span>
                            </div>
                        )}
                    />
                </div>
                <div className="flex items-end place-content-between pt-5">
                    <div className="-mt-1">
                        <span className="text-sm">*</span>
                        <span className="text-xxs font-semibold text-primaryDark">{translation.requiredFields}</span>
                    </div>
                    <Button type="submit" label={translation.start} disabled={!isValid} />
                </div>
            </form>
        </div>
    );
}
