import classNames from "classnames";
import { ReactNode, useRef } from "react";
import { useHover } from "../../utils/hooks";

export type InfoTooltipProps = {
    children: ReactNode;
    width?: string;
    className?: string;
    direction?: "bottom" | "top" | "left" | "right";
    size?: "big" | "small";
};

const SIZE_CLASS = {
    big: "w-5 h-5 text-sm",
    small: "w-3 h-3 text-xxs"
};

const TOOLTIP_DIRECTIONS = {
    bottom: "top-full left-1/2 -translate-x-1/2 mt-0",
    top: "bottom-full left-1/2 -translate-x-1/2 mb-0",
    left: "top-1/2 right-full -translate-y-1/2 mr-0",
    right: "top-1/2 left-full -translate-y-1/2 ml-0"
};

export function InfoTooltip({ children, className, width = "w-60", direction = "bottom", size = "big" }: InfoTooltipProps) {
    const refElement = useRef(null);
    const isHovered = useHover(refElement);

    return (
        <div className={classNames("absolute flex", className)}>
            <div ref={refElement} className="inline-block cursor-pointer">
                <div className={classNames("flex items-center justify-center font-bold text-white rounded-full bg-primary font-serif", SIZE_CLASS[size])}>
                    i
                </div>
                {isHovered && (
                    <div className={classNames("absolute z-50 p-4 text-xs text-white rounded shadow bg-label", TOOLTIP_DIRECTIONS[direction], width)}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
}
