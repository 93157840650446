import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";

import { User } from "../model";
import { doDelete, doPost } from "./http.service";

export interface Token {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    user: User;
}

export function getTokenFromStorage(): Token | undefined {
    const token = {
        refreshToken: getRefreshToken(),
        accessToken: getAccessToken(),
        user: {
            avatar: getAvatar()
        }
    } as Token;

    if (token.refreshToken && token.accessToken) {
        return token;
    }

    return undefined;
}

export function storeToken(token: Token): void {
    localStorage.setItem("accessToken", token.accessToken);
    localStorage.setItem("refreshToken", token.refreshToken);

    if (token.user.avatar) {
        localStorage.setItem("avatar", token.user.avatar);
    }
}

export function clearToken(): void {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("avatar");
}

const getRefreshToken = (): string | null => {
    return localStorage.getItem("refreshToken");
};

const getAvatar = (): string | null => {
    return localStorage.getItem("avatar");
};

export function getAccessToken(): string | null {
    return localStorage.getItem("accessToken");
}

export async function refreshToken(token: Token): Promise<Token> {
    const refreshedToken = await doPost<Token>(`${process.env.REACT_APP_AUTHENTICATION_API_URL}/tokens/refresh`, {
        accessToken: token.accessToken,
        refreshToken: token.refreshToken
    });

    return {
        ...refreshedToken,
        user: {
            ...refreshedToken.user,
            avatar: token.user.avatar
        }
    };
}

export async function authenticateWithInterdeposit(login: string, password: string): Promise<Token> {
    try {
        const token = await doPost<Token>(`${process.env.REACT_APP_AUTHENTICATION_API_URL}/tokens`, {
            provider: "interdeposit",
            token: `${login}:${password}`
        });

        return token;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function authenticateWithMicrosoft(): Promise<Token> {
    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
    };

    const firebaseApp = initializeApp(firebaseConfig);

    const firebaseAuth = getAuth(firebaseApp);

    const firebaseMicrosoftProvider = new OAuthProvider("microsoft.com");

    try {
        const userCredential = await signInWithPopup(firebaseAuth, firebaseMicrosoftProvider);

        const credential = OAuthProvider.credentialFromResult(userCredential);
        const microsoftAccessToken = credential?.accessToken;

        const token = await doPost<Token>(`${process.env.REACT_APP_AUTHENTICATION_API_URL}/tokens`, {
            provider: "microsoft",
            token: microsoftAccessToken
        });

        return token;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function logout(onSuccess: () => void) {
    try {
        await doDelete(`${process.env.REACT_APP_AUTHENTICATION_API_URL}/tokens`, {
            accessToken: getAccessToken(),
            refreshToken: getRefreshToken()
        });
    } catch (error) {
        console.error(error);

        return;
    }

    clearToken();

    onSuccess();
}
