import { createContext } from "react";

import { PluralString, RatioString, Translation } from "./Translation";

//eslint-disable-next-line @typescript-eslint/no-explicit-any
let i18nContext: React.Context<any> | null = null;

export interface I18nContextInterface<T> {
    translation: T;
    translate: (pluralString: PluralString, count: number) => string;
    translateRatio: (ratioString: RatioString, count: number, total: number) => string;
    setLocale: (locale: string) => void;
    locale: string;
}

export function getI18nContext<T extends Translation>(): React.Context<I18nContextInterface<T>> {
    if (!i18nContext) {
        i18nContext = createContext({} as I18nContextInterface<T>);
    }

    return i18nContext;
}
