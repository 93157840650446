import { Cell, SimpleTable } from "../../../design-system";
import { Translation, useI18n } from "../../i18n";

export enum PaymentStatus {
    PAID = "PAID",
    UNPAID = "UNPAID"
}

export function Invoices({ titles, values }: { titles: Cell[]; values: Cell[][] }): JSX.Element {
    const { translation } = useI18n<Translation>();
    return (
        <div className="space-y-2.5 overflow-x-auto">
            <h2 className="font-semibold text-sm text-label">{translation.invoicesHistory}</h2>
            <SimpleTable titles={titles} values={values} layout="auto" />
        </div>
    );
}
