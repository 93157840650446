import classNames from "classnames";

import { ErrorIcon, InfoIcon, SuccessIcon } from "../Icons";

const renderIcon = (typeIcon: string) => {
    switch (typeIcon) {
        case "success":
            return <SuccessIcon className="h-full fill-success" />;
        case "danger":
            return <ErrorIcon className="h-full fill-danger" />;
        case "warning":
            return <InfoIcon className="h-full rotate-180 fill-warning" />;
        default:
            return <InfoIcon className={classNames(`fill-${typeIcon} h-full`)} />;
    }
};

export default function Info({
    children,
    labelType,
    type = "primary",
    className,
    noBackground = false,
    noPadding = false,
    textSize = "sm"
}: {
    children: React.ReactNode;
    labelType?: string;
    type?: "primary" | "warning" | "danger" | "success" | "neutral";
    className?: string;
    noBackground?: boolean;
    noPadding?: boolean;
    textSize?: "xs" | "sm" | "base" | "lg";
}) {
    return (
        <div
            className={classNames(`flex items-center w-full space-x-5 rounded text-${type}`, className, {
                "bg-transparent": noBackground,
                [`bg-${type}-light`]: !noBackground,
                "p-5": !noPadding
            })}
        >
            <div className="h-6">{renderIcon(type)}</div>
            <div className={classNames(`text-${textSize}`)}>
                {labelType && <strong>{labelType} : </strong>}
                {children}
            </div>
        </div>
    );
}
