import classNames from "classnames";
import { ComponentType, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Card, Chip, Contract, CoOwnership, FilterBox, FilterItems, Lawyer, LetterClose, ShareIcon, SkeletonCards } from "../../../design-system";
import { depositCreatorDisplayName } from "../../helpers";
import { Translation, useI18n } from "../../i18n";
import { DepositStore, User } from "../../model";
import { DepositStatus } from "../DepositStatus";

const getContractTooltipText = (translation: Translation, depositStore?: DepositStore) => {
    if (depositStore?.agreementTypes?.length) {
        return depositStore.agreementTypes.map(agreementType => translation.agreementStatus[agreementType]).join(", ");
    }

    return translation.noContractForThisDepositStore;
};

const DepositStoreAdditionalIcon = ({
    Icon,
    active,
    hide,
    tooltip
}: {
    Icon: ComponentType<{ className?: string }>;
    active: boolean;
    hide: boolean;
    tooltip: string;
}): ReactNode => {
    return (
        hide || (
            <Chip
                label={<Icon className={classNames({ "opacity-50": !active })} />}
                tooltipContent={tooltip}
                type={active ? "primary" : "neutral"}
                invertColors={active}
                size="small"
            />
        )
    );
};

export enum OptionsFilter {
    ICON = "ICON",
    IDDN = "IDDN",
    CONTRACT = "CONTRACT",
    HOLDERS = "HOLDERS",
    OWNER = "OWNER"
}

const getFilteredUsersByRole = (depositStores: DepositStore[], role: User.UserWalletRole.ADMINISTRATOR | User.UserWalletRole.CONTRIBUTOR) => {
    return depositStores
        .map(depositStore => depositStore.managedBy)
        .flatMap(managedBy => managedBy?.users ?? [])
        .filter(user => user.role === role)
        .filter((obj, index, self) => index === self.findIndex(t => t.id === obj.id));
};

export default function Wallet({
    depositStores,
    depositStoreLink,
    depositLink,
    inputSearch,
    showToolTip = true,
    loading
}: {
    depositStores?: DepositStore[];
    depositStoreLink: (depositStore: DepositStore) => string;
    depositLink: (depositStore: DepositStore, depositIddn: string) => string;
    inputSearch: string;
    showToolTip?: boolean;
    loading?: boolean;
}) {
    const { translation, translate } = useI18n<Translation>();
    const [filteredDepositStores, setFilteredDepositStores] = useState<FilterItems>();

    const FILTER_OPTIONS: FilterItems = {
        options: {
            title: translation.hideCheckedElements,
            elements: Object.values(OptionsFilter).map(option => translation.optionsFilters[option])
        }
    };

    useEffect(() => {
        if (depositStores) {
            const managersFilters: FilterItems = {};

            const admins = getFilteredUsersByRole(depositStores, User.UserWalletRole.ADMINISTRATOR);

            const contributors = getFilteredUsersByRole(depositStores, User.UserWalletRole.CONTRIBUTOR);

            const representatives = depositStores
                ?.map(depositStore => depositStore.managedBy)
                .flatMap(managedBy => managedBy?.members ?? [])
                .filter((obj, index, self) => index === self.findIndex(t => t.id === obj.id));

            if (admins.length) {
                managersFilters.admin = {
                    title: translation.administrators,
                    elements: admins.map(adminInfo => `${adminInfo.firstName} ${adminInfo.lastName}`)
                };
            }

            if (contributors.length) {
                managersFilters.contributors = {
                    title: translation.contributors,
                    elements: contributors.map(contributorInfo => `${contributorInfo.firstName} ${contributorInfo.lastName}`)
                };
            }

            if (representatives.length) {
                managersFilters.representative = {
                    title: translation.representative,
                    elements: representatives.map(representativeInfo => representativeInfo.name)
                };
            }
            setFilteredDepositStores(managersFilters);
        }
    }, [depositStores]);

    const [displaySettings, setDisplaySettings] = useState<string[]>([]);

    let content;

    if (!depositStores || loading) {
        content = (
            <>
                <div className="animate-pulse flex mb-2.5">
                    <div className="w-20 h-3 bg-gray-200" />
                </div>
                <SkeletonCards length={20} />
            </>
        );
    } else if (depositStores.length === 0) {
        content = <p className="text-center text-deep-blue">{translation.wallet.noDepositInPortfolio}</p>;
    } else if (depositStores.length > 0) {
        content = (
            <div>
                <div className="flex justify-between items-center mb-2">
                    <p className="font-semibold">{inputSearch !== "" ? translation.depositsFoundByDepositStore : translation.depositStore.names}</p>
                    <div className="flex gap-1">
                        {filteredDepositStores && (
                            <div className="flex space-x-1">
                                <FilterBox
                                    filters={filteredDepositStores}
                                    title={translation.filterByContributors}
                                    resetTitle={translation.removeFilters}
                                    disabled={!!depositStores.map(depositStore => depositStore.managedBy)}
                                />
                            </div>
                        )}
                        <div className="flex space-x-1">
                            <FilterBox
                                filters={FILTER_OPTIONS}
                                title={translation.displaySettings}
                                resetTitle={translation.resetDisplaySettings}
                                onItemsSelected={itemsSelected => setDisplaySettings(itemsSelected)}
                                filtersSelected={displaySettings}
                            />
                        </div>
                    </div>
                </div>
                <ul className="space-y-1.5">
                    {depositStores.map(depositStore => {
                        const hasContract = !!depositStore.agreementTypes && depositStore.agreementTypes?.length > 0;
                        const isShared = depositStore.holdersCount > 1;
                        const isManagedByRepresentative = depositStore.managedBy?.isSharedToRepresentatives;

                        return (
                            <>
                                <li key={depositStore.id}>
                                    <Link to={depositStoreLink(depositStore)}>
                                        <Card
                                            title={depositStore.name}
                                            subTitle={!displaySettings.includes(translation.optionsFilters[OptionsFilter.IDDN]) ? depositStore.iddn : undefined}
                                            icon={
                                                !displaySettings.includes(translation.optionsFilters[OptionsFilter.ICON]) ? (
                                                    <ShareIcon className="fill-primary" />
                                                ) : undefined
                                            }
                                            rightContent={
                                                <>
                                                    <div className="flex space-x-1">
                                                        <p className="text-label whitespace-nowrap">
                                                            {translate(translation.deposit.availableCount, depositStore.depositsCount)}
                                                        </p>
                                                        <DepositStatus
                                                            label={translation.deposit.depositStoreStatus[depositStore.sealStatus]}
                                                            status={depositStore.sealStatus}
                                                            size="small"
                                                            showToolTip={showToolTip}
                                                        />
                                                    </div>
                                                    <div className="flex space-x-1 mt-1">
                                                        <DepositStoreAdditionalIcon
                                                            Icon={Contract}
                                                            active={hasContract}
                                                            hide={displaySettings.includes(translation.optionsFilters[OptionsFilter.CONTRACT])}
                                                            tooltip={getContractTooltipText(translation, depositStore)}
                                                        />
                                                        <DepositStoreAdditionalIcon
                                                            Icon={CoOwnership}
                                                            active={isShared}
                                                            hide={displaySettings.includes(translation.optionsFilters[OptionsFilter.HOLDERS])}
                                                            tooltip={isShared ? translation.sharedDepositStore : translation.nonSharedDepositStore}
                                                        />
                                                        <DepositStoreAdditionalIcon
                                                            Icon={Lawyer}
                                                            active={isManagedByRepresentative}
                                                            hide={displaySettings.includes(translation.optionsFilters[OptionsFilter.OWNER])}
                                                            tooltip={
                                                                isManagedByRepresentative
                                                                    ? translation.depositStoreManagedByRepresentative
                                                                    : translation.depositStoreManagedByHolder
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            }
                                        />
                                    </Link>
                                </li>
                                {depositStore.deposits && depositStore.deposits.length > 0 && (
                                    <div className="flex gap-2">
                                        <div className="flex flex-col">
                                            <p className="font-semibold">{translation.deposits}</p>
                                        </div>
                                        <ul className="space-y-1.5 w-full pl-2.5 border-l-4 border-blue-100 min-w-0">
                                            {depositStore.deposits.map(deposit => (
                                                <li key={deposit.id}>
                                                    <Link to={depositLink(depositStore, deposit.id)}>
                                                        <Card
                                                            title={deposit.name}
                                                            subTitle={`${deposit.iddn || "-"} • ${depositCreatorDisplayName(deposit.creator)}`}
                                                            icon={<LetterClose />}
                                                            rightContent={
                                                                <div className="flex space-x-1">
                                                                    <DepositStatus
                                                                        label={translation.deposit.sealStatus[deposit.depositType][deposit.sealStatus]}
                                                                        status={deposit.sealStatus}
                                                                        size="small"
                                                                        showToolTip={showToolTip}
                                                                    />
                                                                </div>
                                                            }
                                                        />
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </>
                        );
                    })}
                </ul>
            </div>
        );
    } else {
        content = <p className="text-sm font-light text-center pt-9 text-deep-blue">{translation.noResult}</p>;
    }

    return content;
}
