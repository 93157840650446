import { I18n } from "@vaultinum/app-sdk";
import { getAuth, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContext, AuthProps } from "./auth";
import { SessionProvider } from "./context";
import { fr } from "./i18n";
import { PrivatePageLayout, PublicPageLayout } from "./layouts";
import { sendLoginEventToMixpanel } from "./services";
import { Downloading, LoginPage, MainPage, ReportManager } from "./views";

const PUBLIC_URL = process.env.PUBLIC_URL;
export const LOGIN_PAGE = `${PUBLIC_URL}/login`;
export const MAIN_PAGE = `${PUBLIC_URL}/main`;
export const REPORT_MANAGER_PAGE = `${PUBLIC_URL}/report`;
export const DOWNLOADING_PAGE = `${PUBLIC_URL}/downloading`;

function PublicRoutes() {
    return (
        <PublicPageLayout>
            <Routes>
                <Route path={LOGIN_PAGE} element={<LoginPage />} />
                <Route path="*" element={<Navigate to={LOGIN_PAGE} />} />
            </Routes>
        </PublicPageLayout>
    );
}

function PrivateRoutes() {
    return (
        <SessionProvider>
            <PrivatePageLayout>
                <Routes>
                    <Route path={REPORT_MANAGER_PAGE} element={<ReportManager />} />
                    <Route path={MAIN_PAGE} element={<MainPage />} />
                    <Route path={DOWNLOADING_PAGE} element={<Downloading />} />
                    <Route path="*" element={<Navigate to={REPORT_MANAGER_PAGE} />} />
                </Routes>
            </PrivatePageLayout>
        </SessionProvider>
    );
}

function AppRouter(): JSX.Element {
    const [authSettings, setAuthSettings] = useState<AuthProps>({
        user: null,
    });

    useEffect(() => {
        getAuth().onAuthStateChanged(async (updatedUser: User | null) => {
            if (updatedUser) {
                sendLoginEventToMixpanel(updatedUser);
            }
            setAuthSettings((prevState) => ({ ...prevState, user: updatedUser }));
        });
    }, []);

    return (
        <BrowserRouter>
            <I18n locale="fr" locales={{ fr }}>
                <AuthContext.Provider value={authSettings}>
                    {authSettings.user && <PrivateRoutes />}
                    {!authSettings.user && <PublicRoutes />}
                </AuthContext.Provider>
            </I18n>
        </BrowserRouter>
    );
}

export default AppRouter;
