import { InfoTooltip, InfoTooltipProps } from "../InfoTooltip/InfoTooltip";

export type InfoTrackerProps = {
    figure: {
        value: number | string;
        label?: string;
    };
    relatedInfo?: {
        value: number | string;
        label?: string;
        tooltip?: InfoTooltipProps;
    }[];
    title?: string;
};

export function InfoTracker({ title, figure, relatedInfo }: InfoTrackerProps) {
    return (
        <div className="flex flex-col items-center w-full space-y-2 text-sm">
            {title && <h4 className="font- text-primary">{title}</h4>}
            <div className="flex flex-col items-center justify-center h-28 w-full font-bold bg-primary-xlight text-primary rounded-md">
                <p className="text-5xl">{figure.value}</p>
                {figure.label && <p className="font-normal">{figure.label}</p>}
            </div>
            {relatedInfo && relatedInfo.length > 0 && (
                <ul className="self-start space-y-1">
                    {relatedInfo.map(info => (
                        <li key={info.label} className="relative text-xs flex flex-row">
                            {info.label && <span className="text-label">{info.label} </span>}
                            <span className="font-bold text-primary ml-1">{info.value}</span>
                            {info.tooltip && (
                                <span>
                                    <InfoTooltip className="mt-0.5 ml-0.5" size={info.tooltip.size}>
                                        {info.tooltip.children}
                                    </InfoTooltip>
                                </span>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
