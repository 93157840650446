export default function RightArrow({ disabled }: { disabled: boolean }) {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_70_89)">
                <path
                    d="M13.0223 8.25H3.9429V9.75H13.0223L9.02115 13.773L10.0759 14.8335L15.8777 9L10.0759 3.1665L9.02115 4.227L13.0223 8.25Z"
                    fill={disabled ? "#C1C1C1" : "#717171"}
                />
            </g>
            <defs>
                <clipPath id="clip0_70_89">
                    <rect width="17.9022" height="18" fill="white" transform="matrix(-1 0 0 1 18.8613 0)" />
                </clipPath>
            </defs>
        </svg>
    );
}
