import { Toast } from "@vaultinum/app-sdk";
import classNames from "classnames";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { MAIN_PAGE } from "../AppRouter";
import { Footer, Header } from "../components";

export default function PrivatePageLayout({ children }: { children: ReactNode }) {
    const location = useLocation();

    return (
        <div className="flex flex-col min-h-screen bg-primary">
            <Toast position="top-center" />
            <Header />
            <div
                className={classNames("flex flex-grow", {
                    "items-center justify-center": location.pathname !== MAIN_PAGE,
                })}
            >
                {children}
            </div>
            <Footer />
        </div>
    );
}
